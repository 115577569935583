import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer15Service {

  private messageSource15 = new BehaviorSubject(true); //false is 'default message'
  currentMessage15 = this.messageSource15.asObservable();

  constructor() { }

  changeMessage15(message15: boolean) {
    this.messageSource15.next(message15)
  }

}