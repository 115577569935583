import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Drawer13Service } from "../../assets/services/drawer13.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { element } from 'protractor';

declare function snipMe(): any;

@Component({
  selector: 'app-profitbills',
  templateUrl: './profitbills.component.html',
  styleUrls: ['./profitbills.component.css'],
  encapsulation: ViewEncapsulation.None,   //snackbar panelcss
})
export class ProfitbillsComponent implements AfterViewInit {

  showMainContent:Boolean=true;

  message:boolean;
  selectedDate: string;
  prcollection: any;
  prdcollection: any;

favoriteCustomer: string;
customers: string[] = ['wholesale', 'retail', 'reseller', 'mrp'];

selectedOptions: string[] = [];

myary: { billid: any, checked: boolean  }[]= [] //declare before constructor

arybilldetails:{
  billid:any;
  code:string;
  productname: string;
  hsncode: string;
  discount: number;
  qnty : number;
  unit2 : string;
  rate: number;
  cgst: number;
  sgst: number;
  igst:number;
  prate: number;
  purchaserateafterqnty: number;
}[]=[];

aryaddressdetails:{
  billid:any;
  billono:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];

  st: string;
  step: string;
  eventdate: string;

  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;
  purchaserateafterqntypre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];
  purchaserateafterqnty: { billid:any; purchaserateafterqntypre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalrate: number;
  summy: { billid:any; gst: number, rate: number, taxableamt: number, amtaftdisc:number, prate:number, purchaserateafterqnty:number }[]= [];
  summypre: { billid:any; gst: number, rate: number, taxableamt: number, amtaftdisc:number, prate:number, purchaserateafterqnty:number }[]= [];

  pcollection: any;

  pressedtogglebtn: any;

  billbarcode: string; 
  updatecollection: any;
  bcollection: any;
  filedata: string;

constructor(private data: Drawer13Service, private afs: AngularFirestore, public fb: FormBuilder, public snackBar: MatSnackBar, private changeDetection: ChangeDetectorRef) {   }


addorreplace(array, item){
 const i=array.findIndex(_item => _item.billid===item.billid);
 if(i>-1) array[i] =item;
 else array.push(item);
}

addorreplace2(array, item){
  const i=array.findIndex(_item => _item.billid===item.billid && _item.code===item.code);
  if(i>-1) array[i] =item;
  else array.push(item);
 }

@ViewChildren('allTheseThings') things: QueryList<any>;


ngForRendred() {

  //console.log('NgFor is Rendered');
  snipMe();

}


ngAfterViewInit() {

  this.st="step1";
  this.step="step1";
this.favoriteCustomer=this.customers[3]; // set default radio button checked
this.selectedDate=moment().format('DD-MM-YYYY')
this.eventdate=moment().format('DD-MM-YYYY')

this.data.currentMessage13.subscribe(message => this.message = message)
//console.log("PL"+this.message);

// this.prcollection =[];
// //this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-12-2019')).snapshotChanges()
// this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-02-2020')).snapshotChanges()

//   .pipe(
//     map(actions => actions.map(a => a.payload.doc.data()))
//   );
  this.prdcollection =[];
  this.prdcollection = this.afs.collection('Bills').doc(this.selectedDate).collection('1').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );


  
        this.updatecollection = this.afs.collection('Bills').snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  

  this.myary=[];

  this.prcollection =[];
  // this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.selectedDate))
  // .get().toPromise()
  // .then(querySnapshot => {
  //     querySnapshot.forEach(doc => {
  //         // doc.data() is never undefined for query doc snapshots
  //         //console.log(doc.id, " => ", doc.data());
  //         //this.myary.push({'id':doc.data().billsid[0]});
  //         for(var i = 0; i < doc.data().totalbills ; i++){
  //           this.myary.push({'billid':doc.data().billsid[i], 'checked':false});
  //       }
  //         //console.log("GGGGG: "+JSON.stringify(this.myary));

  //     });
  // })
  // .catch(function(error) {
  //     //console.log("Error getting documents: ", error);
  // });




  
        this.bcollection =   this.afs.collection('Bills').doc(this.eventdate).collection('--Id--').snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.bcollection.subscribe((posts) => {
    this.afs.collection('Bills', ref => ref.where('date2', "==" , this.selectedDate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          //this.myary.push({'id':doc.data().billsid[0]});
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':doc.data().billsid[i], 'checked':false});
        }
          //console.log("GGGGG: "+JSON.stringify(this.myary));
      });
  })
  .catch(function(error) {
      //console.log("Error getting documents: ", error);
  });

  
  })








  this.things.changes.subscribe(t => {
    this.ngForRendred();
  })
  
}

updatepagecard(ps:string){
if(ps=="a4"){  
  this.st="step1";
  this.step="step1";
}
if(ps=="a5"){
  this.st="step2";
  this.step="step2";
}
if(ps=="tp"){
  this.st="step3";
  this.step="step3";
}

this.ngForRendred();
this.ShowHideButton();

}


onSelect(event){
  //console.log(event);
  this.selectedDate = event;
  //--newly added---
    snipMe();
      //--newly added---

    this.eventdate=moment(event).format('DD-MM-YYYY');

    //this.snackBar.open("MESSAGE", "ACTION" ? 'OK' : undefined, {duration: 2000,verticalPosition:'bottom',horizontalPosition:'end',panelClass :['sbowncss']});
    //this.snackBar.open(moment(event).format('DD-MM-YYYY'), "ACTION" ? 'OK' : undefined, {duration: 2000,verticalPosition:'bottom',horizontalPosition:'end',panelClass :['sbowncss']});
    //this.snackBar.open(moment(event).format('DD-MM-YYYY'), "ACTION" ? 'OK' : undefined, {duration: 200000,panelClass :['sbowncss']});


    //this.selectedDate=(moment(event).format('DD-MM-YYYY'));
    ////console.log(String(this.selectedDate));

    this.prdcollection =[];
  this.prdcollection = this.afs.collection('Bills').doc(moment(event).format('DD-MM-YYYY')).collection('1').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  this.tottaxableamtpre=0;
  this.totamtwithtaxpre=0; 
  this.sumaftrdiscpre=0; 
  this.purchaserateafterqntypre=0;

  this.totalamountpre=0;

  this.tottaxableamt=[];
  this.totamtwithtax=[]; 
  this.sumaftrdisc=[]; 
this.totalamount=[];
  
  this.summy=[];
  this.arybilldetails=[];
  this.aryaddressdetails=[];
  this.myary=[];
  this.prcollection =[];
  this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , moment(event).format('DD-MM-YYYY')))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          //this.myary.push({'id':doc.data().billsid[0]});
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.myary.push({'billid':doc.data().billsid[i], 'checked':false});
        }
          //console.log("GGGGG: "+JSON.stringify(this.myary));
      });
  })
  .catch(function(error) {
      //console.log("Error getting documents: ", error);
  });
  
  }


  updateCheckedOptions(tta,i){
    
    snipMe();

    tta.checked = !tta.checked;

    this.tottaxableamtpre=0;
    this.totamtwithtaxpre=0; 
    this.sumaftrdiscpre=0; 
    this.purchaserateafterqntypre=0;

    this.totalamountpre=0;


  this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
        this.pcollection =   this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().routinebillid)).snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
 
  this.pcollection.subscribe((posts) => {
    if(tta.checked==true){
      
      snipMe();
      this.summy=this.summy.filter(item => item.billid !== tta.billid)

    //console.log("posty:"+posts); //This is the array of posts, not an observable
    this.changeDetection.detectChanges();
    // this.summy=_.uniqWith(this.summy,(obj1,obj2)=>
    // obj1.billid === obj2.bilid&&
    // obj1.amtaftdisc === obj2.amtaftdisc
    // );

    this.summy=_.uniqWith(this.summy,(obj1,obj2)=>
    obj1.billid === obj2.bilid&&
    obj1.amtaftdisc === obj2.amtaftdisc
    );
    this.changeDetection.detectChanges();
      if(tta.billid.substring(3,4)=='G'){
    this.summypre=(_(posts).groupBy('cgst')
      .map((objs, key) => {
          return {
        'billid':tta.billid,
        'cgst': key,             
        'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
        'gstp':(key*2),
        'cgstp':(parseFloat(key)),
        'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'sgstp':(parseFloat(key)),
        'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'igstp':(parseFloat('0')),
        'igsta':(parseFloat('0.00')),
        'taxableamt': 2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'prate':(_.sumBy(objs, 'prate')),
        'purchaserateafterqnty':(_.sumBy(objs, 'purchaserateafterqnty')),
      }
      })
      .value()
    );
    this.changeDetection.detectChanges();
      this.summy=this.summy.concat(this.summypre);
      this.changeDetection.detectChanges();

    this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
    this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
    this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
    this.purchaserateafterqntypre=this.summypre.reduce((sum2, item2) => sum2 + item2.purchaserateafterqnty, 0);
    this.changeDetection.detectChanges();
    this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
    this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
    this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})
    this.addorreplace(this.purchaserateafterqnty,{'billid':tta.billid, purchaserateafterqntypre:this.purchaserateafterqntypre})
    this.changeDetection.detectChanges();
    // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
    // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
    // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

    //console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));

    //console.log("!!!!!!!!:"+JSON.stringify(this.summypre));
    //Array.from(this.summy.reduce((m,t) => m.set(t.billid, t), new Map()).values()) //--------------Removes duplicate arrays-----------------

    //this.summy=Array.from(this.summy.reduce((m,t) => m.set(t.billid && t.amtaftdisc, t), new Map()).values()) 
   // this.summy=Array.from(this.summy.reduce((m,t) => m.set(t.amtaftdisc, t), new Map()).values()) 
 
      this.summy=_.uniqWith(this.summy,_.isEqual);
      this.changeDetection.detectChanges();
      //console.log("SUMMYYY:"+JSON.stringify(this.summy));

      this.changeDetection.detectChanges();

    }

      if(tta.billid.substring(3,4)=='I'){
        this.summypre=(_(posts).groupBy('igst')
        .map((objs, key) => {
          return {
              'billid':tta.billid,
              'igst': key,
              'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
              'gstp':(0),
              'cgstp':(parseFloat('0')),
              'cgsta':(parseFloat('0.00')),
              'sgstp':(parseFloat('0')),
              'sgsta':(parseFloat('0.00')),
              'igstp':(parseFloat(key)),
              'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
              'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
              'prate':(_.sumBy(objs, 'prate')),   
              'purchaserateafterqnty':(_.sumBy(objs, 'purchaserateafterqnty')),   
            }
      })
      .value()
        );
        this.summy=this.summy.concat(this.summypre);

        
        this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
        this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
        this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
        this.purchaserateafterqntypre=this.summypre.reduce((sum2, item2) => sum2 + item2.purchaserateafterqnty, 0);

        this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
        this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
        this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})
        this.addorreplace(this.purchaserateafterqnty,{'billid':tta.billid, purchaserateafterqntypre:this.purchaserateafterqntypre})

        // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
        // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
        // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

        //console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
        this.summy=_.uniqWith(this.summy,_.isEqual);

          //console.log("SUMMYYY:"+JSON.stringify(this.summy));
        }

        if(tta.billid.substring(3,4)=='T'){
          this.summypre=(_(posts).groupBy('taxfree')
          .map((objs, key) => {
            return {
                'billid':tta.billid,
                'taxfree': key,
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'gstp':(0),
                'cgstp':(parseFloat('0')),
                'cgsta':(parseFloat('0.00')),
                'sgstp':(parseFloat('0')),
                'sgsta':(parseFloat('0.00')),
                'igstp':(parseFloat('0')),
                'igsta':(parseFloat('0.00')),
                'taxableamt':(parseFloat('0.00')),
                'prate':(_.sumBy(objs, 'prate')),
                'purchaserateafterqnty':(_.sumBy(objs, 'purchaserateafterqnty')), 
                 }
        })
        .value()
          );
          this.summy=this.summy.concat(this.summypre);
          
          this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
          this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
          this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
          this.purchaserateafterqntypre=this.summypre.reduce((sum2, item2) => sum2 + item2.purchaserateafterqnty, 0);

          this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
          this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
          this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})
          this.addorreplace(this.purchaserateafterqnty,{'billid':tta.billid, purchaserateafterqntypre:this.purchaserateafterqntypre})

          // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
          // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
          // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

          //console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
          this.summy=_.uiqWith(this.summy,_.isEqual);

          //console.log("SUMMYYY:"+JSON.stringify(this.summy));

          }

    //console.log("posts:"+posts); //This is the array of posts, not an observable
    this.changeDetection.detectChanges();
    let ratetotal = 0;
    let amounttotal = 0;
  //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts.length);
  for (var i = 0; i < posts.length; i++) {
      if (posts[i].rate) {
        //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].rate);
          ratetotal += posts[i].rate;
          this.totalrate = ratetotal;
          this.changeDetection.detectChanges();
          amounttotal += (posts[i].rate * posts[i].qnty);
          this.totalamountpre = amounttotal;
          this.changeDetection.detectChanges();
      }
  }

  this.addorreplace(this.totalamount,{'billid':tta.billid, totalamountpre:this.totalamountpre})
  this.changeDetection.detectChanges();
  // this.totalamount.push({'billid':tta.billid, totalamountpre:this.totalamountpre})

  //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+ratetotal);
  return [ratetotal,amounttotal];
  }
  this.changeDetection.detectChanges();
  if(tta.checked==false){
    //console.log("GGG: "+JSON.stringify(this.summy));
    // const summyary =  this.summy.map(function(e) { return e.gst,e.billid; }).indexOf(tta.gst && tta.bilid);
    // if (summyary > -1) {
    //   this.summy.splice(summyary, 1);
    // }

    // const summyary =  this.summy.map(function(e) { return e.billid; }).indexOf(tta.bilid);
    // if (summyary > -1) {
    //   //console.log(summyary)

    //   this.summy.splice(summyary, 1);
    // }
    this.summy=this.summy.filter(item => item.billid !== tta.billid)

    //console.log("GGGGG: "+JSON.stringify(this.summy));
  }
  this.changeDetection.detectChanges();
  });

  
 

})
})


  this.afs.collection('Bills').doc(this.eventdate).collection('--stats--').doc(tta.billid.substring(4)).get().toPromise().then(doc => {
        if(tta.checked==true){
         //console.log("NNNNNN"+doc.data().customerName);

         this.addorreplace(this.aryaddressdetails,{'billid':tta.billid,
         billono:doc.data().billrefno,
            customerName: doc.data().customerName,
            customerAddress11: doc.data().customerAddress11,
            customerAddress12: doc.data().customerAddress12,
            customerAddress21: doc.data().customerAddress21,
            customerAddress22: doc.data().customerAddress22,
            customerContact: doc.data().customerContact,
            customerGst: doc.data().customerGst,
            customerHonor: doc.data().customerHonor,
          });

          // this.aryaddressdetails.push({'billid':tta.billid,
          //   customerName: doc.data().customerName,
          //   customerAddress11: doc.data().customerAddress11,
          //   customerAddress12: doc.data().customerAddress12,
          //   customerAddress21: doc.data().customerAddress21,
          //   customerAddress22: doc.data().customerAddress22,
          //   customerContact: doc.data().customerContact,
          //   customerGst: doc.data().customerGst,
          //   customerType: doc.data().customerType,
          // });

            //console.log("YYYYY"+JSON.stringify(this.aryaddressdetails));
        }
        if(tta.checked==false){
          //console.log("GGG: "+JSON.stringify(this.aryaddressdetails));
          const addrdetailsary =  this.aryaddressdetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
          if (addrdetailsary > -1) {
            this.aryaddressdetails.splice(addrdetailsary, 1);
          }
          //console.log("GGGGG: "+JSON.stringify(this.aryaddressdetails));
        }
        
      return false;         

  });


    //console.log(tta.checked);


    

    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
          this.pcollection =   this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().routinebillid)).snapshotChanges()      
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );
   
    this.pcollection.subscribe((posts) => {





    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
      this.afs.collection('Bills').doc(this.eventdate).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          if(tta.checked==true){
           // this.myary.push({'billid':tta.billid});

           this.addorreplace2(this.arybilldetails,{'billid':tta.billid,
            'code':doc.data().code,
            'productname': doc.data().productname,
            'hsncode' : doc.data().hsncode,
            'discount' : doc.data().discount,
            'qnty' : doc.data().qnty,
            'unit2' : doc.data().unit2,
            'rate': doc.data().rate,
            'cgst': doc.data().cgst,
            'sgst': doc.data().sgst,
            'igst': doc.data().igst,
            'prate': (doc.data().prate !=undefined) ? doc.data().prate : 0,
            'purchaserateafterqnty': (doc.data().purchaserateafterqnty !=undefined) ? doc.data().purchaserateafterqnty : 0,
          });

            // this.arybilldetails.push({'billid':tta.billid,
            //   'code':doc.data().code,
            //   'productname': doc.data().productname,
            //   'hsncode' : doc.data().hsncode,
            //   'discount' : doc.data().discount,
            //   'qnty' : doc.data().qnty,
            //   'unit2' : doc.data().unit2,
            //   'rate': doc.data().rate,
            //   'cgst': doc.data().cgst,
            //   'sgst': doc.data().sgst,
            //   'igst': doc.data().igst,
            // });
            this.changeDetection.detectChanges();

              //console.log("YYYYY"+JSON.stringify(this.arybilldetails));
          }
          if(tta.checked==false){

            //console.log("GGG: "+JSON.stringify(this.arybilldetails));
            const detailsary =  this.arybilldetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (detailsary > -1) {
              this.arybilldetails.splice(detailsary, 1);
            }
            //console.log("AAAAAA: "+JSON.stringify(this.arybilldetails));

            //console.log("SSS: "+JSON.stringify(this.summy));
            const gstdetailsary =  this.summy.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (gstdetailsary > -1) {
              this.summy.splice(gstdetailsary, 1);
            }
            //console.log("SSSSS: "+JSON.stringify(this.summy));

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
            const tottaxableamtdetailsary =  this.tottaxableamt.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (tottaxableamtdetailsary > -1) {
              this.tottaxableamt.splice(tottaxableamtdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
            this.changeDetection.detectChanges();

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));
            const totamtwithtaxdetailsary =  this.totamtwithtax.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (totamtwithtaxdetailsary > -1) {
              this.totamtwithtax.splice(totamtwithtaxdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.sumaftrdisc));
            const sumaftrdiscdetailsary =  this.sumaftrdisc.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (sumaftrdiscdetailsary > -1) {
              this.sumaftrdisc.splice(sumaftrdiscdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.sumaftrdisc));

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totalamount));
            const totalamountdetailsary =  this.totalamount.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (totalamountdetailsary > -1) {
              this.totalamount.splice(totalamountdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totalamount));
            this.changeDetection.detectChanges();

          }

        }) ;
        return false;         
      }
    
    });
  });


    });
  });
});









  });

  
  snipMe();

   //console.log("R: "+JSON.stringify(this.myary));

   return this.myary;
  }

  selectall(){    
    this.changeDetection.detectChanges();

    this.summy=[];
    this.arybilldetails=[];
    this.aryaddressdetails=[];
    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.eventdate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          for(var i = 0; i <= doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':String(doc.data().billsid[i]), 'checked':true})
            this.selectedOptions.push(String(doc.data().billsid[i]))
            this.updateCheckedOptions({"billid":String(doc.data().billsid[i]), "checked":false},0);
            this.changeDetection.detectChanges();
            if(i==doc.data().totalbills ){
              this.addorreplace(this.myary,{'billid':String(doc.data().billsid[i]), 'checked':true})

              const removelastitem =  this.myary.map(function(e) { return e.billid; }).indexOf(String(doc.data().billsid[i]));
            if (removelastitem > -1) {
              this.myary.splice(removelastitem, 1);
            }
              }

          }
      });
      this.changeDetection.detectChanges();

  })
  .catch(function(error) {
      //console.log("Error getting documents: ", error);
  }).then(()=>{
  this.changeDetection.detectChanges();
})
}

  deselectall(){
    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.eventdate))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':String(doc.data().billsid[i]), 'checked':false})
            //---------------remove an array item-----------------
            const index = this.selectedOptions.indexOf(String(doc.data().billsid[i]));
            if (index > -1 ) {
            this.selectedOptions.splice(index,1)
            }
            //---------------remove an array item-----------------
            this.updateCheckedOptions({"billid":String(doc.data().billsid[i]), "checked":true},0);

          }
      });
  })
  .catch(function(error) {
      //console.log("Error getting documents: ", error);
  });


  }
  
  pressedtogglebtnreset(){
    this.pressedtogglebtn=null;
    //this.selectall();
    this.changeDetection.detectChanges();

  }

  sortagain(){
    //var sortedmyaryagain = this.myary.map(el => el.billid.substring(4))
    this.myary.sort(function(a,b){return a.billid.substring(4) - b.billid.substring(4)})
  }

  srchbrcode(){

    var first =this.billbarcode;
    this.myary.sort(function(x,y){ return x.billid == first ? -1 : y.billid == first ? 1 : 0})

    this.addorreplace(this.myary,{'billid':this.billbarcode, 'checked':true})
    this.addorreplace(this.selectedOptions,this.billbarcode)
    this.updateCheckedOptions({"billid":this.billbarcode, "checked":false},0);
    this.changeDetection.detectChanges();
  }
  ShowHideButton() {
    this.deselectall();

    this.showMainContent = this.showMainContent ? false : true;     
  }
}
