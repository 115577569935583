import { Component,AfterViewInit, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSortable} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../vouchers/format-datepicker';
import { DatePipe } from '@angular/common';
import * as firebase from 'firebase/app';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { preserveWhitespacesDefault } from '@angular/compiler';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class VouchersComponent implements AfterViewInit, OnInit {

  panelOpenState = true;

  public fmForm: FormGroup;

  //displayedColumns = ['date', 'name', 'debit', 'credit', 'description', 'edit', 'delete'];
  displayedColumns = ['createdon', 'name', 'type', 'cost', 'count', 'print', 'edit', 'delete'];
  //displayedColumns = ['date2', 'name', 'debit', 'credit', 'description'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ozkeyid: any;
  ozname: any;
  ozcost: number;
  oztotalcount: number;
  oztype: any="";

  enableUpdate: boolean = false;

  currkeyid: any;
  currtype: any;
  currcost: number;

  printfn:Function;

  preset(el){
    this.currkeyid=el.KEYID;
    this.currtype=el.type;
    this.currcost=el.cost;
  }

  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, public datePipe: DatePipe) { 
    
    this.printfn=function(){
      // this.currkeyid=el.KEYID;
      (<HTMLElement>document.getElementsByClassName('printbtn')[0]).click()
    }
  }

  
  ngAfterViewInit() {

      this.afs.collection<any>('Offerzone').valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.paginator=this.paginator; 

        this.dataSource.sort = this.sort;
        //sorting for date column
        this.dataSource.sortingDataAccessor = (item, property) => {
        //console.log(item)
          switch (property) {
            case 'createdon': {
              return (moment(item.createdon, "DD-MM-YYYY").toDate());
            }
            default: return item[property];
          }
        };

      })
    
}


  trackByUid(index, item) {
    return item.uid;
  }


  ngOnInit() {

    this.Form();  


    this.sort.sort(<MatSortable>{
      id: 'createdon',
      start: 'desc',
    }
  );
  
  }

   
    // Reactive form
    Form() {
      this.fmForm = this.fb.group({
        name: [''],
        cost:  [''],
        totalcount:  [''],
        type:  [''],
      })
    }

    // Accessing form control using getters
    get name() {
      return this.fmForm.get('name');
    }

    get cost() {
      return this.fmForm.get('cost');
    }

    get totalcount() {
      return this.fmForm.get('totalcount');
    }

    get type() {
      return this.fmForm.get('type');
    }

    // Reset form's values
    ResetForm() {
      this.fmForm.reset();
      this.enableUpdate=false;
    }
    

    submitData() {
      this.add();
      this.ResetForm();  // Reset form when clicked on reset button
    };
 

    add() {

      let ids =this.afs.createId();

      this.afs.collection("Offerzone").doc(ids).set({
        KEYID: ids,
        cost: Number(this.ozcost),
        createdon: firebase.firestore.FieldValue.serverTimestamp(),
        name: this.ozname,
        totalcount: Number(this.oztotalcount),
        usedcount: 0,
        type: this.oztype
      })
        .catch((err) => {
          console.log(err);
        })
    }

    read(offzon) {  
      this.ozkeyid = offzon.KEYID;
      this.ozname=offzon.name;
      this.ozcost = offzon.cost;
      this.oztotalcount = offzon.totalcount;
      this.oztype = offzon.type; 
    }

    
  update(oid) {
    this.afs.collection("Offerzone").doc(oid).update({
      cost: Number(this.ozcost),
      //createdon: firebase.firestore.FieldValue.serverTimestamp(),
      name: this.ozname,
      totalcount: Number(this.oztotalcount),
      type: this.oztype
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }

  
  delete(oz) {
    this.afs.collection("Offerzone").doc(oz.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }

}