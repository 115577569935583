import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, Observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';

@Component({
  selector: 'app-productmigration',
  templateUrl: './productmigration.component.html',
  styleUrls: ['./productmigration.component.css']
})
export class ProductmigrationComponent implements OnInit {

  public pcForm: FormGroup;
  prdbrand: any='';
  prdcategory: any='';
  prdcode: any='';
  prdcollection: any;

  floccollection: string[] = ['shop 1', 'shop 2', 'Godown'];
  tloccollection: string[] = ['shop 1', 'shop 2', 'Godown'];

 public qntyForm: FormGroup;
frmloc:any;
toloc:any;
  dsbtndisabled: boolean;
  shftqnty: string;
  rsbtndisabled: boolean;
  bcid: any;
  searched: boolean;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  brcollection: any;
  ctcollection: any;
  codecollection: any;
  qntycollection: any;

  ngOnInit() {
    this.Form();  

    this.brcollection =[];
    this.brcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';

    this.frmloc='';
    this.toloc='';

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.searched=false;
  }

  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      productcode: [''],
    })

    this.qntyForm = this.fb.group({
      flocation: [''],
      tlocation: [''],
    })
  }

   // Accessing form control using getters
   get productbrand() {
    return this.pcForm.get('productbrand');
  }
  get productcategory() {
    return this.pcForm.get('productcategory');
  }
  get productcode() {
    return this.pcForm.get('productcode');
  }

  get flocation() {
    return this.qntyForm.get('flocation');
  }
  get tlocation() {
    return this.qntyForm.get('tlocation');
  }

  changeBrand(br){
      
    this.ctcollection =[];
    this.ctcollection = this.afs.collection('Brands',ref => ref.where('brandname', "==" , br)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
    }

  changeCategory(br,ct){
    console.log(this.prdbrand);
    console.log(this.prdcategory);

    this.codecollection =[];
    this.codecollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
  }

  changeCode(br,ct,cd){

    console.log(this.prdbrand);
    console.log(this.prdcategory);
    console.log(this.prdcode);

    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
  }





  from(fval)
  {
    //alert("hello"+fval);

    if(fval=='shop 1'){
    this.tloccollection = ['shop 2', 'Godown'];
    document.getElementById("fromshop1").classList.add("fromclass")
    document.getElementById("fromshop2").classList.remove("fromclass")
    document.getElementById("fromgodown").classList.remove("fromclass")

    document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.remove("toclass")

//--------newly added----------
document.getElementById("fromshop1").classList.remove("hide")
document.getElementById("toshop1").classList.add("hide")
document.getElementById("fromshop2").classList.add("hide")
document.getElementById("fromgodown").classList.add("hide")
//--------newly added----------
    }
    else if(fval=='shop 2'){
      this.tloccollection = ['shop 1', 'Godown'];
      document.getElementById("fromshop1").classList.remove("fromclass")
      document.getElementById("fromshop2").classList.add("fromclass")
      document.getElementById("fromgodown").classList.remove("fromclass")

      document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.remove("toclass")

//--------newly added----------
document.getElementById("fromshop1").classList.add("hide")
document.getElementById("fromshop2").classList.remove("hide")
document.getElementById("toshop2").classList.add("hide")
document.getElementById("fromgodown").classList.add("hide")
//--------newly added----------
      }
      else if(fval=='Godown'){
        this.tloccollection = ['shop 1', 'shop 2'];
        document.getElementById("fromshop1").classList.remove("fromclass")
        document.getElementById("fromshop2").classList.remove("fromclass")
        document.getElementById("fromgodown").classList.add("fromclass")

        document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.remove("toclass")

//--------newly added----------
document.getElementById("fromshop1").classList.add("hide")
document.getElementById("fromshop2").classList.add("hide")
document.getElementById("fromgodown").classList.remove("hide")
document.getElementById("togodown").classList.add("hide")
//--------newly added----------
        }
  }
  to(tval)
  {
    //alert("hello"+tval);

    if(tval=='shop 1'){
      document.getElementById("toshop1").classList.add("toclass")
      document.getElementById("toshop2").classList.remove("toclass")
      document.getElementById("togodown").classList.remove("toclass")
//--------newly added----------
      document.getElementById("toshop1").classList.remove("hide")
document.getElementById("toshop2").classList.add("hide")
document.getElementById("togodown").classList.add("hide")
//--------newly added----------

      }
      else if(tval=='shop 2'){
        document.getElementById("toshop1").classList.remove("toclass")
        document.getElementById("toshop2").classList.add("toclass")
        document.getElementById("togodown").classList.remove("toclass")
        //--------newly added----------
      document.getElementById("toshop1").classList.add("hide")
      document.getElementById("toshop2").classList.remove("hide")
      document.getElementById("togodown").classList.add("hide")
      //--------newly added----------
        }
        else if(tval=='Godown'){
          document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.add("toclass")
          //--------newly added----------
      document.getElementById("toshop1").classList.add("hide")
      document.getElementById("toshop2").classList.add("hide")
      document.getElementById("togodown").classList.remove("hide")
      //--------newly added----------
          }

  }


  stockshift(shftqnty,cd,br,ct,frmloc,toloc){
    this.dsbtndisabled=true;
    this.rsbtndisabled=false;

    if(this.bcid!=''){
    var pcmd=this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid))
    }
    else{
      var pcmd=this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd))
    }

    if(frmloc=="shop 1" && toloc=="shop 2"){
      pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) - Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{
      this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
    
    })

  }
    

  if(frmloc=="shop 1" && toloc=="Godown"){
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown + Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1) - Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{
      this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    })

  }


  if(frmloc=="shop 2" && toloc=="shop 1"){
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2) - Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{
      this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

this.qntycollection=[];
    this.qntycollection=  pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    })

  }



  if(frmloc=="shop 2" && toloc=="Godown"){
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown + Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) - Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{
      this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    })

  }


  if(frmloc=="Godown" && toloc=="shop 1"){
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown - Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{
      this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    })

  }


  if(frmloc=="Godown" && toloc=="shop 2"){
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown - Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{
      this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    })

  }
  this.dsbtndisabled=false;
  this.rsbtndisabled=false;

  }  
  clear(){
    this.qntycollection='';
    this.searched=false;

  }

  reset(){
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection='';
    this.qntycollection='';
    this.frmloc='';
    this.toloc='';
    this.shftqnty='';
    this.searched=false;

  }
  BCsearch(){
    this.searched=true;
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  }
}

