import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import { forEach,sum} from 'lodash';

@Component({
  selector: 'app-particulars',
  templateUrl: './particulars.component.html',
  styleUrls: ['./particulars.component.css']
})
export class ParticularsComponent implements AfterViewInit, OnInit {
  public particularForm: FormGroup;

  displayedColumns = ['particularName', 'particularAddress', 'particularContact', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  partkeyid: any;
  partname: any;
  partaddress: any;
  partcontact: any;
  ///////filter
  search: any;
  selection: any;

  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder) {   }

  ngAfterViewInit() {
    this.afs.collection<any>('Particulars').valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;


      //It searches name and address columns. To search in entire table skip it
      this.dataSource.filterPredicate = (data, filter) =>
      (data.particularName.indexOf(filter) !== -1 || data.particularAddress.indexOf(filter) !== -1);
      //----------------------------------------------------------------------
      console.log((this.dataSource));
    })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;  
  };

  trackByUid(index, item) {
    return item.uid;
  }

  
  ngOnInit() {
    this.Form();
  }
  
    // Reactive particular form
    Form() {
      this.particularForm = this.fb.group({
        particularname: [null, [Validators.required,Validators.minLength(5), Validators.maxLength(10)]],
        particularaddress: [''],
        particularcontact: [''],
      })
    }

    // Accessing form control using getters
    get particularname() {
      return this.particularForm.get('particularname').setValidators([Validators.required, Validators.minLength(3)]);
    }

    get particularaddress() {
      return this.particularForm.get('particularaddress');
    }

    get particularcontact() {
      return this.particularForm.get('particularcontact');
    }
  
    // Reset particular form's values
    ResetForm() {
      this.particularForm.reset();
    }
    

    submitParticularData() {
      this.add(this.particularForm.value.particularid);
  
      this.ResetForm();  // Reset form when clicked on reset button
  
  
    };
  
  
    // add(id) {
    //   this.afs.collection("Particulars").doc("PAR"+id).set({
    //     particularId: "Particular "+id,
    //     particularName: this.partname,
    //     particularAddress: this.partaddress,
    //     particularContact: this.partcontact,
    //   })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // }

    add(id) {
      let ids =this.afs.createId();
      this.afs.collection("Particulars").doc(ids).set({
        KEYID: ids,
        particularName: this.partname,
        particularAddress: this.partaddress,
        particularContact: this.partcontact,
      })
        .catch((err) => {
          console.log(err);
        })
    }

    read(part) {  
      this.partkeyid = part.KEYID;
      this.partname = part.particularName;
      this.partaddress = part.particularAddress;
      this.partcontact = part.particularContact;

    //   this.afs.collection('Particulars').doc(part.KEYID).collection('1').get().toPromise().then(querySnapshot => {
    //     if (!querySnapshot.empty) {
    //         querySnapshot.forEach(doc => {
    //             let data = doc.data()
    //             //if (data.credit != 16) {
    //             console.log(data.credit);
    //            // }
    //         })
    //     }
    // });


  //   this.afs.collection('Particulars').doc(part.KEYID).collection('1').get().toPromise().then(querySnapshot => {
  //     if (!querySnapshot.empty) {
  //         const docSnapshots = querySnapshot.docs;
  //         for (var i in docSnapshots){
  //           const doc = docSnapshots[i].data().credit;
  //           console.log(doc);
  //         }
  //     }
  // });


  // const addcredit : string[] = [];
  //    this.afs.collection('Particulars').doc(part.KEYID).collection('/1', ref => ref.where('date', '==', '29-11-2019')).get().toPromise().then(querySnapshot => {
  //       if (!querySnapshot.empty) {
  //           querySnapshot.forEach(doc => {
  //             addcredit.push(doc.data().credit);
  //               //if (data.credit != 16) {
  //              // }
  //           })
  //           console.log(sum(addcredit));

  //       }
  //   });

  const addcredit : string[] = [];
  this.afs.collection('Ledger/', ref => ref.where('particularname', '==', 'wer').where('date', '==', '29-11-2019' )).get().toPromise().then(querySnapshot => {
     if (!querySnapshot.empty) {
         querySnapshot.forEach(doc => {
           addcredit.push(doc.data().credit);
             //if (data.credit != 16) {
            // }
         })
         console.log(sum(addcredit));

     }
 });

 
    }

    
  update(pid) {

    this.afs.collection("Particulars").doc(pid).update({
        particularName: this.partname,
        particularAddress: this.partaddress,
        particularContact: this.partcontact,
    }).then(() => {
      console.log('updatedF');
    })
  }

  
  delete(part) {
    this.afs.collection("Particulars").doc(part.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }
}