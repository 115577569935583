import { Component, OnInit, PipeTransform, Pipe, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { LedgerdetailsService } from "../../assets/services/ledgerdetails.service";
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-ledgerbooks',
  templateUrl: './ledgerbooks.component.html',
  styleUrls: ['./ledgerbooks.component.css']
})


export class LedgerbooksComponent implements AfterViewInit, OnInit {
  bkcollection: any[];
  
  constructor(private afs: AngularFirestore, private data: LedgerdetailsService, private route: ActivatedRoute, public router: Router, public dialog: MatDialog) {
    
}
 
ngAfterViewInit() {
  var el =document.getElementsByClassName('rightsidebar3') as HTMLCollectionOf<HTMLElement>;
  el[0].style.cssText="max-width: 950px !IMPORTANT; width: 950px !IMPORTANT;"
  console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ")    
}

  ngOnInit() { 
    this.bkcollection =[];
    this.afs.collection('LedgerBooks',ref => ref.orderBy('bookname','asc')).get().toPromise()
    .then(snapshot=>{snapshot.docs.forEach(doc=>{
      this.bkcollection.push({'id':doc.id,'bookname':doc.data().bookname});
    })
  })
   }

  seeledgerdetail(sld) {  
    console.log(sld);
    this.data.changeMessageCP(sld)
      this.router.navigate(['../ledgerdetails'], {relativeTo: this.route})
  
        }
        
//   titleCase(str) {
//    var splitStr = str.toLowerCase().split(' ');
//    for (var i = 0; i < splitStr.length; i++) {
//        // You do not need to check if i is larger than splitStr length, as your for does that for you
//        // Assign it back to the array
//        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
//    }
//    // Directly return the joined string
//    return splitStr.join(' '); 
// }

// addtodb(nb)
//   {
// this.afs.collection("LedgerBooks").doc(nb).set({
//   bookname: this.titleCase(nb), //sentence case of nb
// })
//   }

    }
