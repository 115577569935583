import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SecureInnerPagesGuard } from './auth/guards/secure-inner-pages-guard-guard.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { AllinoneComponent } from './auth/allinone/allinone.component';
import { HomeComponent } from './home/home.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { LedgerdetailsComponent } from './ledgerdetails/ledgerdetails.component';
import { ParticularsComponent } from './particulars/particulars.component';
import { ProductbuildComponent } from './productbuild/productbuild.component';
import { ProductbuildmfcComponent } from './productbuildmfc/productbuildmfc.component';
import { SidebarlComponent } from './sidebarl/sidebarl.component';
import { PaperlayoutComponent } from './paperlayout/paperlayout.component';
import { BillentryComponent } from './billentry/billentry.component';
import { ProductmigrationComponent } from './productmigration/productmigration.component';
import { BarcodelayoutComponent } from './barcodelayout/barcodelayout.component';
import { ProductfiltersComponent } from './productfilters/productfilters.component';
import { BilldetailsComponent } from './billdetails/billdetails.component';
import { EmployeesComponent } from './employees/employees.component';
import { CustomersComponent } from './customers/customers.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SettingsComponent } from './settings/settings.component';
import { BillreturnsComponent } from './billreturns/billreturns.component';
import { BrandscategoriesComponent } from './brandscategories/brandscategories.component';
import { BilldeleteComponent } from './billdelete/billdelete.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductmigrationoutComponent } from './productmigrationout/productmigrationout.component';
import { ProductmigrationinComponent } from './productmigrationin/productmigrationin.component';
import { ProductmigrationstockComponent } from './productmigrationstock/productmigrationstock.component';
import { ProductmigrationmanageComponent } from './productmigrationmanage/productmigrationmanage.component';
import { BarcodelayoutnewstockComponent } from './barcodelayoutnewstock/barcodelayoutnewstock.component';
import { ReportsComponent } from './reports/reports.component';
import { DisplaybannersComponent } from './displaybanners/displaybanners.component';
import { CustomerprofileComponent } from './customerprofile/customerprofile.component';
import { BarcodelayoutnewprdComponent } from './barcodelayoutnewprd/barcodelayoutnewprd.component';
import { AlterstockComponent } from './alterstock/alterstock.component';
import { LedgerbooksComponent } from './ledgerbooks/ledgerbooks.component';
import { LedgerfilterComponent } from './ledgerfilter/ledgerfilter.component';
import { BookstagsComponent } from './bookstags/bookstags.component';
import { ReportdetailsComponent } from './reportdetails/reportdetails.component';
import { EstimationComponent } from './estimation/estimation.component';
import { TopsellingComponent } from './topselling/topselling.component';
import { ProfitbillsComponent } from './profitbills/profitbills.component';
import { BrandcategoriesmfcComponent } from './brandcategoriesmfc/brandcategoriesmfc.component';
import { CreditbillsComponent } from './creditbills/creditbills.component';
import { BilleditComponent } from './billedit/billedit.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { WalkoutsComponent } from './walkouts/walkouts.component';

const routes: Routes = [
  {
    path: 'allinone',
    component: AllinoneComponent, canActivate: [SecureInnerPagesGuard]
  },
  // {
  //   path: 'login',
  //   component: LoginComponent, canActivate: [SecureInnerPagesGuard]
  // },
  // {
  //   path: 'resetpassword',
  //   component: ResetpasswordComponent, canActivate: [AuthGuard]
  // },
  // {
  //   path: 'register',
  //   component: RegistrationComponent, canActivate: [SecureInnerPagesGuard]
  // },
  {
    path: 'home',
    component: HomeComponent, canActivate: [AuthGuard]
  },
  {
    path: 'sidebarl',
    component: SidebarlComponent, canActivate: [AuthGuard],
    children:  [
    {
      path: 'productdetails',
      component: ProductdetailsComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'productbuild', component: ProductbuildComponent, outlet: 'side', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'alterstock',
      component: AlterstockComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'dashboard',
      component: DashboardComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    // {
    //   path: 'brandscategories',
    //   component: BrandscategoriesComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    // },
    {
      path: 'particulars',
      component: ParticularsComponent, canActivate: [AuthGuard]
    },
    {
      path: 'employees',
      component: EmployeesComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'customers',
      component: CustomersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'customerprofile',
      component: CustomerprofileComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'suppliers',
      component: SuppliersComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'ledgerbooks', 
      component: LedgerbooksComponent, outlet: 'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'bookstags',component: BookstagsComponent, outlet:'side3', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'ledgerdetails', 
      component: LedgerdetailsComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'ledgerfilter', 
      component: LedgerfilterComponent, outlet: 'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productbuild',
      component: ProductbuildComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'brandscategories',component: BrandscategoriesComponent, outlet:'side2', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'productbuildmfc',
      component: ProductbuildmfcComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'brandscategoriesmfc',component: BrandcategoriesmfcComponent, outlet:'side4', canActivate: [AuthGuard]}
      ]
    },
    {
      path: 'billentry',
      component: BillentryComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'customers', component: CustomersComponent, outlet: 'side', canActivate: [AuthGuard]}
      ]
    },
    // {
    //   path: 'paperlayout',
    //   component: PaperlayoutComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    // },
    {
      path: 'creditbills',
      component: CreditbillsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billedit',
      component: BilleditComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billreturns',
      component: BillreturnsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billdetails',
      component: BilldetailsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'billdelete',
      component: BilldeleteComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'estimation',
      component: EstimationComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigration',
      component: ProductmigrationComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationout',
      component: ProductmigrationoutComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationin',
      component: ProductmigrationinComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationstock',
      component: ProductmigrationstockComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productmigrationmanage',
      component: ProductmigrationmanageComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'barcodelayout',
      component: BarcodelayoutComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'barcodelayoutnewstock',
      component: BarcodelayoutnewstockComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'barcodelayoutnewprd',
      component: BarcodelayoutnewprdComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'productfilters',
      component: ProductfiltersComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'walkouts',
      component: WalkoutsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'reports',
      component: ReportsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'reportdetails',
      component: ReportdetailsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'topselling',
      component: TopsellingComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'profitbills',
      component: ProfitbillsComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'vouchers',
      component: VouchersComponent, outlet:'secondRouter', canActivate: [AuthGuard]
    },
    {
      path: 'settings',
      component: SettingsComponent, outlet:'secondRouter', canActivate: [AuthGuard],
      children: [
        {path: 'barcodelayout',component: BarcodelayoutComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'brandcategoriesmfc',component: BrandcategoriesmfcComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'brandscategories',component: BrandscategoriesComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'bookstags',component: BookstagsComponent, outlet:'thirdRouter', canActivate: [AuthGuard]},
        {path: 'displaybanners',component: DisplaybannersComponent, outlet:'thirdRouter', canActivate: [AuthGuard]}
      ]
    },
  ]
  },
 
  {
    path: '',
    redirectTo: '/sidebarl/(secondRouter:dashboard)',
    pathMatch: 'full'
  }
  ,
  {
    path: '**',
    redirectTo:'/allinone'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
