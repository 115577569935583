import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { Drawer12Service } from "../../assets/services/drawer12.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';

declare function snipMe(): any;


@Component({
  selector: 'topselling',
  templateUrl: './topselling.component.html',
  styleUrls: ['./topselling.component.css']
})

export class TopsellingComponent implements AfterViewInit {
  //----------------default 'today' selected in range list--------------
  @ViewChild(DaterangepickerComponent, {static: true}) private datePicker: DaterangepickerComponent;
  //----------------default 'today' selected in range list--------------

  
  value=0; //progressbar


qntycounters: {KEYID: string, productname: string, qntycount: number, shop1qnty: number, shop2qnty: number, godownqnty: number, purchaserateafterqnty:number, cgst:number, igst:number, amountafteroffermanualdiscountothercharges:number }[]= [];
resArray: {KEYID: string, productname: string, qntycount: number, shop1qnty: number, shop2qnty: number, godownqnty, purchaserateafterqnty:number, cgst:number, igst:number, amountafteroffermanualdiscountothercharges:number }[]= [];


  message12:boolean;

  st: string;
  step: string;


  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }


  filterValue = {
    dates: String(moment().format('DD-MM-YYYY')),
    datee: String(moment().format('DD-MM-YYYY')),
  };

  todaydate=moment().format('DD-MM-YYYY'); 


  dateRangeFired=false;
  summy2:{ billid:any, shop:string, gst: number, rate: number, taxtype:string, taxableamt: number, amtaftdisc:number }[]= [];
  acollection: any;

  // selectedOptions: string[] = [];

  constructor(private _snackBar: MatSnackBar, private data12: Drawer12Service, private afs: AngularFirestore) { }
  @ViewChildren('allTheseThings') things: QueryList<any>;

  
  ngForRendred() {
    console.log('NgFor is Rendered');
    snipMe();
    }


    
   addorreplace(array, item){
    const i=array.findIndex(_item => _item.KEYID===item.KEYID);
    if(i>-1) array[i] =item;
    else array.push(item);
   }


   openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition:'top',
      horizontalPosition: 'end',      
      panelClass: 'snackbar'
    });
  }

  ngAfterViewInit() {
//----------------default 'today' selected in range list--------------
// this.datePicker.setStartDate(moment());
// this.datePicker.setEndDate(moment());
// this.datePicker.updateView();
//----------------default 'today' selected in range list--------------
    this.openSnackBar("Select Date Range First!","OK");
  this.st="step1";
  this.step="step1";

    this.data12.currentMessage12.subscribe(message12 => this.message12 = message12)
console.log("PL"+this.message12);

//this.applyFilter();

//-----------------------newly added----------------------------------------------------------------
// this.acollection = this.afs.collection('Bills').snapshotChanges()
// .pipe( 
//   map(actions => actions.map(a => a.payload.doc.data()))
// );

// this.acollection.subscribe(() => {this.applyFilter()})
//-----------------------newly added----------------------------------------------------------------


  }
         
  rangeClicked(range) {
    console.log('[rangeClicked] range is : ', range);
    
   console.log(range.dates[0].format('DD-MM-YYYY'));
   console.log(range.dates[1].format('DD-MM-YYYY'));
   this.applyFilter2(0,range.dates[0],range.dates[1])
  }
  datesUpdated(range) {
    if (this.dateRangeFired) {
      this.dateRangeFired = false;
      console.log("dateRangeFired not fired")

      console.log('[datesUpdated] range is : ', range);

      console.log(range.startDate.format('DD-MM-YYYY'));
      console.log(range.endDate.format('DD-MM-YYYY'));
      this.applyFilter2(1, range.startDate, range.endDate)
      return;
    }
    this.dateRangeFired = true;

  }
  choosedDate(range) {
    console.log('[choosedDate] range is : ', range);

  console.log(range.startDate.format('DD-MM-YYYY'));
  console.log(range.endDate.format('DD-MM-YYYY'));
  this.applyFilter2(2,range.startDate,range.endDate)
   }

 async applyFilter2(status,sd,ed) {

   // console.log(event.startDate);
   // console.log(event.endDate);
if(status==0){
    this.filterValue.dates = moment(sd).format('DD-MM-YYYY'); 
    this.filterValue.datee = moment(ed).format('DD-MM-YYYY'); 
    console.log("S: "+this.filterValue.dates);
    console.log("E: "+this.filterValue.datee);
}
if(status==1 || status==2){
  this.filterValue.dates = moment(sd).format('DD-MM-YYYY'); 
  this.filterValue.datee = moment(ed).format('DD-MM-YYYY'); 
  console.log("S: "+this.filterValue.dates);
  console.log("E: "+this.filterValue.datee);
}
    this.applyFilter();
  };


  applyFilter(){
  //this.resArray=[];
    this.afs.collection('Bills').get().toPromise().then(querySnapshot => {

      querySnapshot.forEach(doc => {
        if((moment(doc.data().date2, "DD-MM-YYYY").toDate()) >= (moment(this.filterValue.dates, "DD-MM-YYYY").toDate())
        && (moment(doc.data().date2, "DD-MM-YYYY").toDate()) <= (moment(this.filterValue.datee, "DD-MM-YYYY").toDate())
        && doc.data().billsid)
        {
          console.log(true,moment(doc.data().date2, "DD-MM-YYYY").toDate());

          for(let i=1;i<=doc.data().billsid.length;i++){ 
        
          this.afs.collection('Bills').doc(moment(moment(doc.data().date2, "DD-MM-YYYY").toDate()).format("DD-MM-YYYY")).collection('--Id--',ref => ref.where('currentbillid', '==', Number(doc.data().billsid[i-1].substring(4)))).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docy => {
         this.afs.collection('Bills').doc(moment(moment(doc.data().date2, "DD-MM-YYYY").toDate()).format("DD-MM-YYYY")).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docz => {
              this.qntycounters.push({'KEYID':docz.data().KEYID , 'productname':docz.data().productname, 'qntycount':docz.data().qnty, 'shop1qnty':0 , 'shop2qnty':0 , 'godownqnty':0, 'purchaserateafterqnty':docz.data().purchaserateafterqnty, 'cgst':docz.data().cgst, 'igst':docz.data().igst, 'amountafteroffermanualdiscountothercharges':docz.data().amountafteroffermanualdiscountothercharges});
           
            this.resArray=this.qntycounters.reduce((r, { KEYID, productname, qntycount, shop1qnty, shop2qnty, godownqnty, purchaserateafterqnty, cgst, igst, amountafteroffermanualdiscountothercharges }) => {
        var temp = r.find(o => o.KEYID === KEYID);
        if (!temp) {
            r.push({ KEYID, productname, qntycount, shop1qnty, shop2qnty, godownqnty, purchaserateafterqnty, cgst, igst, amountafteroffermanualdiscountothercharges });
        } else {
            temp.qntycount += qntycount;
            temp.purchaserateafterqnty += purchaserateafterqnty;
            temp.cgst = cgst;
            temp.igst = igst;
            temp.amountafteroffermanualdiscountothercharges += amountafteroffermanualdiscountothercharges;
        }
        return r;
    }, []);
    

    //---START---SOLD OUT SORT---//
    this.resArray=this.resArray.sort((n1,n2) => {
    if (n1.qntycount < n2.qntycount) {
        return 1;
    }

    if (n1.qntycount > n2.qntycount) {
        return -1;
    }

    //---START---PROFIT RATE SORT IF SOLD OUT VALUE SAME---//
    if (n1.qntycount = n2.qntycount) {
    if ((n1.amountafteroffermanualdiscountothercharges-n1.purchaserateafterqnty) < (n2.amountafteroffermanualdiscountothercharges-n2.purchaserateafterqnty)) {
      return 1;
  }
  if ((n1.amountafteroffermanualdiscountothercharges-n1.purchaserateafterqnty) > (n2.amountafteroffermanualdiscountothercharges-n2.purchaserateafterqnty)) {
      return -1;
  }
}
//---END---PROFIT RATE SORT IF SOLD OUT VALUE SAME---//

    return 0;
});
//---END---SOLD OUT SORT---//

// this.resArray=this.resArray.sort((n1,n2) => {
//   if ((n1.amountafteroffermanualdiscountothercharges-n1.purchaserateafterqnty) < (n2.amountafteroffermanualdiscountothercharges-n2.purchaserateafterqnty)) {
//       return 1;
//   }

//   if ((n1.amountafteroffermanualdiscountothercharges-n1.purchaserateafterqnty) > (n2.amountafteroffermanualdiscountothercharges-n2.purchaserateafterqnty)) {
//       return -1;
//   }

//   return 0;
// });

console.log(JSON.stringify(this.resArray));
for (var j = 0; j < this.resArray.length; j++){
  console.log(this.resArray[j].KEYID);
  this.afs.collection('Products').doc(String(this.resArray[j].KEYID))
            .get().toPromise().then(docq => {
            var  objIndex = this.resArray.findIndex((obj => obj.KEYID == String(docq.data().KEYID)));

this.resArray[objIndex].shop1qnty=docq.data().qntyshop1;
this.resArray[objIndex].shop2qnty=docq.data().qntyshop2;
this.resArray[objIndex].godownqnty=docq.data().qntygodown;
            })
}


           })
                          })

  })
})

      }
      
        }
        else{

        }
      
  })
})

          this.qntycounters=[];
                  this.resArray=[];

  }
  getSum(column) : number {
    let sum = 0;
    for(let i = 0; i < this.resArray.length; i++) {
      sum += this.resArray[i][column];
    }
    return sum;
  }
  getgstamnt() : number {
    let sum = 0;
    for(let i = 0; i < this.resArray.length; i++) {
      sum += this.resArray[i]['amountafteroffermanualdiscountothercharges'] ;
    }
    return sum;
  }
}

