import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer14Service {

  private messageSource14 = new BehaviorSubject(true); //false is 'default message'
  currentMessage14 = this.messageSource14.asObservable();

  constructor() { }

  changeMessage14(message14: boolean) {
    this.messageSource14.next(message14)
  }

}