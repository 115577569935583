import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class Drawer13Service {

  private messageSource13 = new BehaviorSubject(true); //false is 'default message'
  currentMessage13 = this.messageSource13.asObservable();

  constructor() { }

  changeMessage13(message13: boolean) {
    this.messageSource13.next(message13)
  }

}