import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, Observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';

@Component({
  selector: 'app-productmigrationstock',
  templateUrl: './productmigrationstock.component.html',
  styleUrls: ['./productmigrationstock.component.css']
})
export class ProductmigrationstockComponent implements OnInit {

  public pcForm: FormGroup;
  prdbrand: any='';
  prdcategory: any='';
  prdcode: any='';
  prdcollection: any;

  floccollection: string[] = ['shop 1', 'shop 2', 'Godown'];
  tloccollection: string[] = ['shop 1', 'shop 2', 'Godown'];

 public qntyForm: FormGroup;
frmloc:any;
toloc:any;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  brcollection: any;
  ctcollection: any;
  codecollection: any;
  qntycollection: any;
  stckqntycollection: any;

  ngOnInit() {
    this.Form();  

    this.brcollection =[];
    this.brcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';

    this.frmloc='';
    this.toloc='';

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    //this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


  }

  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      productcode: [''],
    })

    this.qntyForm = this.fb.group({
      flocation: [''],
      tlocation: [''],
    })
  }

   // Accessing form control using getters
   get productbrand() {
    return this.pcForm.get('productbrand');
  }
  get productcategory() {
    return this.pcForm.get('productcategory');
  }
  get productcode() {
    return this.pcForm.get('productcode');
  }

  get flocation() {
    return this.qntyForm.get('flocation');
  }
  get tlocation() {
    return this.qntyForm.get('tlocation');
  }

  changeBrand(br){
      
    this.ctcollection =[];
    this.ctcollection = this.afs.collection('Brands',ref => ref.where('brandname', "==" , br)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
    }

  changeCategory(br,ct){
    console.log(this.prdbrand);
    console.log(this.prdcategory);

    this.codecollection =[];
    this.codecollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
  }

  changeCode(br,ct,cd){

    console.log(this.prdbrand);
    console.log(this.prdcategory);
    console.log(this.prdcode);

    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
    //this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  }





  from(fval)
  {
    //alert("hello"+fval);

    if(fval=='shop 1'){
    this.tloccollection = ['shop 2', 'Godown'];
    document.getElementById("fromshop1").classList.add("fromclass")
    document.getElementById("fromshop2").classList.remove("fromclass")
    document.getElementById("fromgodown").classList.remove("fromclass")

    document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.remove("toclass")

    }
    else if(fval=='shop 2'){
      this.tloccollection = ['shop 1', 'Godown'];
      document.getElementById("fromshop1").classList.remove("fromclass")
      document.getElementById("fromshop2").classList.add("fromclass")
      document.getElementById("fromgodown").classList.remove("fromclass")

      document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.remove("toclass")

      }
      else if(fval=='Godown'){
        this.tloccollection = ['shop 1', 'shop 2'];
        document.getElementById("fromshop1").classList.remove("fromclass")
        document.getElementById("fromshop2").classList.remove("fromclass")
        document.getElementById("fromgodown").classList.add("fromclass")

        document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.remove("toclass")

        }
  }
  to(tval)
  {
    //alert("hello"+tval);

    if(tval=='shop 1'){
      document.getElementById("toshop1").classList.add("toclass")
      document.getElementById("toshop2").classList.remove("toclass")
      document.getElementById("togodown").classList.remove("toclass")
      }
      else if(tval=='shop 2'){
        document.getElementById("toshop1").classList.remove("toclass")
        document.getElementById("toshop2").classList.add("toclass")
        document.getElementById("togodown").classList.remove("toclass")
        }
        else if(tval=='Godown'){
          document.getElementById("toshop1").classList.remove("toclass")
          document.getElementById("toshop2").classList.remove("toclass")
          document.getElementById("togodown").classList.add("toclass")
          }

  }


  stockshift(shftqnty,cd,br,ct,frmloc,toloc){
    //alert("hello"+shftqnty+cd+br+ct+frmloc+toloc);
    
    if(frmloc=="shop 1" && toloc=="shop 2"){
    this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          brand: br,
          category: ct, 
          code: cd,
          shop1toshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );



    })

   }
    

   if(frmloc=="shop 1" && toloc=="Godown"){
    this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown + Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          brand: br,
          category: ct, 
          code: cd,
          shop1togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );



    })

   }


   if(frmloc=="shop 2" && toloc=="shop 1"){
    this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          brand: br,
          category: ct, 
          code: cd,
          shop2toshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );



    })

   }



   if(frmloc=="shop 2" && toloc=="Godown"){
    this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown + Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          brand: br,
          category: ct, 
          code: cd,
          shop2togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );



    })

   }


   if(frmloc=="Godown" && toloc=="shop 1"){
    this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          brand: br,
          category: ct, 
          code: cd,
          godowntoshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );



    })

   }


   if(frmloc=="Godown" && toloc=="shop 2"){
    this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          brand: br,
          category: ct, 
          code: cd,
          godowntoshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , this.prdcategory).where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );



    })

   }


  }
  
  
  revertbackshop1toshop2qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1toshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

  completeshop1toshop2qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1toshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

  revertbackshop1togodownqnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

  completeshop1togodownqnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown + Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

  revertbackshop2toshop1qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop2toshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

  completeshop2toshop1qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop2toshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

  revertbackshop2togodownqnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : doc.data().qntygodown,
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop2togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    })
  }

completeshop2togodownqnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : doc.data().qntygodown + Number(shftqnty),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        shop2togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  
    
  this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
  })
}

revertbackgodowntoshop1qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : doc.data().qntygodown + Number(shftqnty),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  
    
  this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
  })
}

completegodowntoshop1qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : doc.data().qntygodown,
      qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  
    
  this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
  })
}

revertbackgodowntoshop2qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : doc.data().qntygodown + Number(shftqnty),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  
    
  this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
  })
}

completegodowntoshop2qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : doc.data().qntygodown,
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  
    
  this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
  })
}


}

