import { Component,AfterViewInit, OnInit, ChangeDetectorRef, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import 'rxjs';
import { groupBy, mergeMap, toArray, debounceTime, tap } from 'rxjs/operators';

import { from, zip, of } from 'rxjs';
export interface Item { name: string; rate: number;}
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { equal } from 'assert';
import { database } from 'firebase';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-estimation',
  templateUrl: './estimation.component.html',
  styleUrls: ['./estimation.component.css']
})
export class EstimationComponent implements OnInit {
  bufferValue=0;
  value=0; //progressbar
  favoriteCustomer: string;
  
public BPSForm: FormGroup;
prdbrand: any='';
prdcategory: any='';
prdcode: any='';
prdcollection: any;
searched: boolean;
bcid: any;

ety: string;
etypes: string[] = ['Est. Rate 1 (C&C)', 'Est. Rate 2 (Credit)'];
totalamountpre: number=0.00;
packagingcharge: number=0.00;

//qntycounters
qntycounters: { bcid: string, qntycount: number }[]= [] //declare before constructor
//qntycounters

groupedData: any = [];

myArray: {amtaftdisc: number, gstp: number, cgstp: number, cgsta: number, sgstp: number, sgsta: number, igstp: number, igsta: number, taxableamt: number, rate: number }[]= [] //declare before constructor

date2= moment().format('DD-MM-YYYY');
currentbillid:any;
routinebillid: any;

totalamount: number;
totalestrate: number;
summy: { gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];
hello: string;
custid: any;
custname: any;
custaddress11: any;
custaddress12: any;
custaddress21: any;
custaddress22: any;
custcontact: any;
custgst: any;
custhonor: any;
custaddressS1: any;
custaddressS2: any;
sameaddr: boolean;
prdkeyid: any;
bcbtndisabled: boolean;
clrbtndisabled: boolean;
addbtndisabled: boolean;

billqnty: any;
prdqnty: any;
rembtndisabled: boolean;

arry:{
  code:string;
  qnty:number;
}[]=[];

prdkeyidarray:string[]=[];
billqntyarray:number[]=[];

totbills:number;
crcollection: any;

custcollection: any;
cust: any;

aryaddressdetails:{
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];

estrateary: { prdcode:string, rate:number, prdname:string, hsncode:string, estrate: any, qnty:number, unit2:string}[]= [];
  getEstCounter: string;

constructor(private changeDetection: ChangeDetectorRef, private afs: AngularFirestore, public fb: FormBuilder) {
  this.ety=this.etypes[0];
}
updateetyCheckedOptions()
{
  console.log(this.ety);
}
addorreplace(array, item){
  const i=array.findIndex(_item => _item.prdcode===item.prdcode);
  if(i>-1) array[i] =item;
  else array.push(item);
 }
brcollection: any;
ctcollection: any;
codecollection: any;

Form() {
  this.BPSForm = this.fb.group({
    productbrand: [''],
    productcategory: [''],
    productcode: [''],
  })
}

 // Accessing form control using getters
 get productbrand() {
  return this.BPSForm.get('productbrand');
}
get productcategory() {
  return this.BPSForm.get('productcategory');
}
get productcode() {
  return this.BPSForm.get('productcode');
}


changeBrand(br){
    
  this.ctcollection =[];
  this.ctcollection = this.afs.collection('Brands',ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  }

changeCategory(br,ct){
  console.log(this.prdbrand);
  console.log(this.prdcategory);

  this.codecollection =[];
  this.codecollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
}

changeCode(br,ct,cd){

  console.log(this.prdbrand);
  console.log(this.prdcategory);
  console.log(this.prdcode);

  this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct).where('code', "==" , cd)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    const findPrdCode = this.estrateary.findIndex(a => a.prdcode === this.prdcode)
    if(findPrdCode == -1)
    {
    this.afs.collection('Products',ref => ref.where('code', '==', this.prdcode)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docz => {
        //console.log("ESTARY"+docz.data().estrate);
        if(this.ety=='Est. Rate 1 (C&C)'){
          this.addorreplace(this.estrateary,{'prdcode':docz.data().code, 'rate':docz.data().rate, 'prdname':docz.data().brand +' '+ docz.data().category, 'hsncode' : docz.data().hsncode, 'estrate':docz.data().estrate, 'qnty':docz.data().unit1, 'unit2':docz.data().unit2});
          }
          else{
            this.addorreplace(this.estrateary,{'prdcode':docz.data().code, 'rate':docz.data().rate, 'prdname':docz.data().brand +' '+ docz.data().category, 'hsncode' : docz.data().hsncode, 'estrate':docz.data().estrate2, 'qnty':docz.data().unit1, 'unit2':docz.data().unit2});
          }
//          this.totalamountpre = this.estrateary
// .map(item => (item.estrate * item.qnty))
// .reduce((prev, curr) => prev + curr, 0);

})
}).then(()=>{this.calctotalamount();
})
    }
    else{
      this.estrateary[findPrdCode].qnty = this.estrateary[findPrdCode].qnty+1;
    }
}

  btnsdisabled(){
    this.bcbtndisabled=true;
    this.clrbtndisabled=true;
    this.addbtndisabled=true;
    this.rembtndisabled=true;
  }
   btnsenabled(){
    this.bcbtndisabled=false;
    this.clrbtndisabled=false;
    this.addbtndisabled=false; 
    this.rembtndisabled=false;
    
  }
  async ngOnInit() {
    this.btnsenabled();

    this.sameaddr=true;

  this.custid='';
  this.custname='';
  this.custaddress11='';
  this.custaddress12='';
  this.custaddress21='';
  this.custaddress22='';
  this.custcontact='';
  this.custgst='';
  this.custhonor='';
  this.custaddressS1='';
  this.custaddressS2='';

  this.Form();

  // this.custcollection = this.afs.collection('Customers',ref=> ref.orderBy('customerName')).snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map(a => a.payload.doc.data()))
  //   );

  
    this.brcollection =[];
    this.brcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.searched=false;
  }

  calctotalamount(){
    let estratetotal = 0;
    let estamounttotal = 0;
  //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+estrateary.length);
  for (var i in this.estrateary) {
      if (this.estrateary[i].estrate) {
        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+this.estrateary[i].rate);
          estratetotal += this.estrateary[i].estrate;
          this.totalestrate = estratetotal;
          this.changeDetection.detectChanges();
          estamounttotal += (this.estrateary[i].estrate * this.estrateary[i].qnty);
          this.totalamountpre = estamounttotal;
          this.changeDetection.detectChanges();
      }
  }
  }

// changeCustomer(ct){
//   this.custid=ct.KEYID;
//   this.custname=ct.customerName;
//   this.custaddress11=ct.customerAddress11;
//   this.custaddress12=ct.customerAddress12;
//   this.custaddress21=ct.customerAddress21;
//   this.custaddress22=ct.customerAddress22;
//   this.custcontact=ct.customerContact;
//   this.custgst=ct.customerGst;
//   this.custhonor=ct.customerHonor;
//   this.sameaddr=true;
//   console.log("SA"+this.sameaddr)
//   this.custaddressS1=this.custaddress11;
//   this.custaddressS2=this.custaddress12;

// }

Csearch(ph){
  this.afs.collection('Customers', ref => ref.where('customerContact', '==' , String(ph))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
  this.custid=docy.data().KEYID;
  this.custname=docy.data().customerName;
  this.custaddress11=docy.data().customerAddress11;
  this.custaddress12=docy.data().customerAddress12;
  this.custaddress21=docy.data().customerAddress21;
  this.custaddress22=docy.data().customerAddress22;
  this.custcontact=docy.data().customerContact;
  this.custgst=docy.data().customerGst;
  this.custhonor=docy.data().customerHonor;
  this.sameaddr=true;
  console.log("SA"+this.sameaddr)
  this.custaddressS1=this.custaddress11;
  this.custaddressS2=this.custaddress12;
    })
  });
}

shipaddress(event){
console.log(event.checked);
if(event.checked==true){
  this.custaddressS1=this.custaddress11;
  this.custaddressS2=this.custaddress12;
}
if(event.checked==false){
  this.custaddressS1=this.custaddress21;
  this.custaddressS2=this.custaddress22;
}
}

  clear(){
    this.searched=false;

  }

  reset(){
    this.cust='';
    this.estrateary=[];
    this.totalamountpre=0.00;
    this.packagingcharge=0.00;
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection='';
    this.searched=false;


    this.sameaddr=true;

    this.custid='';
    this.custname='';
    this.custaddress11='';
    this.custaddress12='';
    this.custaddress21='';
    this.custaddress22='';
    this.custcontact='';
    this.custgst='';
    this.custhonor='';
    this.custaddressS1='';
    this.custaddressS2='';


  }
  BCsearch(){
    this.searched=true;
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

        const findPrdCode = this.estrateary.findIndex(a => a.prdcode === this.bcid)
if(findPrdCode == -1)
{
    this.afs.collection('Products',ref => ref.where('code', '==', this.bcid)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docz => {
        console.log(this.ety);
        if(this.ety=='Est. Rate 1 (C&C)'){
          console.log(this.ety);

        this.addorreplace(this.estrateary,{'prdcode':docz.data().code, 'rate':docz.data().rate, 'prdname':docz.data().brand +' '+ docz.data().category, 'hsncode' : docz.data().hsncode, 'estrate':docz.data().estrate, 'qnty':docz.data().unit1, 'unit2':docz.data().unit2});
        }
        else{
          this.addorreplace(this.estrateary,{'prdcode':docz.data().code, 'rate':docz.data().rate, 'prdname':docz.data().brand +' '+ docz.data().category, 'hsncode' : docz.data().hsncode, 'estrate':docz.data().estrate2, 'qnty':docz.data().unit1, 'unit2':docz.data().unit2});
        }
        
//          this.totalamountpre = this.estrateary
// .map(item => (item.estrate * item.qnty))
// .reduce((prev, curr) => prev + curr, 0);

})
}).then(()=>{this.calctotalamount();
})
  }
else{
  this.estrateary[findPrdCode].qnty = this.estrateary[findPrdCode].qnty+1;
}
  }
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>{console.log("fired");});
  }
  
  upcount(bcode,bqnty){
    this.btnsdisabled();

    this.value = 10; //progressbar
    for (var i in this.estrateary) {
      console.log(bcode);
      if (this.estrateary[i].prdcode == bcode) {
        this.estrateary[i].qnty = bqnty+1;
        this.changeDetection.detectChanges();
        console.log("ESTARY"+JSON.stringify(this.estrateary));
         break; //Stop this loop, we found it!
      }
    }
    this.btnsenabled();
    this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});
  }
  downcount(bcode,bqnty){
    this.btnsdisabled();

    this.value = 10; //progressbar
    for (var i in this.estrateary) {
      console.log(bcode);
      if (this.estrateary[i].prdcode == bcode) {
        this.estrateary[i].qnty = bqnty-1;
        this.changeDetection.detectChanges();
        console.log("ESTARY"+JSON.stringify(this.estrateary));
         break; //Stop this loop, we found it!
      }
    }
    this.btnsenabled();
    this.value = 100; //progressbar
  this.delay(1000).then(any=>{
    this.value = 0; //progressbar
});
  }
  remove(bcode){
    // for (var i in this.estrateary) {
    //   if (this.estrateary[i].prdcode == bcode) {
    //     this.estrateary.splice(parseInt(i),1)
    //     break;
    //   }
    // }
    const findIndex = this.estrateary.findIndex(a => a.prdcode === bcode)
    findIndex !== -1 && this.estrateary.splice(findIndex , 1)
    
    this.totalamountpre=0.00;
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection='';
    this.searched=false;

  }


  estimationgenerate(){

    
  this.aryaddressdetails=[{
    customerName: this.custname,
    customerAddress11: this.custaddress11,
    customerAddress12: this.custaddress12,
    customerAddress21: this.custaddressS1,
    customerAddress22: this.custaddressS2,
    customerContact: this.custcontact,
    customerGst: this.custgst,
    customerHonor: this.custhonor,
  }];

  
if(this.ety=='Est. Rate 1 (C&C)'){
    this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
      this.getEstCounter="E1-"+doc.data().countE1;
  })
  .then(()=>{
    this.afs.collection('Estimation').doc(this.getEstCounter).set(
      {
        date:moment().format('DD-MM-YYYY'),
        custid:this.custid,
        estid:this.getEstCounter,
        aryaddressdetails : this.aryaddressdetails,
        estrateary:this.estrateary,
        packagingcharge:this.packagingcharge!=null ? this.packagingcharge : 0.00
      },
   ).then(()=>{
    this.afs.collection('OverallBillCounter').doc('Counter').set({
      countE1:firebase.firestore.FieldValue.increment(1),
   },{merge: true}).then(()=>{
     this.reset();
   })
  })
  })
}
else{
  this.afs.collection('OverallBillCounter').doc('Counter').get().toPromise().then(  doc => {
    this.getEstCounter="E2-"+doc.data().countE2;
})
.then(()=>{
  this.afs.collection('Estimation').doc(this.getEstCounter).set(
    {
      date:moment().format('DD-MM-YYYY'),
      custid:this.custid,
      estid:this.getEstCounter,
      aryaddressdetails : this.aryaddressdetails,
      estrateary:this.estrateary,
      packagingcharge:this.packagingcharge!=null ? this.packagingcharge : 0.00
    },
 ).then(()=>{
  this.afs.collection('OverallBillCounter').doc('Counter').set({
    countE2:firebase.firestore.FieldValue.increment(1),
 },{merge: true}).then(()=>{
   this.reset();
 })
})
})
}
}

}
