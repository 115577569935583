import { Component, ViewEncapsulation, OnInit, Input, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Drawer3Service } from "../../assets/services/drawer3.service";
import { MatCalendarCellCssClasses, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase';
export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-billreturns',
  templateUrl: './billreturns.component.html',
  styleUrls: ['./billreturns.component.css']
})
export class BillreturnsComponent implements AfterViewInit {
  eventdate: any;
 
  discounts: { billid:any; discountspre: number }[]= [];
  otherchg: { billid:any; otherchgpre: number }[]= [];
  dates: { billid:any; datespre: number, totalitempre: number, totalqntypre: number }[]= [];
  billid: any;
  billrefno: any;

  
  arybilldetails:{
    billid:any;
    code:string;
    productname: string;
    hsncode: string;
    discount: number;
    qnty : number;
    unit2 : string;
    rate: number;
    cgst: number;
    sgst: number;
    igst:number;
  }[]=[];

  aryaddressdetails:{
    billid:any;
    billono:any;
    customerName:string;
    customerAddress11: string;
    customerAddress12: string;
    customerAddress21: string;
    customerAddress22: string;
    customerContact: string;
    customerGst: string;
    customerHonor: string;
  
  }[]=[];
  routinebillid: any;
  returnprdary: any;

  counter: { billid:any; cnt: number }[]=[];
  totalworth: number=0;

  printfn:Function;

  currkeyid:any;

  bil:String='';

  constructor(private data3: Drawer3Service, private afs: AngularFirestore) {
    this.printfn=function(){
      // this.currkeyid=el.KEYID;
      (<HTMLElement>document.getElementsByClassName('printbtn')[0]).click()
    }
   }

  addorreplace(array, item){
    const i=array.findIndex(_item => _item.billid===item.billid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }
   
   addorreplace2(array, item){
     const i=array.findIndex(_item => _item.billid===item.billid && _item.code===item.code);
     if(i>-1) array[i] =item;
     else array.push(item);
    }

  ngAfterViewInit() {
    this.arybilldetails=[];
    this.aryaddressdetails=[];
    this.returnprdary=[];
    this.totalworth=0;
  }

  BLsearch(b){
    this.arybilldetails=[];
    this.aryaddressdetails=[];
    this.returnprdary=[];
    this.totalworth=0;

    this.afs.collection('Bills', ref => ref.where('billsrefno', 'array-contains' , String(b)))
    .get().toPromise()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.billrefno=[];
          this.eventdate=doc.data().date2;
            this.billrefno=doc.data().billsrefno;    
            this.billid=doc.data().billsid[this.billrefno.findIndex(function (el){
              return el==String(b)
            })]; 
 

    this.afs.collection('Bills').doc(this.eventdate).collection('--Id--',ref => ref.where('currbillfullid', '==', this.billid)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
        this.routinebillid=docy.data().routinebillid
        this.addorreplace(this.dates,{'billid':docy.data().currbillfullid, datespre:docy.data().timestamp, totalitempre:docy.data().totalitems, totalqntypre:docy.data().totalquantities});
  })
  }).then(()=>{
    this.afs.collection('Bills').doc(this.eventdate).collection(String(this.routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
    this.addorreplace2(this.arybilldetails,{'billid':this.billid,
    'code':doc.data().code,
    'productname': doc.data().productname,
    'hsncode' : doc.data().hsncode,
    'discount' : doc.data().discount,
    'qnty' : doc.data().qnty,
    'unit2' : doc.data().unit2,
    'rate': doc.data().rate,
    'cgst': doc.data().cgst,
    'sgst': doc.data().sgst,
    'igst': doc.data().igst,
  });
})
}
})
  })
    this.afs.collection('Bills').doc(this.eventdate).collection('--DISCOUNTS--',ref => ref.where('billid', '==', this.billid)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
          this.addorreplace(this.discounts,{'billid':docy.data().billid, discountspre:docy.data().manualdisc});
    })
  })
  this.afs.collection('Bills').doc(this.eventdate).collection('--OTHERCHARGES--',ref => ref.where('billid', '==', this.billid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
      this.addorreplace(this.otherchg,{'billid':docy.data().billid, otherchgpre:docy.data().othercharges});
  })
})
console.log(this.dates)
console.log(this.discounts)
console.log(this.otherchg)
console.log(this.arybilldetails)

        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
}
      


remove(c){
  this.afs.collection('Bills').doc(this.eventdate).collection(String(this.routinebillid)).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        if(doc.data().code==c){
  this.addorreplace2(this.returnprdary,{'billid':this.billid,
  'prdkeyid':doc.data().KEYID,
  'code':doc.data().code,
  'productname': doc.data().productname,
  'hsncode' : doc.data().hsncode,
  'discount' : doc.data().discount,
  'qnty' : doc.data().qnty,
  'unit2' : doc.data().unit2,
  'rate': doc.data().rate,
  'cgst': doc.data().cgst,
  'sgst': doc.data().sgst,
  'igst': doc.data().igst,
});
        }
})
}
}).then(()=>{
  this.totalworth=0;
  for(let i=0;i<this.returnprdary.length;i++){
    this.totalworth=this.totalworth+Math.ceil(
      Number(((this.returnprdary[i].qnty * this.returnprdary[i].rate)
      -((this.returnprdary[i].qnty * this.returnprdary[i].rate))) 
      + (((this.returnprdary[i].qnty * this.returnprdary[i].rate)
      -((this.returnprdary[i].qnty * this.returnprdary[i].rate)))*(this.returnprdary[i].sgst/100))
       + (((this.returnprdary[i].qnty * this.returnprdary[i].rate)
       -((this.returnprdary[i].qnty * this.returnprdary[i].rate)))*(this.returnprdary[i].cgst/100))
        + (((this.returnprdary[i].qnty * this.returnprdary[i].rate)))));
  }
})
}
downcount(c,q){
  if(this.counter.findIndex(a => a.billid===c)==-1){
    this.counter.push({'billid':c,'cnt':0})
  }
  if(this.counter[this.counter.findIndex(a => a.billid===c)].cnt<q){
    this.addorreplace(this.counter,{'billid':c, cnt:this.counter[this.counter.findIndex(a => a.billid===c)].cnt+1});
  this.afs.collection('Bills').doc(this.eventdate).collection(String(this.routinebillid)).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        if(doc.data().code==c){
  this.addorreplace2(this.returnprdary,{'billid':this.billid,
  'prdkeyid':doc.data().KEYID,
  'code':doc.data().code,
  'productname': doc.data().productname,
  'hsncode' : doc.data().hsncode,
  'discount' : doc.data().discount,
  'qnty' : this.counter[this.counter.findIndex(a => a.billid===c)].cnt,
  'unit2' : doc.data().unit2,
  'rate': doc.data().rate,
  'cgst': doc.data().cgst,
  'sgst': doc.data().sgst,
  'igst': doc.data().igst,
});
        }
})
}
}).then(()=>{
  this.totalworth=0;
  for(let i=0;i<this.returnprdary.length;i++){
    this.totalworth=this.totalworth+Math.ceil(
      Number(((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate)
      -((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate))) 
      + (((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate)
      -((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate)))*(this.returnprdary[i].sgst/100))
       + (((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate)
       -((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate)))*(this.returnprdary[i].cgst/100))
        + (((this.counter[this.counter.findIndex(a => a.billid===c)].cnt * this.returnprdary[i].rate)))));
  }
})
  }
}

returnwithrestock(){
  this.generatevoucher()
  this.restock()
}
returnwithoutrestock(){
  this.generatevoucher()
}
  
preset(){
  let ids =this.afs.createId();
  this.currkeyid=ids;
}
generatevoucher(){
  this.afs.collection("Offerzone").doc(this.currkeyid).set({
    KEYID: this.currkeyid,
    cost: Number(this.totalworth),
    createdon: firebase.firestore.FieldValue.serverTimestamp(),
    name: 'Return Voucher',
    totalcount: 1,
    usedcount: 0,
    type: 'returnvoucher'
  })
    .catch((err) => {
      console.log(err);
    })
}
restock(){
  for(let i=0;i<this.returnprdary.length;i++){
  this.afs.collection("Products", ref => ref.where('code', '==', this.returnprdary[i].code)).doc(this.returnprdary[i].prdkeyid).update({
    qntyshop1: firebase.firestore.FieldValue.increment(this.returnprdary[i].qnty),
    status:'returned',
    returnedon: firebase.firestore.FieldValue.serverTimestamp(),
  })
  }  
}

}