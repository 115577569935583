import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatExpansionPanel} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import 'rxjs';

import { forEach,sum} from 'lodash';
import * as _ from 'lodash';


import {
  animate,
  animateChild,
  group,
  state,
  style,
  transition,
  trigger,
  query,
  AnimationTriggerMetadata,
} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../customers/format-datepicker';

import { DatePipe } from '@angular/common';

import * as moment from 'moment';

// const EXPANSION_PANEL_ANIMATION_TIMING = '3000ms cubic-bezier(0.4,0.0,0.2,1)';
// MatExpansionPanel['decorators'][0].args[0].animations = [
//   trigger('bodyExpansion', [
//     state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
//     state('expanded', style({ height: '*', visibility: 'visible' })),
//     transition('expanded <=> collapsed, void => collapsed',
//       animate(EXPANSION_PANEL_ANIMATION_TIMING)),
//   ])];
  
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class CustomersComponent  implements AfterViewInit, OnInit {

  panelOpenState = true;


  public customerForm: FormGroup;
  filetot: number;

//  displayedColumns = ['customerImage', 'customerName', 'customerType', 'customerAddress', 'customerContact', 'customerGst', 'customerDiscountType', 'details', 'edit', 'delete'];
// displayedColumns = ['customerImage', 'customerName', 'customerType', 'customerContact', 'customerDiscountType', 'details', 'edit', 'delete'];
displayedColumns = ['customerImage', 'customerName', 'customerType', 'customerAddress', 'customerContact', 'edit', 'delete'];
// displayedColumns = ['customerImage', 'customerName', 'customerType', 'customerAddress', 'customerContact', 'details', 'edit', 'delete'];

dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  custkeyid: any;
  custname: any;
  dobdate: any='';
  custaddress11: any='';
  custaddress12: any='';
  custaddress21: any='';
  custaddress22: any='';
  custcontact: any;
  custemail: any='';
  custgst: any='';
  ///////filter
  search: any;
  selection: any;

  custtype: string;
  customers: string[] = ['Individual', 'Company'];

  custtypeH: string;
  customersH: string[] = ['Mr. ', 'Mrs. ', 'Ms. ', 'M/s. '];

  disctype: string;
  discounttype: string[] = ['mrp', 'retail', 'reseller', 'wholesale'];

  enableUpdate: boolean = false;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, private route: ActivatedRoute, public router: Router, public datePipe: DatePipe) {   }

  ngAfterViewInit() {
    this.afs.collection<any>('Customers').valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;


      //It searches name and address columns. To search in entire table skip it
      this.dataSource.filterPredicate = (data, filter) =>
      (data.customerName.indexOf(filter) !== -1);
      //----------------------------------------------------------------------
      console.log((this.dataSource));
    })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toUpperCase();
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;  
  };

  trackByUid(index, item) {
    return item.uid;
  }

  
  ngOnInit() {
    this.custtype=this.customers[0]; // set default radio button checked
    this.custtypeH=this.customersH[0];
    this.disctype=this.discounttype[0];

    this.Form();
  }
  
    // Reactive customer form
    Form() {
      this.customerForm = this.fb.group({
        customername: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(30)]],
        dateofbirth: [''],
        customeraddress11: [''],
        customeraddress12: [''],
        customeraddress21: [''],
        customeraddress22: [''],
        customercontact: [null, [Validators.required,Validators.minLength(10)]],
        customeremail: [''],
        customergst: [''],
        customerimage: [''],
      })
    }

    // Accessing form control using getters
    get customername() {
      return this.customerForm.get('customername').setValidators([Validators.required, Validators.minLength(3)]);
    }

    get dateofbirth() {
      return this.customerForm.get('dateofbirth');
    }

    get customeraddress11() {
      return this.customerForm.get('customeraddress11');
    }

    get customeraddress12() {
      return this.customerForm.get('customeraddress12');
    }

    get customeraddress21() {
      return this.customerForm.get('customeraddress21');
    }

    get customeraddress22() {
      return this.customerForm.get('customeraddress22');
    }

    get customercontact() {
      return this.customerForm.get('customercontact');
    }

    get customeremail() {
      return this.customerForm.get('customeremail');
    }

    get customergst() {
      return this.customerForm.get('customergst');
    }

    get customerimage() {
      return this.customerForm.get('customerimage');
    }
    
    // Reset customer form's values
    ResetForm() {
      this.urls = [];
      this.filetot= 0;
      this.customerForm.reset();
      this.enableUpdate=false;
    }
    

    submitCustomerData() {
      this.add(this.customerForm.value.customerid);
  
      this.ResetForm();  // Reset form when clicked on reset button
  
  
    };
  
  
    // add(id) {
    //   this.afs.collection("Customers").doc("PAR"+id).set({
    //     customerId: "Customer "+id,
    //     customerName: this.custname,
    //     customerAddress: this.custaddress,
    //     customerContact: this.custcontact,
    //   })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // }

    add(id) {
      let ids =this.afs.createId();
      this.afs.collection("Customers").doc(ids).set({
        KEYID: ids,
        customerName: this.custname.toUpperCase(),
        date: this.dobdate,
        date2: this.datePipe.transform(this.dobdate,"dd-MM-yyyy"),
        customerType: this.custtype,
        customerHonor: this.custtypeH,
        customerDiscountType: (this.disctype != undefined) ? this.disctype : this.discounttype[0],
        customerAddress11: this.custaddress11,
        customerAddress12: this.custaddress12,
        customerAddress21: this.custaddress21,
        customerAddress22: this.custaddress22,
        customerContact: this.custcontact.toString(),
        customerEmail: this.custemail,
        //customerGst: this.custgst.toUpperCase(),
        customerGst: (this.custgst!=null) ? (this.custgst.toUpperCase()):'',
        customerImage: this.urls, //images
        particulartype: "customer"
      })
        .catch((err) => {
          console.log(err);
        })
    }

    read(cust) {  
      this.custkeyid = cust.KEYID;
      this.custname = cust.customerName;
      this.custtype=cust.customerType;
      this.custtypeH= cust.customerHonor,
      this.disctype= (cust.customerDiscountType!= undefined) ? cust.customerDiscountType : this.discounttype[0],
      this.dobdate = ((moment(cust.date2, "DD-MM-YYYY").toDate()));
      console.log(((moment(cust.date2, "DD-MM-YYYY").toDate())));
      this.custaddress11 = cust.customerAddress11;
      this.custaddress12 = cust.customerAddress12;
      this.custaddress21 = cust.customerAddress21;
      this.custaddress22 = cust.customerAddress22;
      this.custcontact = cust.customerContact;
      this.custemail = cust.customerEmail;
      this.custgst = cust.customerGst;
      this.urls = cust.customerImage; //images

           this.filetot = this.urls.length;

    //   this.afs.collection('Customers').doc(cust.KEYID).collection('1').get().toPromise().then(querySnapshot => {
    //     if (!querySnapshot.empty) {
    //         querySnapshot.forEach(doc => {
    //             let data = doc.data()
    //             //if (data.credit != 16) {
    //             console.log(data.credit);
    //            // }
    //         })
    //     }
    // });


  //   this.afs.collection('Customers').doc(cust.KEYID).collection('1').get().toPromise().then(querySnapshot => {
  //     if (!querySnapshot.empty) {
  //         const docSnapshots = querySnapshot.docs;
  //         for (var i in docSnapshots){
  //           const doc = docSnapshots[i].data().credit;
  //           console.log(doc);
  //         }
  //     }
  // });


  // const addcredit : string[] = [];
  //    this.afs.collection('Customers').doc(cust.KEYID).collection('/1', ref => ref.where('date', '==', '29-11-2019')).get().toPromise().then(querySnapshot => {
  //       if (!querySnapshot.empty) {
  //           querySnapshot.forEach(doc => {
  //             addcredit.push(doc.data().credit);
  //               //if (data.credit != 16) {
  //              // }
  //           })
  //           console.log(sum(addcredit));

  //       }
  //   });


 
    }


  update(pid) {

    this.afs.collection("Customers").doc(pid).update({
      customerHonor:this.custtypeH,
      customerDiscountType:(this.disctype != undefined) ? this.disctype : this.discounttype[0],
      customerName: this.custname.toUpperCase(),
      date: (this.dobdate!="Invalid Date") ? this.dobdate : null,
      date2: (this.dobdate!="Invalid Date") ? (this.datePipe.transform(this.dobdate,"dd-MM-yyyy")) : null,
      customerType: this.custtype,
        customerAddress11: this.custaddress11,
        customerAddress12: this.custaddress12,
        customerAddress21: this.custaddress21,
        customerAddress22: this.custaddress22,
        customerContact: this.custcontact.toString(),
        customerEmail: this.custemail,
        //customerGst: this.custgst.toUpperCase(),
        customerGst: (this.custgst!=null) ? (this.custgst.toUpperCase()):'',
        customerImage: this.urls, //images
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }

  
  delete(cust) {
    this.afs.collection("Customers").doc(cust.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }



  urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}







// seeprofile(cust) {  
//   console.log(cust.KEYID);
//   this.data.changeMessageCP(cust.KEYID)
//     this.router.navigate(['../customerprofile'], {relativeTo: this.route})

//       }




      getTotalCust(){
        if(this.dataSource === undefined){return 0;}
        else if(this.dataSource != undefined)
           {
            return this.dataSource.filteredData.length;
           }
      }

      getTotalComp(){
        if(this.dataSource === undefined){return 0;}
        else if(this.dataSource != undefined)
           {
            return this.dataSource.filteredData.filter(i => (i.customerType == "Company")).length;
           }
      }

      getTotalIndiv(){
        if(this.dataSource === undefined){return 0;}
        else if(this.dataSource != undefined)
           {
            return this.dataSource.filteredData.filter(i => (i.customerType == "Individual")).length;
           }
      }
      
}