
import { Component, OnInit, TrackByFunction } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as _ from'lodash';

interface ImageEnitity {
  index: string; 
  id: string; 
  code: string;
}

@Component({
  selector: 'app-barcodelayoutnewprd',
  templateUrl: './barcodelayoutnewprd.component.html',
  styleUrls: ['./barcodelayoutnewprd.component.css']
})
export class BarcodelayoutnewprdComponent implements OnInit {
  prdcollection: any;
  myArray: { index: string, id: string, code: string, qntycount: number, rate:number, bcc:string, color:string, size:string, sizechart:string, brand:string, category:string, labelcode:string, suppliercode:string  }[]= [] //declare before constructor
  brndcollection: any;
  ctrycollection: any;
  prcollection: any;
  bbcollection: any;
  labelcode: any;
  suppliercode: any;

  constructor(private afs: AngularFirestore) { }

  taskTypeAreas: {
    code: string;
  }[] = [
    {
      code: 'BLXS'
    },
    {
      code: 'RDS'
    },
    {
      code: 'BKM'
    },
    {
      code: '7WTXS'
    }
  ];
  selectedOptions: string[] = [];
  countUpdated:{'bcid': string, 'qntycount':number};

  ngOnInit() {

    this.bbcollection = this.afs.collection('Barcodebasket').snapshotChanges()
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);
    this.bbcollection.subscribe(() => {
  this.afs.collection('Barcodebasket').get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
          this.myArray.push({'index':doc.data().code,'id':doc.data().KEYID,'code':doc.data().code, 'qntycount':doc.data().qntycount, 'rate':doc.data().rate, 'bcc':doc.data().bcc, 'color':doc.data().color, 'size':doc.data().size, 'sizechart':doc.data().sizechart.substring(0,2), 'brand':doc.data().brand, 'category':doc.data().category, 'labelcode':doc.data().labelcode, 'suppliercode':doc.data().suppliercode});
          console.log("T: "+JSON.stringify(this.myArray));      
          this.countUpdated = {'bcid': doc.data().code, 'qntycount':0};

          for(let i=1; i<doc.data().qntycount;i++){
           // this.myArray.find(item => item.code == this.countUpdated.bcid).qntycount = this.myArray.find(item => item.code == this.countUpdated.bcid).qntycount+1;
            
const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(doc.data().code);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,0,this.myArray.find(item => item.code === doc.data().code));
}
console.log("IIIIII: "+JSON.stringify(this.myArray));
          }
    })
  }
  }).then(()=>{
    
  })

})
  }



  clearbarcodebasket(){
    var deletearray=[];
    this.afs.collection('Barcodebasket').get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          deletearray.push(doc.data().code);
        })
      }
    }).then(()=>{
      for(let i=0; i<deletearray.length; i++){
        this.afs.collection('Barcodebasket').doc(String(deletearray[i])).delete();
      }
    });
 }




  /**
   * @default 3
   */
  public columns = 3;

  public get table(): ImageEnitity[][] {
    const rowCount = Math.floor(this.myArray.length / this.columns);
    const remainder = this.myArray.length % this.columns;
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(this.myArray.slice(i * this.columns, (i * this.columns) + this.columns))
    }
    if (remainder) {
      rows.push(this.myArray.slice(this.myArray.length - remainder, this.myArray.length));
    }
    return rows;
  };

  /**
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  public trackRow: TrackByFunction<ImageEnitity[]> = (index, item) => {
    return index;
  };

  /**
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  public trackImage: TrackByFunction<ImageEnitity> = (index, item) => {
    //return item.name;
    return item.id;
  };

insert(c,i){

  this.myArray.find(item => item.code == c).qntycount = this.myArray.find(item => item.code == c).qntycount+1;
 


const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(c);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,0,this.myArray.find(item => item.code === c));
}
console.log("IIIIII: "+JSON.stringify(this.myArray));
}

remove(c,i){

  this.myArray.find(item => item.code == c).qntycount = this.myArray.find(item => item.code == c).qntycount-1;


const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(c);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,1);
}
console.log("RRRRRR: "+JSON.stringify(this.myArray));
}

}