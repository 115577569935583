import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnimationDurations, MatSnackBar } from '@angular/material';
import { firestore } from 'firebase';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-brandcategoriesmfc',
  templateUrl: './brandcategoriesmfc.component.html',
  styleUrls: ['./brandcategoriesmfc.component.css']
})
export class BrandcategoriesmfcComponent implements OnInit {

  public BForm: FormGroup;
  public CForm: FormGroup;
  public sC1Form: FormGroup;
  public sC2Form: FormGroup;

  brandnew: any;
  categorynew: any;
  subcategory1new: any;
  subcategory2new: any;
  brcollection: any;
  ctcollection: any;
  sct1collection: any;
  sct2collection: any;
  selectedbrand: any;
  selectedcategory: any;
  selectedsubcategory1: any;
  selectedsubcategory2: any;
  selectedbrand2: any;
  selectedcategory2: any;
  selectedsubcategory21: any;
  selectedsubcategory22: any;
  brcollection2: any;
  ctcollection2: any;
  sct1collection2: any;
  sct2collection2: any;

  sizy:string;
  sizes = [{label:'default',checked:true}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];
  
  sizenameDEFAULT: string='default';
  sizenameMM: string='0';
  sizenameCM: string='0';
  sizenameINCHES: string='0';
  sizenameFEET: string='0';
  sizenameNUMBERS: string='0';
  sizenameLETTERS: string='0';

  updateCheckedOptions(index, size) {

    size.checked = !size.checked;
    //----newly added--------
    size.checked=true;
    //----newly added--------
    console.log(index,size.checked);

    if(size.checked==true)
    {
    if(index==0){this.sizenameDEFAULT='default';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:true}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==1){this.sizenameDEFAULT='0';this.sizenameMM='mm';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:true}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==2){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='cm';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:true}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==3){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='inches';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:true}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==4){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='feet';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:true}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==5){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='numbers';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:true}, {label:'letters',checked:false}];}
    if(index==6){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='letters';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:true}];}

    console.log(this.sizes);
    }
    if(size.checked==false){
      console.log(index,size);

    }
   
}

  constructor(private afs: AngularFirestore, public snackBar: MatSnackBar, public fb: FormBuilder) {

   }
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
    }

  ngOnInit() {

    this.FormB();
    this.FormC();
    this.FormsC1();
    this.FormsC2();

    this.brcollection =[];
    this.brcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.ctcollection =[];
    this.ctcollection = this.afs.collection('Categories',ref => ref.orderBy('allcategories','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.sct1collection =[];
    this.sct1collection = this.afs.collection('subCategories1',ref => ref.orderBy('allsubcategories1','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.sct2collection =[];
    this.sct2collection = this.afs.collection('subCategories2',ref => ref.orderBy('allsubcategories2','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    this.brcollection2 =[];
    this.brcollection2 = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  }

   // Reactive particular form
   FormB() {
    this.BForm = this.fb.group({
      newbrand: [''],
    })
  }
  // Accessing form control using getters
  get newbrand() {
    return this.BForm.get('newbrand');
  }

// Reactive particular form
FormC() {
  this.CForm = this.fb.group({
    newcategory: [''],
  })
}
FormsC1() {
  this.sC1Form = this.fb.group({
    newsubcategory1: [''],
  })
}
FormsC2() {
  this.sC2Form = this.fb.group({
    newsubcategory2: [''],
  })
}
// Accessing form control using getters
get newcategory() {
  return this.CForm.get('newcategory');
}
get newsubcategory1() {
  return this.sC1Form.get('newsubcategory1');
}
get newsubcategory2() {
  return this.sC2Form.get('newsubcategory2');
}
bsubmitbtn(){
  this.afs.collection('Brands').doc(String(this.brandnew).toUpperCase()).set(
    {
      brandname : String(this.brandnew).toUpperCase()
    },
     {merge: true}
)
}

bdeletebtn(brndname){
  this.afs.collection('Brands').doc(brndname).delete().then(function() {
    console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
}

csubmitbtn(){
  this.afs.collection('Categories').doc('allcategories').set(
    {
      allcategories:firestore.FieldValue.arrayUnion(String(this.categorynew).toUpperCase()),
      // committed:'', 
    },
     {merge: true}
)
}
sc1submitbtn(){
  this.afs.collection('subCategories1').doc('allsubcategories1').set(
    {
      allsubcategories1:firestore.FieldValue.arrayUnion(String(this.subcategory1new).toUpperCase()),
      // committed:'', 
    },
     {merge: true}
)
}
sc2submitbtn(){
  this.afs.collection('subCategories2').doc('allsubcategories2').set(
    {
      allsubcategories2:firestore.FieldValue.arrayUnion(String(this.subcategory2new).toUpperCase()),
      // committed:'', 
    },
     {merge: true}
)
}
cdeletebtn(ctname){
  this.afs.collection('Categories').doc('allcategories').update({
    allcategories:firestore.FieldValue.arrayRemove(ctname)
  })
}
sc1deletebtn(sct1name){
  this.afs.collection('subCategories1').doc('allsubcategories1').update({
    allsubcategories1:firestore.FieldValue.arrayRemove(sct1name)
  })
}
sc2deletebtn(sct2name){
  this.afs.collection('subCategories2').doc('allsubcategories2').update({
    allsubcategories2:firestore.FieldValue.arrayRemove(sct2name)
  })
}
changeBrand(brandselected){
this.selectedbrand=brandselected;
}
changeCategory(categoryselected){
this.selectedcategory=categoryselected;
}
changesubCategory1(subcategory1selected){
  this.selectedsubcategory1=subcategory1selected;
  }
  changesubCategory2(subcategory2selected){
    this.selectedsubcategory2=subcategory2selected;
    }

bccommitbtn(br,ct, sct1, sct2, szy){
  if(szy != undefined){
 
  this.afs.collection('BrandCategoryCounter').get().toPromise().then( querySnapshot => {
    querySnapshot.forEach( doc => {

      let a=doc.data().count;
      console.log(a)

this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
if(!doc.data().categories){
  this.afs.collection('Brands').doc(br).update({
    categories:firestore.FieldValue.arrayUnion({
    sizechart:String(szy).toUpperCase(),
    categoryname:String(ct).toUpperCase(),
    subcategory1name:String(sct1).toUpperCase(),
    subcategory2name:String(sct2).toUpperCase(),
    id:a})
  }).then(()=>{

  this.afs.collection('Categories').doc('allcategories').update({
    committed:firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
  }).then(()=>{

    this.afs.collection('subCategories1').doc('allsubcategories1').update({
      committed:firestore.FieldValue.arrayUnion(String(sct1).toUpperCase()) 
    }).then(()=>{

      this.afs.collection('subCategories2').doc('allsubcategories2').update({
        committed:firestore.FieldValue.arrayUnion(String(sct2).toUpperCase()) 
      }).then(()=>{
  
    this.afs.collection('BrandCategoryCounter').doc('Counter').update({
      count: firestore.FieldValue.increment(1)
    })
   })
  })
})
})
}
var arr=[];    
if(doc.data().categories){
  for(let i=0;i<doc.data().categories.length;i++){
    if(doc.data().categories[i].categoryname == ct && doc.data().categories[i].subcategory1name == sct1  && doc.data().categories[i].subcategory2name == sct2 ){
      arr.push(ct)
      console.log("ALREADY COMMITTED")
      this.openSnackBar("ALREADY COMMITTED","OK");

    }
    if(doc.data().categories[i].categoryname != ct){
    }
}

if(arr.length==0){
this.afs.collection('Brands').doc(br).update({
  categories:firestore.FieldValue.arrayUnion({
    sizechart:String(szy).toUpperCase(),
    categoryname:String(ct).toUpperCase(),
    subcategory1name:String(sct1).toUpperCase(),
    subcategory2name:String(sct2).toUpperCase(),
    id:a}) 
}).then(()=>{

this.afs.collection('Categories').doc('allcategories').update({
  committed:firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
}).then(()=>{

  this.afs.collection('subCategories1').doc('allsubcategories1').update({
    committed:firestore.FieldValue.arrayUnion(String(sct1).toUpperCase()) 
  })
  .then(()=>{

    this.afs.collection('subCategories2').doc('allsubcategories2').update({
      committed:firestore.FieldValue.arrayUnion(String(sct2).toUpperCase()) 
    })
    .then(()=>{

  this.afs.collection('BrandCategoryCounter').doc('Counter').update({
    count: firestore.FieldValue.increment(1)
  })
 })
})
})
})
console.log("NEWLY COMMITTED")
this.openSnackBar("NEWLY COMMITTED","OK");
    }
  }

})

})

  })
}
else{
  this.openSnackBar('Please Select Size Chart', 'OK')
}
}


changeBrand2(brandselected){
  this.selectedbrand2=brandselected;
    
  this.ctcollection2 =[];
  this.ctcollection2 = this.afs.collection('Brands',ref => ref.where('brandname', "==" , brandselected)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  }

  changeCategory2(brandselected,categoryselected){
  this.selectedcategory2=categoryselected;

  }
  changesubCategory21(brandselected,subcategory1selected){
    this.selectedsubcategory21=subcategory1selected;
  
    }
    changesubCategory22(brandselected,subcategory2selected){
      this.selectedsubcategory22=subcategory2selected;
    
      }

  
}