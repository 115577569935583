import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, Observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';

@Component({
  selector: 'app-productmigrationmanage',
  templateUrl: './productmigrationmanage.component.html',
  styleUrls: ['./productmigrationmanage.component.css']
})
export class ProductmigrationmanageComponent implements OnInit {

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  stckqntycollection: any;

  ngOnInit() {

   this.afs.collection('Productbasket').get().toPromise().then(querySnapshot => {
     
    querySnapshot.forEach(doc => {
      if((doc.data().shop1toshop2qnty == undefined ||  doc.data().shop1toshop2qnty == 0) &&
      (doc.data().shop1togodownqnty == undefined ||  doc.data().shop1togodownqnty == 0) &&
      (doc.data().shop2toshop1qnty == undefined ||  doc.data().shop2toshop1qnty == 0) &&
      (doc.data().shop2togodownqnty == undefined ||  doc.data().shop2togodownqnty == 0) &&
      (doc.data().godowntoshop1qnty == undefined ||  doc.data().godowntoshop1qnty == 0) &&
      (doc.data().godowntoshop2qnty == undefined ||  doc.data().godowntoshop2qnty == 0)       
      ) {
      doc.ref.delete();
      console.log("deleted");
      }
    })
   }).then(()=>{
    

    this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productbasket',ref => 
    ref.orderBy('code')   
    )
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  })

  }


  
  revertbackshop1toshop2qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown),
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1toshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

  completeshop1toshop2qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1toshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

  revertbackshop1togodownqnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown),
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

  completeshop1togodownqnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown) + Number(shftqnty),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop1togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

  revertbackshop2toshop1qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop2toshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

  completeshop2toshop1qnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown),
        qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
        qntyshop2 : Number(doc.data().qntyshop2),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop2toshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

  revertbackshop2togodownqnty(cd,shftqnty){
    this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntygodown : Number(doc.data().qntygodown),
        qntyshop1 : Number(doc.data().qntyshop1),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productbasket').doc(cd).set({
          code: cd,
          shop2togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
        },
        {merge: true}
        )
      .catch((err) => {
        console.log(err);
      })
    })
  }

completeshop2togodownqnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : Number(doc.data().qntygodown) + Number(shftqnty),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        shop2togodownqnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  })
}

revertbackgodowntoshop1qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : Number(doc.data().qntygodown) + Number(shftqnty),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  })
}

completegodowntoshop1qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : Number(doc.data().qntygodown),
      qntyshop1 : Number(doc.data().qntyshop1) + Number(shftqnty),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop1qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  })
}

revertbackgodowntoshop2qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : Number(doc.data().qntygodown) + Number(shftqnty),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })
  })
}

completegodowntoshop2qnty(cd,shftqnty){
  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.set({
      qntygodown : Number(doc.data().qntygodown),
      qntyshop1 : Number(doc.data().qntyshop1),
      qntyshop2 : Number(doc.data().qntyshop2) + Number(shftqnty),    
    },
     {merge: true}
  )
  .catch((err) => {
    console.log(err);
  })
  })
  }).then(()=>{

    this.afs.collection('Productbasket').doc(cd).set({
        code: cd,
        godowntoshop2qnty : firebase.firestore.FieldValue.increment(Number(-shftqnty)),
      },
      {merge: true}
      )
    .catch((err) => {
      console.log(err);
    })  
  })
}

}

