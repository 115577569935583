

import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatExpansionPanel} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import 'rxjs';

import { forEach,sum} from 'lodash';


import {
  animate,
  animateChild,
  group,
  state,
  style,
  transition,
  trigger,
  query,
  AnimationTriggerMetadata,
} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../suppliers/format-datepicker';

import { DatePipe } from '@angular/common';

import * as moment from 'moment';

const EXPANSION_PANEL_ANIMATION_TIMING = '3000ms cubic-bezier(0.4,0.0,0.2,1)';
MatExpansionPanel['decorators'][0].args[0].animations = [
  trigger('bodyExpansion', [
    state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed, void => collapsed',
      animate(EXPANSION_PANEL_ANIMATION_TIMING)),
  ])];
  
@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})

export class SuppliersComponent  implements AfterViewInit, OnInit {

  panelOpenState = false;


  public supplierForm: FormGroup;
  filetot: number;

  //  displayedColumns = ['supplierImage', 'supplierCode', 'supplierName', 'supplierType', 'supplierAddress', 'supplierContact', 'supplierGst', 'details', 'edit', 'delete'];
 displayedColumns = ['supplierImage', 'supplierCode', 'supplierName', 'supplierType', 'supplierAddress', 'supplierContact', 'supplierGst', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  suppkeyid: any;
  suppname: any;
  suppcode: any;
  suppaddress11: any='';
  suppaddress12: any='';
  suppaddress21: any='';
  suppaddress22: any='';
  suppcontact: any;
  suppemail: any='';
  suppgst: any='';
  ///////filter
  search: any;
  selection: any;

  supptype: string;
  suppliers: string[] = ['Individual', 'Company'];

  supptypeH: string;
  suppliersH: string[] = ['Mr. ', 'Mrs. ', 'Ms. ', 'M/s. '];

  enableUpdate: boolean = false;
  
  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, private route: ActivatedRoute, public router: Router, public datePipe: DatePipe) {   }

  ngAfterViewInit() {
    this.afs.collection<any>('Suppliers').valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;


      //It searches name and address columns. To search in entire table skip it
      this.dataSource.filterPredicate = (data, filter) =>
      (data.supplierName.indexOf(filter) !== -1);
      //----------------------------------------------------------------------
      console.log((this.dataSource));
    })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toUpperCase();
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;  
  };

  trackByUid(index, item) {
    return item.uid;
  }

  
  ngOnInit() {
    this.supptype=this.suppliers[0]; // set default radio button checked
    this.supptypeH=this.suppliersH[0];

    this.Form();
  }
  
    // Reactive supplier form
    Form() {
      this.supplierForm = this.fb.group({
        suppliername: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(30)]],
        suppliercode: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(10)]],
        supplieraddress11: [''],
        supplieraddress12: [''],
        supplieraddress21: [''],
        supplieraddress22: [''],
        suppliercontact: [''],
        supplieremail: [''],
        suppliergst: [''],
        supplierimage: [''],
      })
    }

    // Accessing form control using getters
    get suppliername() {
      return this.supplierForm.get('suppliername').setValidators([Validators.required, Validators.minLength(3)]);
    }
    get suppliercode() {
      return this.supplierForm.get('suppliercode').setValidators([Validators.required, Validators.minLength(3)]);
    }

    get supplieraddress11() {
      return this.supplierForm.get('supplieraddress11');
    }

    get supplieraddress12() {
      return this.supplierForm.get('supplieraddress12');
    }

    get supplieraddress21() {
      return this.supplierForm.get('supplieraddress21');
    }

    get supplieraddress22() {
      return this.supplierForm.get('supplieraddress22');
    }

    get suppliercontact() {
      return this.supplierForm.get('suppliercontact');
    }

    get supplieremail() {
      return this.supplierForm.get('supplieremail');
    }

    get suppliergst() {
      return this.supplierForm.get('suppliergst');
    }

    get supplierimage() {
      return this.supplierForm.get('supplierimage');
    }
    
    // Reset supplier form's values
    ResetForm() {
      this.urls = [];
      this.filetot= 0;
      this.supplierForm.reset();
      this.enableUpdate=false;
    }
    

    submitSupplierData() {
      this.add(this.supplierForm.value.supplierid);
  
      this.ResetForm();  // Reset form when clicked on reset button
  
  
    };
  
  
    // add(id) {
    //   this.afs.collection("Suppliers").doc("PAR"+id).set({
    //     supplierId: "Supplier "+id,
    //     supplierName: this.suppname,
    //     supplierAddress: this.suppaddress,
    //     supplierContact: this.suppcontact,
    //   })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // }

    add(id) {
      let ids =this.afs.createId();
      this.afs.collection("Suppliers").doc(ids).set({
        KEYID: ids,
        supplierName: this.suppname.toUpperCase(),
        supplierCode: this.suppcode.toUpperCase(),
        supplierType: this.supptype,
        supplierHonor: this.supptypeH,
        supplierAddress11: this.suppaddress11,
        supplierAddress12: this.suppaddress12,
        supplierAddress21: this.suppaddress21,
        supplierAddress22: this.suppaddress22,
        supplierContact: (this.suppcontact!=null) ? (String(this.suppcontact)): '',
        supplierEmail: this.suppemail,
        //supplierGst: this.suppgst.toUpperCase(),
        supplierGst: (this.suppgst!=null) ? (this.suppgst.toUpperCase()):'',
        supplierImage: this.urls, //images
        particulartype: "supplier"
      })
        .catch((err) => {
          console.log(err);
        })
    }

    read(supp) {  
      this.suppkeyid = supp.KEYID;
      this.suppname = supp.supplierName;
      this.suppcode = supp.supplierCode;
      this.supptype=supp.supplierType;
      this.supptypeH= supp.supplierHonor,
      this.suppaddress11 = supp.supplierAddress11;
      this.suppaddress12 = supp.supplierAddress12;
      this.suppaddress21 = supp.supplierAddress21;
      this.suppaddress22 = supp.supplierAddress22;
      this.suppcontact = supp.supplierContact;
      this.suppemail = supp.supplierEmail;
      this.suppgst = supp.supplierGst;
      this.urls = supp.supplierImage; //images

           this.filetot = this.urls.length;

    //   this.afs.collection('Suppliers').doc(supp.KEYID).collection('1').get().toPromise().then(querySnapshot => {
    //     if (!querySnapshot.empty) {
    //         querySnapshot.forEach(doc => {
    //             let data = doc.data()
    //             //if (data.credit != 16) {
    //             console.log(data.credit);
    //            // }
    //         })
    //     }
    // });


  //   this.afs.collection('Suppliers').doc(supp.KEYID).collection('1').get().toPromise().then(querySnapshot => {
  //     if (!querySnapshot.empty) {
  //         const docSnapshots = querySnapshot.docs;
  //         for (var i in docSnapshots){
  //           const doc = docSnapshots[i].data().credit;
  //           console.log(doc);
  //         }
  //     }
  // });


  // const addcredit : string[] = [];
  //    this.afs.collection('Suppliers').doc(supp.KEYID).collection('/1', ref => ref.where('date', '==', '29-11-2019')).get().toPromise().then(querySnapshot => {
  //       if (!querySnapshot.empty) {
  //           querySnapshot.forEach(doc => {
  //             addcredit.push(doc.data().credit);
  //               //if (data.credit != 16) {
  //              // }
  //           })
  //           console.log(sum(addcredit));

  //       }
  //   });


 
    }


  update(pid) {

    this.afs.collection("Suppliers").doc(pid).update({
      supplierHonor:this.supptypeH,
      supplierCode: this.suppcode.toUpperCase(),
      supplierType: this.supptype,
        supplierAddress11: this.suppaddress11,
        supplierAddress12: this.suppaddress12,
        supplierAddress21: this.suppaddress21,
        supplierAddress22: this.suppaddress22,
        supplierContact: (this.suppcontact!=null) ? (String(this.suppcontact)): '',
        supplierEmail: this.suppemail,
        //supplierGst: this.suppgst.toUpperCase(),
        supplierGst: (this.suppgst!=null) ? (this.suppgst.toUpperCase()):'',
        supplierImage: this.urls, //images
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }

  
  delete(supp) {
    this.afs.collection("Suppliers").doc(supp.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }



  urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}


getTotalSup(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined)
     {
      return this.dataSource.filteredData.length;
     }
}

}