import { Component, ViewEncapsulation, OnInit, Inject, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CustomerprofileService } from "../../assets/services/customerprofile.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { MatTableDataSource, MatSort, MatPaginator, MatSortable} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../customerprofile/format-datepicker';
import { DatePipe } from '@angular/common';

export interface Item { name: string; rate: number;}

import 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';

declare function snipMe(): any;

export interface DialogData {
  routinebillid: any;
  date: any;
  customerName: any;
  customerId: any;
  ps:string;
}

export interface DialogDataERONE {
  routinebillid: any;
  date: any;
  customerName: any;
  customerId: any;
  ps:string;
}

export interface DialogDataERTWO {
  routinebillid: any;
  date: any;
  customerName: any;
  customerId: any;
  ps:string;
}

export interface DialogDataERVIEW {
  estid: any;
}
export interface DialogDataERVIEWTP {
  estid: any;
}

@Component({
  selector: 'app-customerprofile',
  templateUrl: './customerprofile.component.html',
  styleUrls: ['./customerprofile.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class CustomerprofileComponent implements OnInit,AfterViewInit {
  messageCP: string;
  mycustary: {
    customerHonor:any,customerName:any,customerType:any,customerAddress11:any,customerAddress12:any,customerAddress21:any,customerAddress22:any,customerEmail:any,customerContact:any,customerGst:any,customerImage:any,
    customerDiscountType: any;
  }[]= [];
    myArray: { date: any, routinebillid: any, billrefno: any; customerName: any, customerId: any }[]= [] //declare before constructor
  myArrayESTO: { date: any, estid: any, customerId: any }[]= [] //declare before constructor
  addorreplace2(array, item){
    const i=array.findIndex(_item => _item.estid===item.estid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   //ledgerdetails newly added
  displayedColumns = ['date2', 'name', 'book', 'debit', 'credit', 'tag', 'description'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ledkeyid: any;
  leddate: any;
  ledcust: any;
  leddebit: any;
  ledcredit: any;
  leddescription: any;
  bktag:any;
  ///////filter
  namesearch: any;
  selection: any;
  datesearch: any;
  filterValue = {
    name: '',
    date2s: '',
    date2e: '',
  };
  tagscollection: any;
  name: any;
//ledgerdetails newly added

  constructor(private data: CustomerprofileService, private afs: AngularFirestore, public dialog: MatDialog, public datePipe: DatePipe) { }

//ledgerdetails newly added



ngOnInit() {

    this.afs.collection('Ledger', ref => ref.where("cust.customerId", "==" , this.messageCP)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  ).subscribe(data => { //filtered by book
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.filterPredicate = this.createFilter();
      console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
      this.dataSource.sort = this.sort;

      //sorting for date column
      this.dataSource.sortingDataAccessor = (item, property) => {
      //console.log(item)
        switch (property) {
          case 'date2': {
            return (moment(item.date2, "DD-MM-YYYY").toDate());
          }
          default: return item[property];
        }
      };

      this.dataSource.paginator=this.paginator;



    })    
    this.sort.sort(<MatSortable>{
      id: 'date2',
      start: 'desc',
    }
  );

}

getTotalDebit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tdebit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseFloat(a.debit);
});
return tdebit;
}
}


getTotalCredit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tcredit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseFloat(a.credit);
});
return tcredit;
}
}


getTotal(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){
let tcreditdebit = _.sumBy(this.dataSource.filteredData, function (a) {  
  if(a.debit>a.credit || a.credit>a.debit){
    return parseFloat(a.debit)-parseFloat(a.credit);
    }
      else{
        return 0.00;
      }
});
return Math.abs(tcreditdebit);
}
}


  applyFilter2(filterValue: string) {
    this.filterValue.date2s = this.datePipe.transform(new Date(this.datesearch.start),"dd-MM-yyyy"); 
    this.filterValue.date2e = this.datePipe.transform(new Date(this.datesearch.end),"dd-MM-yyyy"); 
    this.dataSource.filter = JSON.stringify(this.filterValue);   
    console.log("S: "+this.filterValue.date2s);
    console.log("E: "+this.filterValue.date2e);
    this.getTotalDebit();
    this.getTotalCredit();
    this.getTotal();
  };



  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log("CURRDate:" +moment(data.date2, "DD-MM-YYYY").toDate());
      console.log("SDMoment:" +moment(searchTerms.date2s, "DD-MM-YYYY").toDate());
      console.log("EDMoment:" +moment(searchTerms.date2e, "DD-MM-YYYY").toDate());
      if((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
      && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
      {
        console.log(true);
      }
      else{
        console.log(false);
      }
      return (data.name.indexOf(searchTerms.name) !== -1)
        && ((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
        && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
    }
    return filterFunction;
  }

  trackByUid(index, item) {
    return item.uid;
  }
  //ledgerdetails newly added
  ngAfterViewInit() {
    this.data.currentMessageCP.subscribe(messageCP => this.messageCP = messageCP)



    this.afs.collection('Customers', ref => ref.where('KEYID', '==', this.messageCP)).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
          this.mycustary.push(
            {
            'customerHonor':doc.data().customerHonor,
            'customerName':doc.data().customerName,
            'customerType':doc.data().customerType,
            'customerAddress11':doc.data().customerAddress11,
            'customerAddress12':doc.data().customerAddress12,
            'customerAddress21':doc.data().customerAddress21,
            'customerAddress22':doc.data().customerAddress22,
            'customerEmail':doc.data().customerEmail,
            'customerContact':doc.data().customerContact,
            'customerGst':doc.data().customerGst,
            'customerImage':doc.data().customerImage,
            'customerDiscountType':doc.data().customerDiscountType,
          });
          console.log("T: "+JSON.stringify(this.mycustary));      
  
      });
  });


  
   this.afs.collectionGroup('--stats--', ref => ref.where('customerId', '==', this.messageCP)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
        console.log(doc.ref.parent.parent.id, ' => ', doc.id, ' => ', doc.data().customerName);
        this.myArray.push({'date':doc.ref.parent.parent.id,'routinebillid':doc.id,'billrefno':doc.data().billrefno,'customerName':doc.data().customerName,'customerId':doc.data().customerId});
        console.log("T: "+JSON.stringify(this.myArray));      

    });
    //newly added
    this.myArray=this.myArray.sort((b,a) => a.billrefno.localeCompare(b.billrefno, undefined, {numeric: true}));
    //newly added

});


this.afs.collection('Estimation', ref => ref.where('custid', '==', this.messageCP)).snapshotChanges()      
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data())))
  .subscribe((posts) => {
    //this.myArrayESTO=[];
this.afs.collection('Estimation', ref => ref.where('custid', '==', this.messageCP)).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(doc => {
      this.addorreplace2(this.myArrayESTO,{'date':doc.data().date,'estid':doc.data().estid, customerId: doc.data().custid});
      console.log("T: "+JSON.stringify(this.myArrayESTO));      

  });
});
  })





  //ledgerdetails newly added
  // this.afs.collection('Ledger', ref => ref.where("cust.customerId", "==" , this.messageCP)).snapshotChanges()
  // .pipe(
  //   map(actions => actions.map(a => a.payload.doc.data()))
  // ).subscribe(data => { //filtered by book
  //     this.dataSource = new MatTableDataSource(data); 
  //     this.dataSource.filterPredicate = this.createFilter();
  //     console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
  //     this.dataSource.sort = this.sort;

  //     //sorting for date column
  //     this.dataSource.sortingDataAccessor = (item, property) => {
  //     //console.log(item)
  //       switch (property) {
  //         case 'date2': {
  //           return (moment(item.date2, "DD-MM-YYYY").toDate());
  //         }
  //         default: return item[property];
  //       }
  //     };

  //     this.dataSource.paginator=this.paginator;



  //   })    
  //   this.sort.sort(<MatSortable>{
  //     id: 'date2',
  //     start: 'desc',
  //   }
  // );
 //ledgerdetails newly added

  }
  
  openDialogERONE(rid,dt,custname,custid,pagesize) {
    this.dialog.open(DialogDataExampleDialogERONE, {
      data: {
        routinebillid: rid,
        date: dt,
        customerName: custname,
        customerId: custid,
        ps:pagesize
      }
    });
  }

  openDialogERTWO(rid,dt,custname,custid,pagesize) {
    this.dialog.open(DialogDataExampleDialogERTWO, {
      data: {
        routinebillid: rid,
        date: dt,
        customerName: custname,
        customerId: custid,
        ps:pagesize
      }
    });
  }

  openDialogERVIEW(eid) {
    let dialogRef=this.dialog.open(DialogDataExampleDialogERVIEW, {
      data: {
        estid: eid,
      }
    });
    const sub=dialogRef.componentInstance.onAdd.subscribe(()=>{
//do something
this.afs.collection('Estimation', ref => ref.where('custid', '==', this.messageCP)).snapshotChanges()      
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data())))
  .subscribe((posts) => {
    this.myArrayESTO=[];
this.afs.collection('Estimation', ref => ref.where('custid', '==', this.messageCP)).get().toPromise().then(querySnapshot => {
  querySnapshot.forEach(doc => {
      this.addorreplace2(this.myArrayESTO,{'date':doc.data().date,'estid':doc.data().estid, customerId: doc.data().custid});
      console.log("T: "+JSON.stringify(this.myArrayESTO));      

  });
});
  })
    })
dialogRef.afterClosed().subscribe(()=>{
  //unsubscribe onAdd
console.log("called");

})
  }


openDialogERVIEWTP(eid) {
  let dialogRef=this.dialog.open(DialogDataExampleDialogERVIEWTP, {
    data: {
      estid: eid,
    }
  });
  const sub=dialogRef.componentInstance.onAdd.subscribe(()=>{
//do something
this.afs.collection('Estimation', ref => ref.where('custid', '==', this.messageCP)).snapshotChanges()      
.pipe( 
map(actions => actions.map(a => a.payload.doc.data())))
.subscribe((posts) => {
  this.myArrayESTO=[];
this.afs.collection('Estimation', ref => ref.where('custid', '==', this.messageCP)).get().toPromise().then(querySnapshot => {
querySnapshot.forEach(doc => {
    this.addorreplace2(this.myArrayESTO,{'date':doc.data().date,'estid':doc.data().estid, customerId: doc.data().custid});
    console.log("T: "+JSON.stringify(this.myArrayESTO));      

});
});
})
  })
dialogRef.afterClosed().subscribe(()=>{
//unsubscribe onAdd
console.log("called");

})
}


openDialog(rid,dt,custname,custid) {
  this.dialog.open(DialogDataExampleDialog, {
    data: {
      routinebillid: rid,
      date: dt,
      customerName: custname,
      customerId : custid
    }
  });
}

}


@Component({
  selector: 'dialog-data-example-dialog-erateone',
  templateUrl: 'dialog-data-example-dialog-erateone.html',
  styleUrls: ['customerprofile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDataExampleDialogERONE implements AfterViewInit {

  message:boolean;
  selectedDate: string;
  prcollection: any;
  prdcollection: any;

favoriteCustomer: string;
customers: string[] = ['wholesale', 'retail', 'reseller', 'mrp'];

selectedOptions: string[] = [];


myary: { billid: any }[]= [] //declare before constructor

arybilldetails:{
  billid:any;
  code:string;
  productname: string;
  hsncode: string;
  discount: number;
  qnty : number;
  unit2 : string;
  rate: number;
  estrate: number;
  cgst: number;
  sgst: number;
  igst:number;
}[]=[];

aryaddressdetails:{
  billid:any;
  billono:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];

  st: string;
  step: string;
  eventdate: string;

  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalestrate: number;
  summy: { billid:any; gst: number, estrate: number, taxableamt: number, amtaftdisc:number }[]= [];
  summypre: { billid:any; gst: number, estrate: number, taxableamt: number, amtaftdisc:number }[]= [];

  pcollection: any;

  pressedtogglebtn: any;

  billbarcode: string; 
  updatecollection: any;
  bcollection: any;
  estrateary: { prdcode:string, rate:number, prdname:string, hsncode:string, estrate: any, qnty:number, unit2:string}[]= [];
  packagingcharge: number=0.00;
constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataERONE, private afs: AngularFirestore,  private changeDetection: ChangeDetectorRef) {   }


addorreplace(array, item){
 const i=array.findIndex(_item => _item.billid===item.billid);
 if(i>-1) array[i] =item;
 else array.push(item);
}

addorreplace2(array, item){
  const i=array.findIndex(_item => _item.billid===item.billid && _item.code===item.code);
  if(i>-1) array[i] =item;
  else array.push(item);
 }

 @ViewChildren('allTheseThings') things: QueryList<any>;

ngForRendred() {

  //console.log('NgFor is Rendered');
  //brilliant work dont remove it change time
  setTimeout( ()=>{
    snipMe();
    }, 2000)
    //brilliant work dont remove it change time
}


ngAfterViewInit() {

   this.st="step2";
   this.step="step2";
  //this.step= this.data.ps;
this.favoriteCustomer=this.customers[3]; // set default radio button checked

console.log("PL"+this.message);


  // this.myary=[];
  // this.prcollection =[];

  
  

   this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
   .get().toPromise()
   .then(querySnapshot => {
       querySnapshot.forEach(doc => {
         this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(this.data.routinebillid)
         .get().toPromise()
         .then(docy => {
           console.log("cc"+this.data.routinebillid);
               if(docy.data().customerId == this.data.customerId){
                 this.addorreplace(this.myary,{'billid':doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]});
 
             this.selectedOptions.push(String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]))
             this.updateCheckedOptions({"billid":String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)])},0);
             //this.changeDetection.detectChanges();
 
               }            
           })
       });
       //this.changeDetection.detectChanges();
 
   })
   .catch(function(error) {
       console.log("Error getting documents: ", error);
   }).then(()=>{
 
     //this.changeDetection.detectChanges();
 })



  //this.selectall();


  this.things.changes.subscribe(t => {
    this.ngForRendred();
  })
}



  updateCheckedOptions(tta,i){
    
    //tta.checked = !tta.checked;

    this.tottaxableamtpre=0;
    this.totamtwithtaxpre=0; 
    this.sumaftrdiscpre=0; 

    this.totalamountpre=0;


  this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(tta.billid.substring(4)).get().toPromise().then(doc => {

         this.addorreplace(this.aryaddressdetails,{'billid':tta.billid,
         billono:doc.data().billrefno,
            customerName: doc.data().customerName,
            customerAddress11: doc.data().customerAddress11,
            customerAddress12: doc.data().customerAddress12,
            customerAddress21: doc.data().customerAddress21,
            customerAddress22: doc.data().customerAddress22,
            customerContact: doc.data().customerContact,
            customerGst: doc.data().customerGst,
            customerHonor: doc.data().customerHonor,
          });    

  });
 


    this.afs.collection('Bills').doc(this.data.date).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
      this.afs.collection('Bills').doc(this.data.date).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {

          this.afs.collection('Products',ref => ref.where('code', '==', doc.data().code)).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docz => {
              //console.log("ESTARY"+docz.data().estrate);
              this.estrateary.push({'prdcode':doc.data().code, 'rate':doc.data().rate, 'prdname':docz.data().brand +' '+ docz.data().category, 'hsncode' : docz.data().hsncode, 'estrate':docz.data().estrate, 'qnty':doc.data().qnty, 'unit2':doc.data().unit2});
               console.log("ESTARY"+JSON.stringify(this.estrateary));

               this.totalamountpre = this.estrateary
  .map(item => (item.estrate * item.qnty))
  .reduce((prev, curr) => prev + curr, 0);


    })
  })
        }) ;
      }

    });
  });

  });

 // snipMe();
 this.things.changes.subscribe(t => {
  this.ngForRendred();
})
   return this.estrateary;

  }

  selectall(){
    //this.changeDetection.detectChanges();


    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(this.data.routinebillid)
        .get().toPromise()
        .then(docy => {
          console.log("cc"+this.data.routinebillid);
              if(docy.data().customerId == this.data.customerId){
                this.addorreplace(this.myary,{'billid':doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]});

            this.selectedOptions.push(String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]))
            this.updateCheckedOptions({"billid":String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)])},0);
            //this.changeDetection.detectChanges();

              }            
          })
      });
      //this.changeDetection.detectChanges();

  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  }).then(()=>{

    //this.changeDetection.detectChanges();
})
   }

  deselectall(){
    console.log("hello");
    this.myary=[];
    this.selectedOptions=[];
  }

estimationgenerate(){
  this.afs.collection('Estimation').doc('E1-'+this.aryaddressdetails[0].billono).set(
    {
      date:moment().format('DD-MM-YYYY'),
      custid:this.data.customerId,
      billid:this.aryaddressdetails[0].billid,
      estid:"E1-"+this.aryaddressdetails[0].billono,
      aryaddressdetails : this.aryaddressdetails,
      estrateary:this.estrateary,
      packagingcharge:this.packagingcharge
    },
    {merge: true}
 )
}
}

@Component({
  selector: 'dialog-data-example-dialog-eratetwo',
  templateUrl: 'dialog-data-example-dialog-eratetwo.html',
  styleUrls: ['customerprofile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDataExampleDialogERTWO implements AfterViewInit {

  message:boolean;
  selectedDate: string;
  prcollection: any;
  prdcollection: any;

favoriteCustomer: string;
customers: string[] = ['wholesale', 'retail', 'reseller', 'mrp'];

selectedOptions: string[] = [];


myary: { billid: any }[]= [] //declare before constructor

arybilldetails:{
  billid:any;
  code:string;
  productname: string;
  hsncode: string;
  discount: number;
  qnty : number;
  unit2 : string;
  rate: number;
  estrate: number;
  cgst: number;
  sgst: number;
  igst:number;
}[]=[];

aryaddressdetails:{
  billid:any;
  billono:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];

  st: string;
  step: string;
  eventdate: string;

  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalestrate: number;
  summy: { billid:any; gst: number, estrate: number, taxableamt: number, amtaftdisc:number }[]= [];
  summypre: { billid:any; gst: number, estrate: number, taxableamt: number, amtaftdisc:number }[]= [];

  pcollection: any;

  pressedtogglebtn: any;

  billbarcode: string; 
  updatecollection: any;
  bcollection: any;
  estrateary: { prdcode:string, rate:number, prdname:string, hsncode:string, estrate: any, qnty:number, unit2:string}[]= [];
  packagingcharge: number=0.00;
constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataERONE, private afs: AngularFirestore,  private changeDetection: ChangeDetectorRef) {   }


addorreplace(array, item){
 const i=array.findIndex(_item => _item.billid===item.billid);
 if(i>-1) array[i] =item;
 else array.push(item);
}

addorreplace2(array, item){
  const i=array.findIndex(_item => _item.billid===item.billid && _item.code===item.code);
  if(i>-1) array[i] =item;
  else array.push(item);
 }

 @ViewChildren('allTheseThings') things: QueryList<any>;

ngForRendred() {

  //console.log('NgFor is Rendered');
  //brilliant work dont remove it change time
  setTimeout( ()=>{
    snipMe();
    }, 2000)
    //brilliant work dont remove it change time
}


ngAfterViewInit() {

   this.st="step2";
   this.step="step2";
  //this.step= this.data.ps;
this.favoriteCustomer=this.customers[3]; // set default radio button checked

console.log("PL"+this.message);


  // this.myary=[];
  // this.prcollection =[];

  
  

   this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
   .get().toPromise()
   .then(querySnapshot => {
       querySnapshot.forEach(doc => {
         this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(this.data.routinebillid)
         .get().toPromise()
         .then(docy => {
           console.log("cc"+this.data.routinebillid);
               if(docy.data().customerId == this.data.customerId){
                 this.addorreplace(this.myary,{'billid':doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]});
 
             this.selectedOptions.push(String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]))
             this.updateCheckedOptions({"billid":String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)])},0);
             //this.changeDetection.detectChanges();
 
               }            
           })
       });
       //this.changeDetection.detectChanges();
 
   })
   .catch(function(error) {
       console.log("Error getting documents: ", error);
   }).then(()=>{
 
     //this.changeDetection.detectChanges();
 })



  //this.selectall();


  this.things.changes.subscribe(t => {
    this.ngForRendred();
  })
}



  updateCheckedOptions(tta,i){
    
    //tta.checked = !tta.checked;

    this.tottaxableamtpre=0;
    this.totamtwithtaxpre=0; 
    this.sumaftrdiscpre=0; 

    this.totalamountpre=0;


  this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(tta.billid.substring(4)).get().toPromise().then(doc => {

         this.addorreplace(this.aryaddressdetails,{'billid':tta.billid,
         billono:doc.data().billrefno,
            customerName: doc.data().customerName,
            customerAddress11: doc.data().customerAddress11,
            customerAddress12: doc.data().customerAddress12,
            customerAddress21: doc.data().customerAddress21,
            customerAddress22: doc.data().customerAddress22,
            customerContact: doc.data().customerContact,
            customerGst: doc.data().customerGst,
            customerHonor: doc.data().customerHonor,
          });    

  });
 


    this.afs.collection('Bills').doc(this.data.date).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
      this.afs.collection('Bills').doc(this.data.date).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {

          this.afs.collection('Products',ref => ref.where('code', '==', doc.data().code)).get().toPromise().then(querySnapshot => {
            querySnapshot.forEach(docz => {
              //console.log("ESTARY"+docz.data().estrate);
              this.estrateary.push({'prdcode':doc.data().code, 'rate':doc.data().rate, 'prdname':docz.data().brand +' '+ docz.data().category, 'hsncode' : docz.data().hsncode, 'estrate':docz.data().estrate2, 'qnty':doc.data().qnty, 'unit2':doc.data().unit2});
               console.log("ESTARY"+JSON.stringify(this.estrateary));

               this.totalamountpre = this.estrateary
  .map(item => (item.estrate * item.qnty))
  .reduce((prev, curr) => prev + curr, 0);


    })
  })
        }) ;
      }

    });
  });

  });

 // snipMe();
 this.things.changes.subscribe(t => {
  this.ngForRendred();
})
   return this.estrateary;

  }

  selectall(){
    //this.changeDetection.detectChanges();


    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(this.data.routinebillid)
        .get().toPromise()
        .then(docy => {
          console.log("cc"+this.data.routinebillid);
              if(docy.data().customerId == this.data.customerId){
                this.addorreplace(this.myary,{'billid':doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]});

            this.selectedOptions.push(String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]))
            this.updateCheckedOptions({"billid":String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)])},0);
            //this.changeDetection.detectChanges();

              }            
          })
      });
      //this.changeDetection.detectChanges();

  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  }).then(()=>{

    //this.changeDetection.detectChanges();
})
   }

  deselectall(){
    console.log("hello");
    this.myary=[];
    this.selectedOptions=[];
  }

estimationgenerate(){
  this.afs.collection('Estimation').doc('E2-'+this.aryaddressdetails[0].billono).set(
    {
      date:moment().format('DD-MM-YYYY'),
      custid:this.data.customerId,
      billid:this.aryaddressdetails[0].billid,
      estid:"E2-"+this.aryaddressdetails[0].billono,
      aryaddressdetails : this.aryaddressdetails,
      estrateary:this.estrateary,
      packagingcharge:this.packagingcharge
    },
    {merge: true}
 )
}
}


@Component({
  selector: 'dialog-data-example-dialog-erateiview',
  templateUrl: 'dialog-data-example-dialog-erateview.html',
  styleUrls: ['customerprofile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDataExampleDialogERVIEW implements AfterViewInit {

aryaddressdetails:{
  billid:any;
  billono:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];


  totalamountpre: number;

  totalestrate: number;
  estrateary: { prdcode:string, rate:number, prdname:string, hsncode:string, estrate: any, qnty:number, unit2:string}[]= [];
  packagingcharge: number=0.00;
  estcollection: any;
  billid: any;
  date: any;
  onAdd=new EventEmitter();

constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataERVIEW, private afs: AngularFirestore, private matDialog: MatDialog) {   }


 @ViewChildren('allTheseThings') things: QueryList<any>;

ngForRendred() {

  //console.log('NgFor is Rendered');
  //brilliant work dont remove it change time
  setTimeout( ()=>{
    snipMe();
    }, 2000)
    //brilliant work dont remove it change time
}


ngAfterViewInit() {

  this.readestimation();

  this.things.changes.subscribe(t => {
    this.ngForRendred();
  })
}


readestimation(){
  this.estcollection = this.afs.collection('Estimation', ref => ref.where('estid', '==', this.data.estid)).snapshotChanges()
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);

this.estcollection.subscribe(() => {
  this.afs.collection('Estimation', ref => ref.where('estid', '==', this.data.estid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
      this.billid = docy.data().billid,
      this.date = docy.data().date,
      this.aryaddressdetails = docy.data().aryaddressdetails,
      this.estrateary = docy.data().estrateary,
      this.packagingcharge= docy.data().packagingcharge
    })
    this.totalamountpre = this.estrateary
  .map(item => (item.estrate * item.qnty))
  .reduce((prev, curr) => prev + curr, 0);
  })
})
}


deleteestimation() {
  this.afs.collection('Estimation').doc(this.data.estid).delete()
            .then(res => {
               console.log('Estimate deleted Successfully');

               this.onAdd.emit();
               this.matDialog.closeAll();
               
               this.aryaddressdetails=[];
               this.estrateary=[];
               this.packagingcharge=0.00;
               this.totalamountpre=0.00;
             })
            .catch((error) => {
               console.error('Error removing document: ', error);
            });

}
}


@Component({
  selector: 'dialog-data-example-dialog-erateiviewtp',
  templateUrl: 'dialog-data-example-dialog-erateviewtp.html',
  styleUrls: ['customerprofile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDataExampleDialogERVIEWTP implements AfterViewInit {

aryaddressdetails:{
  billid:any;
  billono:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];


  totalamountpre: number;

  totalestrate: number;
  estrateary: { prdcode:string, rate:number, prdname:string, hsncode:string, estrate: any, qnty:number, unit2:string}[]= [];
  packagingcharge: number=0.00;
  estcollection: any;
  billid: any;
  date: any;
  onAdd=new EventEmitter();

constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataERVIEWTP, private afs: AngularFirestore, private matDialog: MatDialog) {   }


 @ViewChildren('allTheseThings') things: QueryList<any>;

ngForRendred() {

  //console.log('NgFor is Rendered');
  //brilliant work dont remove it change time
  setTimeout( ()=>{
    snipMe();
    }, 2000)
    //brilliant work dont remove it change time
}


ngAfterViewInit() {

  this.readestimation();

  this.things.changes.subscribe(t => {
    this.ngForRendred();
  })
}


readestimation(){
  this.estcollection = this.afs.collection('Estimation', ref => ref.where('estid', '==', this.data.estid)).snapshotChanges()
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);

this.estcollection.subscribe(() => {
  this.afs.collection('Estimation', ref => ref.where('estid', '==', this.data.estid)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
      this.billid = docy.data().billid,
      this.date = docy.data().date,
      this.aryaddressdetails = docy.data().aryaddressdetails,
      this.estrateary = docy.data().estrateary,
      this.packagingcharge= docy.data().packagingcharge
    })
    this.totalamountpre = this.estrateary
  .map(item => (item.estrate * item.qnty))
  .reduce((prev, curr) => prev + curr, 0);
  })
})
}


deleteestimation() {
  this.afs.collection('Estimation').doc(this.data.estid).delete()
            .then(res => {
               console.log('Estimate deleted Successfully');

               this.onAdd.emit();
               this.matDialog.closeAll();
               
               this.aryaddressdetails=[];
               this.estrateary=[];
               this.packagingcharge=0.00;
               this.totalamountpre=0.00;
             })
            .catch((error) => {
               console.error('Error removing document: ', error);
            });

}
}


@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
  styleUrls: ['customerprofile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDataExampleDialog implements AfterViewInit {

  message:boolean;
  selectedDate: string;
  prcollection: any;
  prdcollection: any;

favoriteCustomer: string;
customers: string[] = ['wholesale', 'retail', 'reseller', 'mrp'];

selectedOptions: string[] = [];


myary: { billid: any, checked: boolean  }[]= [] //declare before constructor

arybilldetails:{
  billid:any;
  code:string;
  productname: string;
  hsncode: string;
  discount: number;
  qnty : number;
  unit2 : string;
  rate: number;
  cgst: number;
  sgst: number;
  igst:number;
}[]=[];

aryaddressdetails:{
  billid:any;
  customerName:string;
  customerAddress11: string;
  customerAddress12: string;
  customerAddress21: string;
  customerAddress22: string;
  customerContact: string;
  customerGst: string;
  customerHonor: string;

}[]=[];

  st: string;
  step: string;
  eventdate: string;

  tottaxableamtpre: number;
  totamtwithtaxpre: number;
  sumaftrdiscpre: number;

  tottaxableamt: { billid:any; tottaxableamtpre: number }[]= [];
  totamtwithtax: { billid:any; totamtwithtaxpre: number }[]= [];
  sumaftrdisc: { billid:any; sumaftrdiscpre: number }[]= [];

  totalamountpre: number;
  totalamount: { billid:any; totalamountpre: number }[]= [];

  totalrate: number;
  summy: { billid:any; gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];
  summypre: { billid:any; gst: number, rate: number, taxableamt: number, amtaftdisc:number }[]= [];

  pcollection: any;

  pressedtogglebtn: any;

  billbarcode: string; 
  updatecollection: any;
  bcollection: any;

constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private afs: AngularFirestore,  private changeDetection: ChangeDetectorRef) {   }


addorreplace(array, item){
 const i=array.findIndex(_item => _item.billid===item.billid);
 if(i>-1) array[i] =item;
 else array.push(item);
}

addorreplace2(array, item){
  const i=array.findIndex(_item => _item.billid===item.billid && _item.code===item.code);
  if(i>-1) array[i] =item;
  else array.push(item);
 }

 @ViewChildren('allTheseThings') things: QueryList<any>;

ngForRendred() {

  //console.log('NgFor is Rendered');
  snipMe();
}


ngAfterViewInit() {

   this.st="step1";
   this.step="step1";
  // this.step= this.data.ps;
this.favoriteCustomer=this.customers[3]; // set default radio button checked

console.log("PL"+this.message);

// this.prcollection =[];
// //this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-12-2019')).snapshotChanges()
// this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , '08-02-2020')).snapshotChanges()

//   .pipe(
//     map(actions => actions.map(a => a.payload.doc.data()))
//   );
  this.prdcollection =[];
  this.prdcollection = this.afs.collection('Bills').doc(this.data.date).collection('1').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );


  
        this.updatecollection = this.afs.collection('Bills').snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  

  this.myary=[];
  this.prcollection =[];
  // this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  // .get().toPromise()
  // .then(querySnapshot => {
  //     querySnapshot.forEach(doc => {
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());
  //         //this.myary.push({'id':doc.data().billsid[0]});
  //         for(var i = 0; i < doc.data().totalbills ; i++){
  //           this.myary.push({'billid':doc.data().billsid[i], 'checked':false});
  //       }
  //         console.log("GGGGG: "+JSON.stringify(this.myary));

  //     });
  // })
  // .catch(function(error) {
  //     console.log("Error getting documents: ", error);
  // });




  
        this.bcollection =   this.afs.collection('Bills').doc(this.data.date).collection('--Id--').snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.bcollection.subscribe((posts) => {
    this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {

        //for(var i = 0; i < doc.data().totalbills ; i++){

          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log("ca"+this.data.date);
          console.log("ca"+this.data.routinebillid);
          console.log("ca"+this.data.customerName);
          //this.myary.push({'id':doc.data().billsid[0]});
          
        this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(this.data.routinebillid)
        .get().toPromise()
        .then(docy => {
          console.log("cc"+this.data.routinebillid);
              if(docy.data().customerId == this.data.customerId){
                console.log("cc"+this.data.routinebillid);
                console.log("cc"+doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]);
                this.addorreplace(this.myary,{'billid':doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)], 'checked':false});

              }            
          })


        
          console.log("GGGGG: "+JSON.stringify(this.myary));

        //}
      });
      
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

  this.selectall();

  })






  this.things.changes.subscribe(t => {
    this.ngForRendred();
  })
}


onSelect(event){
  console.log(event);
  this.data.date = event;
  //--newly added---
  snipMe();
      //--newly added---


    //this.snackBar.open("MESSAGE", "ACTION" ? 'OK' : undefined, {duration: 2000,verticalPosition:'bottom',horizontalPosition:'end',panelClass :['sbowncss']});
    //this.snackBar.open(this.data.date, "ACTION" ? 'OK' : undefined, {duration: 2000,verticalPosition:'bottom',horizontalPosition:'end',panelClass :['sbowncss']});
    //this.snackBar.open(this.data.date, "ACTION" ? 'OK' : undefined, {duration: 200000,panelClass :['sbowncss']});


    //this.data.date=(this.data.date);
    //console.log(String(this.data.date));

    this.prdcollection =[];
  this.prdcollection = this.afs.collection('Bills').doc(this.data.date).collection('1').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  this.tottaxableamtpre=0;
  this.totamtwithtaxpre=0; 
  this.sumaftrdiscpre=0; 

  this.totalamountpre=0;

  this.tottaxableamt=[];
  this.totamtwithtax=[]; 
  this.sumaftrdisc=[]; 
this.totalamount=[];
  
  this.summy=[];
  this.arybilldetails=[];
  this.aryaddressdetails=[];
  this.myary=[];
  this.prcollection =[];
  this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          //this.myary.push({'id':doc.data().billsid[0]});
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.myary.push({'billid':doc.data().billsid[i], 'checked':false});
        }
          console.log("GGGGG: "+JSON.stringify(this.myary));

      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });
  
  }


  updateCheckedOptions(tta,i){
    
    snipMe();

    tta.checked = !tta.checked;

    this.tottaxableamtpre=0;
    this.totamtwithtaxpre=0; 
    this.sumaftrdiscpre=0; 

    this.totalamountpre=0;


  this.afs.collection('Bills').doc(this.data.date).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(docy => {
        this.pcollection =   this.afs.collection('Bills').doc(this.data.date).collection(String(docy.data().routinebillid)).snapshotChanges()      
  .pipe( 
    map(actions => actions.map(a => a.payload.doc.data()))
  );
 
  this.pcollection.subscribe((posts) => {
    if(tta.checked==true){
      
      snipMe();
      this.summy=this.summy.filter(item => item.billid !== tta.billid)

    //console.log("posty:"+posts); //This is the array of posts, not an observable
    this.changeDetection.detectChanges();
    // this.summy=_.uniqWith(this.summy,(obj1,obj2)=>
    // obj1.billid === obj2.bilid&&
    // obj1.amtaftdisc === obj2.amtaftdisc
    // );

    this.summy=_.uniqWith(this.summy,(obj1,obj2)=>
    obj1.billid === obj2.bilid&&
    obj1.amtaftdisc === obj2.amtaftdisc
    );
    this.changeDetection.detectChanges();
      if(tta.billid.substring(3,4)=='G'){
    this.summypre=(_(posts).groupBy('cgst')
      .map((objs, key) => {
          return {
        'billid':tta.billid,
        'cgst': key,             
        'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
        'gstp':(key*2),
        'cgstp':(parseFloat(key)),
        'cgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'sgstp':(parseFloat(key)),
        'sgsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
        'igstp':(parseFloat('0')),
        'igsta':(parseFloat('0.00')),
        'taxableamt': 2 * ((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
          }
      })
      .value()
    );
    this.changeDetection.detectChanges();
      this.summy=this.summy.concat(this.summypre);
      this.changeDetection.detectChanges();

    this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
    this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
    this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
    this.changeDetection.detectChanges();
    this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
    this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
    this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})
    this.changeDetection.detectChanges();
    // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
    // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
    // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

    //console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));

    //console.log("!!!!!!!!:"+JSON.stringify(this.summypre));
    //Array.from(this.summy.reduce((m,t) => m.set(t.billid, t), new Map()).values()) //--------------Removes duplicate arrays-----------------

    //this.summy=Array.from(this.summy.reduce((m,t) => m.set(t.billid && t.amtaftdisc, t), new Map()).values()) 
   // this.summy=Array.from(this.summy.reduce((m,t) => m.set(t.amtaftdisc, t), new Map()).values()) 
 
      this.summy=_.uniqWith(this.summy,_.isEqual);
      this.changeDetection.detectChanges();
      //console.log("SUMMYYY:"+JSON.stringify(this.summy));

      this.changeDetection.detectChanges();

    }

      if(tta.billid.substring(3,4)=='I'){
        this.summypre=(_(posts).groupBy('igst')
        .map((objs, key) => {
          return {
              'billid':tta.billid,
              'igst': key,
              'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
              'gstp':(0),
              'cgstp':(parseFloat('0')),
              'cgsta':(parseFloat('0.00')),
              'sgstp':(parseFloat('0')),
              'sgsta':(parseFloat('0.00')),
              'igstp':(parseFloat(key)),
              'igsta':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
              'taxableamt':((parseFloat(key)) * (_.sumBy(objs, 'amountafterdiscount'))/100),
               }
      })
      .value()
        );
        this.summy=this.summy.concat(this.summypre);

        
        this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
        this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
        this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);
  
        this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
        this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
        this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})
      
        // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
        // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
        // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

        //console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
        this.summy=_.uniqWith(this.summy,_.isEqual);

          //console.log("SUMMYYY:"+JSON.stringify(this.summy));
        }

        if(tta.billid.substring(3,4)=='T'){
          this.summypre=(_(posts).groupBy('taxfree')
          .map((objs, key) => {
            return {
                'billid':tta.billid,
                'taxfree': key,
                'amtaftdisc': (_.sumBy(objs, 'amountafterdiscount')),
                'gstp':(0),
                'cgstp':(parseFloat('0')),
                'cgsta':(parseFloat('0.00')),
                'sgstp':(parseFloat('0')),
                'sgsta':(parseFloat('0.00')),
                'igstp':(parseFloat('0')),
                'igsta':(parseFloat('0.00')),
                'taxableamt':(parseFloat('0.00')),
                 }
        })
        .value()
          );
          this.summy=this.summy.concat(this.summypre);
          
          this.tottaxableamtpre=this.summypre.reduce((sum1, item1) => sum1 + item1.taxableamt, 0)
          this.totamtwithtaxpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0) + this.tottaxableamtpre;
          this.sumaftrdiscpre=this.summypre.reduce((sum2, item2) => sum2 + item2.amtaftdisc, 0);

          this.addorreplace(this.tottaxableamt,{'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
          this.addorreplace(this.totamtwithtax,{'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
          this.addorreplace(this.sumaftrdisc,{'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

          // this.tottaxableamt.push({'billid':tta.billid, tottaxableamtpre:this.tottaxableamtpre})
          // this.totamtwithtax.push({'billid':tta.billid, totamtwithtaxpre:this.totamtwithtaxpre})
          // this.sumaftrdisc.push({'billid':tta.billid, sumaftrdiscpre:this.sumaftrdiscpre})

          //console.log("TAXXXX:"+JSON.stringify(this.totamtwithtax));
          this.summy=_.uniqWith(this.summy,_.isEqual);

          //console.log("SUMMYYY:"+JSON.stringify(this.summy));

          }

    //console.log("posts:"+posts); //This is the array of posts, not an observable
    this.changeDetection.detectChanges();
    let ratetotal = 0;
    let amounttotal = 0;
  //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts.length);
  for (var i = 0; i < posts.length; i++) {
      if (posts[i].rate) {
        //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+posts[i].rate);
          ratetotal += posts[i].rate;
          this.totalrate = ratetotal;
          this.changeDetection.detectChanges();
          amounttotal += (posts[i].rate * posts[i].qnty);
          this.totalamountpre = amounttotal;
          this.changeDetection.detectChanges();
      }
  }

  this.addorreplace(this.totalamount,{'billid':tta.billid, totalamountpre:this.totalamountpre})
  this.changeDetection.detectChanges();
  // this.totalamount.push({'billid':tta.billid, totalamountpre:this.totalamountpre})

  //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz:"+ratetotal);
  return [ratetotal,amounttotal];
  }
  this.changeDetection.detectChanges();
  if(tta.checked==false){
    //console.log("GGG: "+JSON.stringify(this.summy));
    // const summyary =  this.summy.map(function(e) { return e.gst,e.billid; }).indexOf(tta.gst && tta.bilid);
    // if (summyary > -1) {
    //   this.summy.splice(summyary, 1);
    // }

    // const summyary =  this.summy.map(function(e) { return e.billid; }).indexOf(tta.bilid);
    // if (summyary > -1) {
    //   //console.log(summyary)

    //   this.summy.splice(summyary, 1);
    // }
    this.summy=this.summy.filter(item => item.billid !== tta.billid)

    //console.log("GGGGG: "+JSON.stringify(this.summy));
  }
  this.changeDetection.detectChanges();
  });

  
 

})
})


  this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(tta.billid.substring(4)).get().toPromise().then(doc => {
        if(tta.checked==true){
         //console.log("NNNNNN"+doc.data().customerName);

         this.addorreplace(this.aryaddressdetails,{'billid':tta.billid,
         billono:doc.data().billrefno,
            customerName: doc.data().customerName,
            customerAddress11: doc.data().customerAddress11,
            customerAddress12: doc.data().customerAddress12,
            customerAddress21: doc.data().customerAddress21,
            customerAddress22: doc.data().customerAddress22,
            customerContact: doc.data().customerContact,
            customerGst: doc.data().customerGst,
            customerHonor: doc.data().customerHonor,
          });

          // this.aryaddressdetails.push({'billid':tta.billid,
          //   customerName: doc.data().customerName,
          //   customerAddress11: doc.data().customerAddress11,
          //   customerAddress12: doc.data().customerAddress12,
          //   customerAddress21: doc.data().customerAddress21,
          //   customerAddress22: doc.data().customerAddress22,
          //   customerContact: doc.data().customerContact,
          //   customerGst: doc.data().customerGst,
          //   customerType: doc.data().customerType,
          // });

            //console.log("YYYYY"+JSON.stringify(this.aryaddressdetails));
        }
        if(tta.checked==false){
          //console.log("GGG: "+JSON.stringify(this.aryaddressdetails));
          const addrdetailsary =  this.aryaddressdetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
          if (addrdetailsary > -1) {
            this.aryaddressdetails.splice(addrdetailsary, 1);
          }
          //console.log("GGGGG: "+JSON.stringify(this.aryaddressdetails));
        }
        
      return false;         

  });


    //console.log(tta.checked);


    

    this.afs.collection('Bills').doc(this.data.date).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
          this.pcollection =   this.afs.collection('Bills').doc(this.data.date).collection(String(docy.data().routinebillid)).snapshotChanges()      
    .pipe( 
      map(actions => actions.map(a => a.payload.doc.data()))
    );
   
    this.pcollection.subscribe((posts) => {





    this.afs.collection('Bills').doc(this.data.date).collection('--Id--',ref => ref.where('currentbillid', '==', Number(tta.billid.substring(4)))).get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(docy => {
      this.afs.collection('Bills').doc(this.data.date).collection(String(docy.data().routinebillid)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          if(tta.checked==true){
           // this.myary.push({'billid':tta.billid});

           this.addorreplace2(this.arybilldetails,{'billid':tta.billid,
            'code':doc.data().code,
            'productname': doc.data().productname,
            'hsncode' : doc.data().hsncode,
            'discount' : doc.data().discount,
            'qnty' : doc.data().qnty,
            'unit2' : doc.data().unit2,
            'rate': doc.data().rate,
            'cgst': doc.data().cgst,
            'sgst': doc.data().sgst,
            'igst': doc.data().igst,
          });

            // this.arybilldetails.push({'billid':tta.billid,
            //   'code':doc.data().code,
            //   'productname': doc.data().productname,
            //   'hsncode' : doc.data().hsncode,
            //   'discount' : doc.data().discount,
            //   'qnty' : doc.data().qnty,
            //   'unit2' : doc.data().unit2,
            //   'rate': doc.data().rate,
            //   'cgst': doc.data().cgst,
            //   'sgst': doc.data().sgst,
            //   'igst': doc.data().igst,
            // });
            this.changeDetection.detectChanges();

              //console.log("YYYYY"+JSON.stringify(this.arybilldetails));
          }
          if(tta.checked==false){

            //console.log("GGG: "+JSON.stringify(this.arybilldetails));
            const detailsary =  this.arybilldetails.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (detailsary > -1) {
              this.arybilldetails.splice(detailsary, 1);
            }
            //console.log("AAAAAA: "+JSON.stringify(this.arybilldetails));

            //console.log("SSS: "+JSON.stringify(this.summy));
            const gstdetailsary =  this.summy.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (gstdetailsary > -1) {
              this.summy.splice(gstdetailsary, 1);
            }
            //console.log("SSSSS: "+JSON.stringify(this.summy));

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
            const tottaxableamtdetailsary =  this.tottaxableamt.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (tottaxableamtdetailsary > -1) {
              this.tottaxableamt.splice(tottaxableamtdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.tottaxableamt));
            this.changeDetection.detectChanges();

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));
            const totamtwithtaxdetailsary =  this.totamtwithtax.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (totamtwithtaxdetailsary > -1) {
              this.totamtwithtax.splice(totamtwithtaxdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totamtwithtax));

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.sumaftrdisc));
            const sumaftrdiscdetailsary =  this.sumaftrdisc.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (sumaftrdiscdetailsary > -1) {
              this.sumaftrdisc.splice(sumaftrdiscdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.sumaftrdisc));

            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totalamount));
            const totalamountdetailsary =  this.totalamount.map(function(e) { return e.billid; }).indexOf(tta.billid);
            if (totalamountdetailsary > -1) {
              this.totalamount.splice(totalamountdetailsary, 1);
            }
            //console.log("TTTTTTAAAA: "+JSON.stringify(this.totalamount));
            this.changeDetection.detectChanges();

          }

        }) ;
        return false;         
      }
    
    });
  });


    });
  });
});









  });

  
  snipMe();

   //console.log("R: "+JSON.stringify(this.myary));

   return this.myary;
  }

  selectall(){
    this.changeDetection.detectChanges();

    this.summy=[];
    this.arybilldetails=[];
    this.aryaddressdetails=[];
    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          //for(var i = 0; i < doc.data().totalbills ; i++){

          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          console.log("ca"+this.data.date);
          console.log("ca"+this.data.routinebillid);
          console.log("ca"+this.data.customerName);
          //this.myary.push({'id':doc.data().billsid[0]});
          
        this.afs.collection('Bills').doc(this.data.date).collection('--stats--').doc(this.data.routinebillid)
        .get().toPromise()
        .then(docy => {
          console.log("cc"+this.data.routinebillid);
              if(docy.data().customerId == this.data.customerId){
                console.log("cc"+this.data.routinebillid);
                console.log("cc"+doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]);
                this.addorreplace(this.myary,{'billid':doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)], 'checked':false});

            this.selectedOptions.push(String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]))
            this.updateCheckedOptions({"billid":String(doc.data().billsid[doc.data().billsrefno.indexOf(docy.data().billrefno)]), "checked":false},0);
            this.changeDetection.detectChanges();

              }            
          })


        
          console.log("GGGGG: "+JSON.stringify(this.myary));

        //}
      });
      this.changeDetection.detectChanges();

  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  }).then(()=>{
    this.changeDetection.detectChanges();
})
   }

  deselectall(){
    this.prcollection = this.afs.collection('Bills', ref => ref.where('date2', "==" , this.data.date))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          for(var i = 0; i < doc.data().totalbills ; i++){
            this.addorreplace(this.myary,{'billid':String(doc.data().billsid[i]), 'checked':false})
            //---------------remove an array item-----------------
            const index = this.selectedOptions.indexOf(String(doc.data().billsid[i]));
            if (index > -1 ) {
            this.selectedOptions.splice(index,1)
            }
            //---------------remove an array item-----------------
            this.updateCheckedOptions({"billid":String(doc.data().billsid[i]), "checked":true},0);

          }
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });


  }
  
  pressedtogglebtnreset(){
    this.pressedtogglebtn=null;
    //this.selectall();
    this.changeDetection.detectChanges();

  }

  sortagain(){
    //var sortedmyaryagain = this.myary.map(el => el.billid.substring(4))
    this.myary.sort(function(a,b){return a.billid.substring(4) - b.billid.substring(4)})
  }

  srchbrcode(){

    var first =this.billbarcode;
    this.myary.sort(function(x,y){ return x.billid == first ? -1 : y.billid == first ? 1 : 0})

    this.addorreplace(this.myary,{'billid':this.billbarcode, 'checked':true})
    this.addorreplace(this.selectedOptions,this.billbarcode)
    this.updateCheckedOptions({"billid":this.billbarcode, "checked":false},0);
    this.changeDetection.detectChanges();
  }

}


