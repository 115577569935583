import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import { forEach,sum} from 'lodash';
import * as _ from 'lodash';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css'],
})

export class ProductdetailsComponent implements AfterViewInit, OnInit {

  displayedColumns = ['code','brand','category','color','size','modelno','labelcode','suppliercode','hsncode','gst','prate','rate','qntyshop1',];
  dataSource: MatTableDataSource<any>; 
  
  // displayedColumns2 = ['brand','category','subcategory1','subcategory2','code','color','size','modelno','labelcode','suppliercode','hsncode','gst','prate','rate','qntygodown','qntyshop1','qntyshop2','totqnty','estrate','estrate2','mrpdiscP','mrpdisc','retaildiscP','retaildisc','resellerdiscP','resellerdisc','wholesalediscP','wholesaledisc'];
  displayedColumns2 = ['brand','category','code','color','size','modelno','labelcode','suppliercode','hsncode','gst','prate','rate','qntyshop1'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  totalValue: any;
  
  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder) {   }
  prcollection: any;
  qntycollection: any;

  search: any='';
  search2: any='';


  ngAfterViewInit() {
    this.prcollection = this.afs.collection('Products').valueChanges().subscribe(data => {
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator=this.paginator;
    
      
      //  //It searches Category columns. To search in entire table skip it
      //  this.dataSource.filterPredicate = (data, filter) =>
      //  (data.category.indexOf(filter) !== -1);
      //  //----------------------------------------------------------------------
      //  console.log((this.dataSource));
     })
  }
  
  ngOnInit() {
}

  applyFilter(){
      const filterObj = { 
          filterValue1: this.search.trim().toUpperCase(),
          filterValue2: this.search2.trim().toUpperCase()
      };
      this.dataSource.filter = JSON.stringify(filterObj);

      this.dataSource.filterPredicate = (data, filterObjString: string) => {
        const filterObj = JSON.parse(filterObjString);
        const filterValue1 = filterObj.filterValue1;
        const filterValue2 = filterObj.filterValue2;
 
    if(filterValue1==''){
      return (data.category.indexOf(filterValue2) !== -1);
    }
    if(filterValue2==''){
      return ( data.brand.indexOf(filterValue1) !== -1);
    }
    if(filterValue1!='' && filterValue2!=''){
      return ( data.brand.indexOf(filterValue1) !== -1 && 
      data.category.indexOf(filterValue2) !== -1);
    }

    }
  }


  gettotalRows(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){return this.dataSource.filteredData.length;
    }
    }

getXS(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xsqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='XS'), function (a) {
    return parseInt(a.qntyshop1);
});
return xsqntyshop1;
  }
}
getS(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let sqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='S'), function (a) {
    return parseInt(a.qntyshop1);
});
return sqntyshop1;
  }
}
getM(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let mqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='M'), function (a) {
    return parseInt(a.qntyshop1);
});
return mqntyshop1;
  }
}
getL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let lqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='L'), function (a) {
    return parseInt(a.qntyshop1);
});
return lqntyshop1;
  }
}
getXL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xlqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='XL'), function (a) {
    return parseInt(a.qntyshop1);
});
return xlqntyshop1;
  }
}
getXXL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xxlqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='XXL'), function (a) {
    return parseInt(a.qntyshop1);
});
return xxlqntyshop1;
  }
}
getXXXL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xxxlqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='XXXL'), function (a) {
    return parseInt(a.qntyshop1);
});
return xxxlqntyshop1;
  }
}
get4XL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xxxxlqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='4XL'), function (a) {
    return parseInt(a.qntyshop1);
});
return xxxxlqntyshop1;
  }
}
get5XL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xxxxxlqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='5XL'), function (a) {
    return parseInt(a.qntyshop1);
});
return xxxxxlqntyshop1;
  }
}
get6XL(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let xxxxxxlqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => i.size=='6XL'), function (a) {
    return parseInt(a.qntyshop1);
});
return xxxxxxlqntyshop1;
  }
}
getFR(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let frqntyshop1= _.sumBy(this.dataSource.filteredData.filter(i => (i.size=='FR' || i.size=='FREE' || i.size=='NIL')), function (a) {
    return parseInt(a.qntyshop1);
});
return frqntyshop1;
  }
}



  getTotalQntyGodown(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntygodown = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntygodown);
  });
  return tqntygodown;
  }
  }

  getTotalQntyShop1(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntyshop1 = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntyshop1);
  });
  return tqntyshop1;
  }
  }

  getTotalQntyShop2(){
    if(this.dataSource === undefined){return 0;}
    else if(this.dataSource != undefined){let tqntyshop2 = _.sumBy(this.dataSource.filteredData, function (a) {
       return parseInt(a.qntyshop2);
  });
  return tqntyshop2;
  }
  }



}
