import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnimationDurations, MatSnackBar } from '@angular/material';
import { firestore } from 'firebase';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-brandscategories',
  templateUrl: './brandscategories.component.html',
  styleUrls: ['./brandscategories.component.css']
})
export class BrandscategoriesComponent implements OnInit {

  public BForm: FormGroup;
  public CForm: FormGroup;
  public sCForm: FormGroup;

  brandnew: any;
  categorynew: any;
  brcollection: any;
  ctcollection: any;
  selectedbrand: any;
  selectedcategory: any;
  selectedbrand2: any;
  selectedcategory2: any;
  brcollection2: any;
  ctcollection2: any;

  // subcategorynew: any;
  // sctcollection: any;
  // selectedsubcategory: any;
  // selectedsubcategory2: any;


  sizy:string;
  sizes = [{label:'default',checked:true}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];
  
  sizenameDEFAULT: string='default';
  sizenameMM: string='0';
  sizenameCM: string='0';
  sizenameINCHES: string='0';
  sizenameFEET: string='0';
  sizenameNUMBERS: string='0';
  sizenameLETTERS: string='0';

  updateCheckedOptions(index, size) {

    size.checked = !size.checked;
    //----newly added--------
    size.checked=true;
    //----newly added--------
    console.log(index,size.checked);

    if(size.checked==true)
    {
    if(index==0){this.sizenameDEFAULT='default';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:true}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==1){this.sizenameDEFAULT='0';this.sizenameMM='mm';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:true}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==2){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='cm';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:true}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==3){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='inches';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:true}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==4){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='feet';this.sizenameNUMBERS='0';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:true}, {label:'numbers',checked:false}, {label:'letters',checked:false}];}
    if(index==5){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='numbers';this.sizenameLETTERS='0';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:true}, {label:'letters',checked:false}];}
    if(index==6){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameCM='0';this.sizenameINCHES='0';this.sizenameFEET='0';this.sizenameNUMBERS='0';this.sizenameLETTERS='letters';this.sizes = [{label:'default',checked:false}, {label:'mm',checked:false}, {label:'cm',checked:false}, {label:'inches',checked:false}, {label:'feet',checked:false}, {label:'numbers',checked:false}, {label:'letters',checked:true}];}

    console.log(this.sizes);
    }
    if(size.checked==false){
      console.log(index,size);

    }
   
}

constructor(private afs: AngularFirestore, public snackBar: MatSnackBar, public fb: FormBuilder) {

}
openSnackBar(message: string, action: string) {
 this.snackBar.open(message, action, {
    duration: 2000,
    panelClass: ['snackbarcss'],
 });
 }

ngOnInit() {

 this.FormB();
 this.FormC();
 //this.FormsC();

 this.brcollection =[];
 this.brcollection = this.afs.collection('Brands').snapshotChanges()
 .pipe(
   map(actions => actions.map(a => a.payload.doc.data()))
 );
 
 this.ctcollection =[];
 this.ctcollection = this.afs.collection('Categories').snapshotChanges()
 .pipe(
   map(actions => actions.map(a => a.payload.doc.data()))
 );

//  this.sctcollection =[];
//  this.sctcollection = this.afs.collection('subCategories',ref => ref.orderBy('allsubcategories','asc')).snapshotChanges()
//  .pipe(
//    map(actions => actions.map(a => a.payload.doc.data()))
//  );

 this.brcollection2 =[];
 this.brcollection2 = this.afs.collection('Brands').snapshotChanges()
 .pipe(
   map(actions => actions.map(a => a.payload.doc.data()))
 );

}

// Reactive particular form
FormB() {
 this.BForm = this.fb.group({
   newbrand: [''],
 })
}
// Accessing form control using getters
get newbrand() {
 return this.BForm.get('newbrand');
}

// Reactive particular form
FormC() {
this.CForm = this.fb.group({
 newcategory: [''],
})
}
// FormsC() {
// this.sCForm = this.fb.group({
//  newsubcategory: [''],
// })
// }
// Accessing form control using getters
get newcategory() {
return this.CForm.get('newcategory');
}
// get newsubcategory() {
// return this.sCForm.get('newsubcategory');
// }
bsubmitbtn(){
this.afs.collection('Brands').doc(String(this.brandnew).toUpperCase()).set(
 {
   brandname : String(this.brandnew).toUpperCase()
 },
  {merge: true}
)
}
// scsubmitbtn(){
// this.afs.collection('subCategories').doc('allsubcategories').set(
//  {
//    allsubcategories:firestore.FieldValue.arrayUnion(String(this.subcategorynew).toUpperCase()),
//    // committed:'', 
//  },
//   {merge: true}
// )
// }

bdeletebtn(brndname){
this.afs.collection('Brands').doc(brndname).delete().then(function() {
 console.log("Document successfully deleted!");
}).catch(function(error) {
 console.error("Error removing document: ", error);
});
}

csubmitbtn(){
this.afs.collection('Categories').doc('allcategories').set(
 {
   allcategories:firestore.FieldValue.arrayUnion(String(this.categorynew).toUpperCase()),
   // committed:'', 
 },
  {merge: true}
)
}

cdeletebtn(ctname){
this.afs.collection('Categories').doc('allcategories').update({
 allcategories:firestore.FieldValue.arrayRemove(ctname)
})
}
// scdeletebtn(sctname){
// this.afs.collection('subCategories').doc('allsubcategories').update({
//  allsubcategories:firestore.FieldValue.arrayRemove(sctname)
// })
// }
changeBrand(brandselected){
this.selectedbrand=brandselected;
}
changeCategory(categoryselected){
this.selectedcategory=categoryselected;
}
// changesubCategory(subcategoryselected){
// this.selectedsubcategory=subcategoryselected;
// }

bccommitbtn(br,ct,sct,szy){
if(szy != undefined){

this.afs.collection('BrandCategoryCounter').get().toPromise().then( querySnapshot => {
 querySnapshot.forEach( doc => {

   let a=doc.data().count;
   console.log(a)

this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
if(!doc.data().categories){
this.afs.collection('Brands').doc(br).update({
 categories:firestore.FieldValue.arrayUnion({
   sizechart:String(szy).toUpperCase(),
   categoryname:String(ct).toUpperCase(),
  // subcategoryname:String(sct).toUpperCase(),
   id:a})
}).then(()=>{

this.afs.collection('Categories').doc('allcategories').update({
 committed:firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
})
.then(()=>{
//  this.afs.collection('subCategories').doc('allsubcategories').update({
//    committed:firestore.FieldValue.arrayUnion(String(sct).toUpperCase()) 
//  })
// .then(()=>{

 this.afs.collection('BrandCategoryCounter').doc('Counter').update({
   count: firestore.FieldValue.increment(1)
 })
//})
})
})
}
var arr=[];    
if(doc.data().categories){
for(let i=0;i<doc.data().categories.length;i++){
 if(doc.data().categories[i].categoryname == ct){
   arr.push(ct)
   console.log("ALREADY COMMITTED")
   this.openSnackBar("ALREADY COMMITTED","OK");
 }
 if(doc.data().categories[i].categoryname != ct){
 }
}

if(arr.length==0){
this.afs.collection('Brands').doc(br).update({
categories:firestore.FieldValue.arrayUnion({
 sizechart:String(szy).toUpperCase(),
 categoryname:String(ct).toUpperCase(),
 id:a}) 
}).then(()=>{

this.afs.collection('Categories').doc('allcategories').update({
committed:firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
}).then(()=>{

// this.afs.collection('subCategories').doc('allsubcategories').update({
//  committed:firestore.FieldValue.arrayUnion(String(sct).toUpperCase()) 
// }).then(()=>{

this.afs.collection('BrandCategoryCounter').doc('Counter').update({
 count: firestore.FieldValue.increment(1)
})
//})
})
})
console.log("NEWLY COMMITTED")
this.openSnackBar("NEWLY COMMITTED","OK");
 }
}

})

})

})

}
else{
this.openSnackBar('Please Select Size Chart', 'OK')
}
}

changeBrand2(brandselected){
this.selectedbrand2=brandselected;
 
this.ctcollection2 =[];
this.ctcollection2 = this.afs.collection('Brands',ref => ref.where('brandname', "==" , brandselected)).snapshotChanges()
.pipe(
 map(actions => actions.map(a => a.payload.doc.data()))
);

}

changeCategory2(brandselected,categoryselected){
this.selectedcategory2=categoryselected;

}

// changesubCategory2(brandselected,subcategoryselected){
//  this.selectedsubcategory2=subcategoryselected;
//  }


}