import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, Observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';
import * as _ from 'lodash';

@Component({
  selector: 'app-alterstock',
  templateUrl: './alterstock.component.html',
  styleUrls: ['./alterstock.component.css']
})
export class AlterstockComponent implements OnInit {

  public pcForm: FormGroup;
  prdbrand: any='';
  prdcategory: any='';
  prdcode: any='';
  prdcollection: any;

 public qntyForm: FormGroup;


dsbtndisabled: boolean;

newshop1qnty: number = 0;
newshop2qnty: number = 0;
newgodownqnty: number = 0;

rsbtndisabled: boolean;
bcid: any;
searched: boolean;
  prdsize: any;
  prdcolor: any;
  prdrate: any;
  prdctry: any;
  bccn: any;
  sizchrtname: any;
  lblcode: any='';

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  brcollection: any;
  ctcollection: any;
  codecollection: any;
  qntycollection: any;
  stckqntycollection: any;

  ngOnInit() {
    this.Form();  

    this.brcollection =[];
    this.brcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    //this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productrack', ref => ref.where('code', "==" , this.prdcode)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.searched=false;

  }

  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      productcode: [''],
    })

    this.qntyForm = this.fb.group({
      nwshop1quantity: [''],
      newshop2quantity: [''],
      newgodownquantity: ['']
    })
  }

   // Accessing form control using getters
   get productbrand() {
    return this.pcForm.get('productbrand');
  }
  get productcategory() {
    return this.pcForm.get('productcategory');
  }
  get productcode() {
    return this.pcForm.get('productcode');
  }

  get nwshop1quantity() {
    return this.qntyForm.get('nwshop1quantity');
  }
  get newshop2quantity() {
    return this.qntyForm.get('newshop2quantity');
  }
  get newgodownquantity() {
    return this.qntyForm.get('newgodownquantity');
  }

  changeBrand(br){
      
    this.ctcollection =[];
    this.ctcollection = this.afs.collection('Brands',ref => ref.where('brandname', "==" , br)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
    }

  changeCategory(br,ct){
    console.log(this.prdbrand);
    console.log(this.prdcategory);

    this.codecollection =[];
    this.codecollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , this.prdcategory.substr(this.prdcategory.indexOf(' ')+1))).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
  }

  changeCode(br,ct,cd){

    console.log(this.prdbrand);
    console.log(this.prdcategory);
    console.log(this.prdcode);

    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
  
    //this.stckqntycollection=[];
    this.stckqntycollection=  this.afs.collection('Productrack', ref => ref.where('code', "==" , cd)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  }





  alterstock(newshop1qnty,newshop2qnty,newgodownqnty,cd,br,ct){
  
    if(this.bcid!=''){
      cd=this.bcid;
      var pcmd=this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid))
      var pcbaskmd=this.afs.collection('Productrack', ref => ref.where('code', "==" , this.bcid))

      
      
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntyshop1 : Number(doc.data().qntyshop1) + Number(newshop1qnty),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(newshop2qnty),    
         qntygodown : Number(doc.data().qntygodown) + Number(newgodownqnty),
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productrack').doc(cd).set({
          code: cd,
          newshop1qnty : firebase.firestore.FieldValue.increment(Number(newshop1qnty)),
          newshop2qnty : firebase.firestore.FieldValue.increment(Number(newshop2qnty)),
          newgodownqnty : firebase.firestore.FieldValue.increment(Number(newgodownqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=pcbaskmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );





    this.afs.collection('Products', ref => ref.where('code', '==' , cd)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {
        console.log("hello3");
        querySnapshot.forEach(doc => {
          this.prdsize = doc.data().size;
          this.prdcolor = doc.data().color;
          this.prdrate = doc.data().rate;
          this.prdbrand = doc.data().brand;
          this.prdctry=doc.data().category;
          this.bccn=doc.data().bcc;
          this.lblcode=doc.data().labelcode;
        })
      }
      }).then(()=>{
        console.log(this.prdbrand)
        this.afs.collection('Brands').doc(this.prdbrand).get().toPromise().then(doc => {
      if(doc.data().categories){
        for(let i=0;i<doc.data().categories.length;i++){
          if(doc.data().categories[i].categoryname == this.prdctry){
            this.sizchrtname = doc.data().categories[i].sizechart
            console.log(this.sizchrtname)
          }
          if(doc.data().categories[i].categoryname != this.prdctry){
          }
      }
      
    }
  }).then(()=>{

  
    this.afs.collection("Barcoderack").doc(cd).set({
      category:this.prdctry,
      bcc:this.bccn,
      size:this.prdsize,

      //newly added
      sizechart: this.sizchrtname,
      labelcode: this.lblcode,
      //newly added

      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:cd,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      rate:Number(this.prdrate),

      //qntycount:Number(Number(this.prdqntyshop1)),
      qntycount:Number(Number(this.newshop1qnty)+Number(this.newshop2qnty)+Number(this.newgodownqnty))
    })
      })
    })


    })

          }
      else{
        var pcmd=this.afs.collection('Products', ref => ref.where('code', "==" , cd))
        var pcbaskmd=this.afs.collection('Productrack', ref => ref.where('code', "==" , cd))

        
      
    pcmd.get().toPromise().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.set({
        qntyshop1 : Number(doc.data().qntyshop1) + Number(newshop1qnty),
        qntyshop2 : Number(doc.data().qntyshop2) + Number(newshop2qnty),    
         qntygodown : Number(doc.data().qntygodown) + Number(newgodownqnty),
      },
       {merge: true}
    )
    .catch((err) => {
      console.log(err);
    })
    })
    }).then(()=>{

      this.afs.collection('Productrack').doc(cd).set({
          code: cd,
          newshop1qnty : firebase.firestore.FieldValue.increment(Number(newshop1qnty)),
          newshop2qnty : firebase.firestore.FieldValue.increment(Number(newshop2qnty)),
          newgodownqnty : firebase.firestore.FieldValue.increment(Number(newgodownqnty)),
        },
         {merge: true}
      )
      .catch((err) => {
        console.log(err);
      })
    
      
    this.prdcollection = pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=pcmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    
    this.stckqntycollection=[];
    this.stckqntycollection=pcbaskmd.snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );





    this.afs.collection('Products', ref => ref.where('code', '==' , cd)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {
        console.log("hello3");
        querySnapshot.forEach(doc => {
          this.prdsize = doc.data().size;
          this.prdcolor = doc.data().color;
          this.prdrate = doc.data().rate;
          this.prdbrand = doc.data().brand;
          this.lblcode = doc.data().labelcode;
        })
      }
      }).then(()=>{
        this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
      if(doc.data().categories){
        for(let i=0;i<doc.data().categories.length;i++){
          if(doc.data().categories[i].categoryname == this.prdcategory.substr(this.prdcategory.indexOf(' ')+1)){
            this.sizchrtname = doc.data().categories[i].sizechart
            console.log(this.sizchrtname)
          }
          if(doc.data().categories[i].categoryname != this.prdcategory.substr(this.prdcategory.indexOf(' ')+1)){
          }
      }
      
    }
  }).then(()=>{

    console.log("hello"+this.lblcode);
    this.afs.collection("Barcoderack").doc(cd).set({
      category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
      bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
      size:this.prdsize,

      //newly added
      sizechart: this.sizchrtname,
      labelcode: this.lblcode,
      //newly added

      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:cd,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      rate:Number(this.prdrate),

      //qntycount:Number(Number(this.prdqntyshop1)),
      qntycount:Number(Number(this.newshop1qnty)+Number(this.newshop2qnty)+Number(this.newgodownqnty))
    })
      })
    })


    })


      }

      
  //   pcmd.get().toPromise().then(querySnapshot => {
  //     querySnapshot.forEach(doc => {
  //       doc.ref.set({
  //       qntyshop1 : Number(doc.data().qntyshop1) + Number(newshop1qnty),
  //       qntyshop2 : Number(doc.data().qntyshop2) + Number(newshop2qnty),    
  //        qntygodown : Number(doc.data().qntygodown) + Number(newgodownqnty),
  //     },
  //      {merge: true}
  //   )
  //   .catch((err) => {
  //     console.log(err);
  //   })
  //   })
  //   }).then(()=>{

  //     this.afs.collection('Productrack').doc(cd).set({
  //         code: cd,
  //         newshop1qnty : firebase.firestore.FieldValue.increment(Number(newshop1qnty)),
  //         newshop2qnty : firebase.firestore.FieldValue.increment(Number(newshop2qnty)),
  //         newgodownqnty : firebase.firestore.FieldValue.increment(Number(newgodownqnty)),
  //       },
  //        {merge: true}
  //     )
  //     .catch((err) => {
  //       console.log(err);
  //     })
    
      
  //   this.prdcollection = pcmd.snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map(a => a.payload.doc.data()))
  //   );

  //   this.qntycollection=[];
  //   this.qntycollection=pcmd.snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map(a => a.payload.doc.data()))
  //   );

    
  //   this.stckqntycollection=[];
  //   this.stckqntycollection=pcbaskmd.snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map(a => a.payload.doc.data()))
  //   );





  //   this.afs.collection('Products', ref => ref.where('code', '==' , cd)).get().toPromise().then(querySnapshot => {
  //     if (querySnapshot.size > 0) {
  //       console.log("hello3");
  //       querySnapshot.forEach(doc => {
  //         this.prdsize = doc.data().size;
  //         this.prdcolor = doc.data().color;
  //         this.prdrate = doc.data().rate;
  //         this.prdbrand = doc.data().brand;
  //       })
  //     }
  //     }).then(()=>{
  //       this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
  //     if(doc.data().categories){
  //       for(let i=0;i<doc.data().categories.length;i++){
  //         if(doc.data().categories[i].categoryname == this.prdcategory.substr(this.prdcategory.indexOf(' ')+1)){
  //           this.sizchrtname = doc.data().categories[i].sizechart
  //           console.log(this.sizchrtname)
  //         }
  //         if(doc.data().categories[i].categoryname != this.prdcategory.substr(this.prdcategory.indexOf(' ')+1)){
  //         }
  //     }
      
  //   }
  // }).then(()=>{

  
  //   this.afs.collection("Barcoderack").doc(cd).set({
  //     category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
  //     bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
  //     size:this.prdsize,

  //     //newly added
  //     sizechart: this.sizchrtname,
  //     //newly added

  //     color:this.prdcolor,
  //     // code:this.prdcolor+this.prdsize,
  //     code:cd,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
  //     rate:Number(this.prdrate),

  //     //qntycount:Number(Number(this.prdqntyshop1)),
  //     qntycount:Number(Number(this.newshop1qnty)+Number(this.newshop2qnty)+Number(this.newgodownqnty))
  //   })
  //     })
  //   })


  //   })

   this.dsbtndisabled=false;
   this.rsbtndisabled=false;

  }  

  clear(){
    this.qntycollection='';
    this.stckqntycollection='';
    this.searched=false;

  }

  reset(){
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection='';
    this.qntycollection='';
    this.stckqntycollection='';
    this.newshop1qnty=0;
    this.newshop2qnty=0;
    this.newgodownqnty=0;
    this.searched=false;

  }
  BCsearch(){
    this.searched=true;
    this.prdbrand='';
    this.prdcategory='';
    this.prdcode='';
    this.prdcollection = this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.qntycollection=[];
    this.qntycollection=  this.afs.collection('Products', ref => ref.where('code', "==" , this.bcid)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.stckqntycollection=  this.afs.collection('Productrack', ref => ref.where('code', "==" , this.bcid)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
console.log(this.qntycollection);
    
  }

}

