import { Component, OnInit } from '@angular/core';
import { Drawer2Service } from "../../assets/services/drawer2.service";
import { Drawer3Service } from "../../assets/services/drawer3.service";
import { Drawer10Service } from "../../assets/services/drawer10.service";
import { Drawer11Service } from "../../assets/services/drawer11.service";
import { Drawer12Service } from "../../assets/services/drawer12.service";
import { Drawer13Service } from "../../assets/services/drawer13.service";
import { Drawer14Service } from "../../assets/services/drawer14.service";
import { Drawer15Service } from "../../assets/services/drawer15.service";

declare function openfullscreen(): any;
declare function closefullscreen(): any;


@Component({
  selector: 'app-sidebarl',
  templateUrl: './sidebarl.component.html',
  styleUrls: ['./sidebarl.component.css']
})
export class SidebarlComponent implements OnInit {

screenWidth: number;

message:boolean;
message3:boolean;
message10:boolean;
message11:boolean;
message12:boolean;
message13:boolean;
message14:boolean;
message15:boolean;

constructor(private data: Drawer2Service,private data3: Drawer3Service,private data10: Drawer10Service,private data11: Drawer11Service,private data12: Drawer12Service,private data13: Drawer13Service,private data14: Drawer14Service,private data15: Drawer15Service) {
  // set screenWidth on page load
  this.screenWidth = window.innerWidth;
  window.onresize = () => {
    // set screenWidth on screen size change
    this.screenWidth = window.innerWidth;
  };
}
  ngOnInit() {
    this.data.currentMessage.subscribe(message => this.message = message)
    console.log("SL"+this.message);

    this.data3.currentMessage3.subscribe(message3 => this.message3 = message3)
    console.log("SL"+this.message3);

    this.data10.currentMessage10.subscribe(message10 => this.message10 = message10)
    console.log("SL"+this.message10);

    this.data11.currentMessage11.subscribe(message11 => this.message11 = message11)
    console.log("SL"+this.message11);

    this.data12.currentMessage12.subscribe(message12 => this.message12 = message12)
    console.log("SL"+this.message12);

    this.data13.currentMessage13.subscribe(message13 => this.message13 = message13)
    console.log("SL"+this.message13);

    this.data14.currentMessage14.subscribe(message14 => this.message14 = message14)
    console.log("SL"+this.message14);
    
    this.data15.currentMessage15.subscribe(message15 => this.message15 = message15)
    console.log("SL"+this.message15);
  }

  newMessage() {
    if( this.message== true)
    {console.log("INSIDE "+this.message);
      this.data.changeMessage(false)
      console.log(this.message);}
    else if(this.message== false)
    {this.data.changeMessage(true)}
    console.log(this.message);

  }

  newMessage3() {
    if( this.message3== true)
    {console.log("INSIDE "+this.message3);
      this.data3.changeMessage3(false)
      console.log(this.message3);}
    else if(this.message3== false)
    {this.data3.changeMessage3(true)}
    console.log(this.message3);

  }

  newMessage10() {
    if( this.message10== true)
    {console.log("INSIDE "+this.message10);
      this.data10.changeMessage10(false)
      console.log(this.message10);}
    else if(this.message10== false)
    {this.data10.changeMessage10(true)}
    console.log(this.message10);

  }

  newMessage11() {
    if( this.message11== true)
    {console.log("INSIDE "+this.message11);
      this.data11.changeMessage11(false)
      console.log(this.message11);}
    else if(this.message11== false)
    {this.data11.changeMessage11(true)}
    console.log(this.message11);

  }

  newMessage12() {
    if( this.message12== true)
    {console.log("INSIDE "+this.message12);
      this.data12.changeMessage12(false)
      console.log(this.message12);}
    else if(this.message12== false)
    {this.data12.changeMessage12(true)}
    console.log(this.message12);

  }

  newMessage13() {
    if( this.message13== true)
    {console.log("INSIDE "+this.message13);
      this.data13.changeMessage13(false)
      console.log(this.message13);}
    else if(this.message13== false)
    {this.data13.changeMessage13(true)}
    console.log(this.message13);

  }

  newMessage14() {
    if( this.message14== true)
    {console.log("INSIDE "+this.message14);
      this.data14.changeMessage14(false)
      console.log(this.message14);}
    else if(this.message14== false)
    {this.data14.changeMessage14(true)}
    console.log(this.message14);

  }
  newMessage15() {
    if( this.message15== true)
    {console.log("INSIDE "+this.message15);
      this.data15.changeMessage15(false)
      console.log(this.message15);}
    else if(this.message15== false)
    {this.data15.changeMessage15(true)}
    console.log(this.message15);

  }

  public icon = 'fullscreen'; 
  public toggleIcon() {
      if (this.icon === 'fullscreen') {
          this.icon = 'fullscreen_exit';
          openfullscreen();

      } else {
          this.icon = 'fullscreen';
          closefullscreen();

      }
  }

}
