import { Component, OnInit, TrackByFunction } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import * as _ from'lodash';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';

interface ImageEnitity {
  index: string; 
  id: string; 
  code: string;
}

@Component({
  selector: 'app-barcodelayout',
  templateUrl: './barcodelayout.component.html',
  styleUrls: ['./barcodelayout.component.css']
})
export class BarcodelayoutComponent implements OnInit {
  prdcollection: any;
  myArray: { index: string, id: string, code: string, qntycount: number, rate:number, bcc:string, color:string, size:string, sizechart:string, brand:string, category:string, labelcode:string ,suppliercode:string  }[]= [] //declare before constructor
  brndcollection: any;
  ctrycollection: any;
  prcollection: any;
  prdbrand: any;
  prdcategory: any;
  labelcode: any;
  suppliercode: any;

  constructor(private afs: AngularFirestore) { }

  taskTypeAreas: {
    code: any;
  }[] = [];
  selectedOptions: string[] = [];

  codesearch = new FormControl();
  $taskTypeAreas = this.codesearch.valueChanges.pipe(
    startWith(null),
    debounceTime(200),
    switchMap((res: string) => {
      if (!res) return of(this.taskTypeAreas);
      res = res.toUpperCase();
      return of(
        this.taskTypeAreas.filter(x => x.code.toUpperCase().indexOf(res) >= 0)
      );
    })
    //, map(res => this.searchlength=res.length)
  );
  
  ngOnInit() {
    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.taskTypeAreas=[];
  this.prcollection =[];
  this.prcollection = this.afs.collection('Products',ref=>ref.orderBy('code'))
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
            this.taskTypeAreas.push({'code':doc.data().code});
          console.log("GGGGG: "+JSON.stringify(this.taskTypeAreas));
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });


  }

  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct,br){
    
    ct=ct.match(/\D+/g).join('');

    this.prcollection =[];
    this.prcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , br).where('category', "==" , ct))
    .get().toPromise()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log("SAO: "+JSON.stringify(this.selectedOptions));

          //this.taskTypeAreas.push({'code':doc.data().code});
          // const taskTypeAreasconst=_.filter(this.taskTypeAreas,function(el){ return doc.data().code==el.code});
          // this.taskTypeAreas.unshift(...taskTypeAreasconst);
          
          console.log("GGGGG: "+JSON.stringify(this.taskTypeAreas));
        });
    }).then(()=>{
      //this.taskTypeAreas=_.filter(this.taskTypeAreas,(val,i,iteratee)=>_.includes(iteratee,val,i+1));
this.taskTypeAreas=_.uniqBy(this.taskTypeAreas,'code')
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

  }

    sortagain(){
    this.taskTypeAreas.sort(function(a,b){return a.code.match(/\d+/)[0] - b.code.match(/\d+/)[0]})
  }

  clearbtn(){
    this.ctrycollection="";
    this.prdbrand=null;
    this.prdcategory=null;
    // this.brndcollection = this.afs.collection('Brands').snapshotChanges()
    // .pipe(
    //   map(actions => actions.map(a => a.payload.doc.data()))
    // );

    
  //   this.prcollection =[];
  //   //this.taskTypeAreas=[];
  // this.prcollection = this.afs.collection('Products')
  // .get().toPromise()
  // .then(querySnapshot => {
  //     querySnapshot.forEach(doc => {
  //           //this.taskTypeAreas.push({'code':doc.data().code});
  //           this.taskTypeAreas=_.concat(this.taskTypeAreas,{'code':doc.data().code});
  //         console.log("GGGGG: "+JSON.stringify(this.taskTypeAreas));
  //     });
  // }).then(()=>{
  //   this.taskTypeAreas=_.uniqBy(this.taskTypeAreas,'code');
  // })
  // .catch(function(error) {
  //     console.log("Error getting documents: ", error);
  // });
  }


  /**
   * @default 3
   */
  public columns = 3;

  public get table(): ImageEnitity[][] {
    const rowCount = Math.floor(this.myArray.length / this.columns);
    const remainder = this.myArray.length % this.columns;
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(this.myArray.slice(i * this.columns, (i * this.columns) + this.columns))
    }
    if (remainder) {
      rows.push(this.myArray.slice(this.myArray.length - remainder, this.myArray.length));
    }
    return rows;
  };

  /**
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  public trackRow: TrackByFunction<ImageEnitity[]> = (index, item) => {
    return index;
  };

  /**
   * https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  public trackImage: TrackByFunction<ImageEnitity> = (index, item) => {
    //return item.name;
    return item.id;
  };

 // public myArray: ImageEnitity[] = [
    // { name: '1', path: '/images/first/', qnty:1 },
    // { name: '2', path: '/images/second/', qnty:1 },
    // { name: '3', path: '/images/third/', qnty:1 },
    // { name: '4', path: '/images/etc/', qnty:3 },
    // { name: '5', path: '/images/etc/', qnty:1 },
    // { name: '6', path: '/images/etc/', qnty:1 },
    // { name: '7', path: '/images/etc/', qnty:1 },
    // { name: '8', path: '/images/etc/', qnty:1 },
    // { name: '9', path: '/images/etc/', qnty:1 }
 // ];



updateCheckedOptions(tta,i){

  tta.checked = !tta.checked;
  console.log(this.selectedOptions.length);
 // for (var i = 0; i < (this.selectedOptions.length); i++) {
    console.log(tta.code);
    this.afs.collection('Products', ref => ref.where('code', "==" , tta.code)).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        if(tta.checked==true){
          this.myArray.push({'index':i,'id':doc.data().KEYID,'code':doc.data().code, 'qntycount':1, 'rate':doc.data().rate, 'bcc':doc.data().bcc, 'color':doc.data().color, 'size':doc.data().size, 'sizechart':doc.data().sizechart.substring(0,2), 'brand':doc.data().brand, 'category':doc.data().category, 'labelcode':doc.data().labelcode, 'suppliercode':doc.data().suppliercode});
          console.log("T: "+JSON.stringify(this.myArray));

        //   for(var m = 0; m < this.myArray.length ; m++){
        //     this.myArray.push(this.myArray[m]);
        // }
        
        }
        else {
          console.log("GGG: "+JSON.stringify(this.myArray));
          const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(doc.data().code);
          console.log("SSSS: "+JSON.stringify(this.myArray));
          if (srch > -1) {
            const cnt=this.myArray.filter(i=>i.code=== doc.data().code).length;
                      console.log("CCCC: "+cnt);

            this.myArray.splice(srch,cnt);
          }
          console.log("GGGGG: "+JSON.stringify(this.myArray));
        }
      }) ;
      return false;         
    }

  });

  console.log("R: "+JSON.stringify(this.myArray));
  return this.myArray;

}
insert(c,i){

  let countUpdated = {'bcid': c, 'qntycount':0};

  this.afs.collection('Products', ref => ref.where('code', '==' , c)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == c) {
 
        //qntycounters update
        this.myArray.find(item => item.code == countUpdated.bcid).qntycount = this.myArray.find(item => item.code == countUpdated.bcid).qntycount+ doc.data().unit1;
        console.log(JSON.stringify(this.myArray));
        //qntycounters update
        
      }
  })
  })


const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(c);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,0,this.myArray.find(item => item.code === c));
}
console.log("IIIIII: "+JSON.stringify(this.myArray));
}

remove(c,i){

  let countUpdated = {'bcid': c, 'qntycount':0};

  this.afs.collection('Products', ref => ref.where('code', '==' , c)).get().toPromise().then(querySnapshot => {
    //if (querySnapshot.size > 0) {

      querySnapshot.forEach(doc => {
        if (doc.data().code == c) {
 
        //qntycounters update
        this.myArray.find(item => item.code == countUpdated.bcid).qntycount = this.myArray.find(item => item.code == countUpdated.bcid).qntycount- doc.data().unit1;
        console.log(JSON.stringify(this.myArray));
        //qntycounters update
        
      }
  })
  })

  

const srch =  this.myArray.map(function(e) { return e.code; }).indexOf(c);
console.log(srch);
if (srch > -1) {
  this.myArray.splice(srch,1);
}
console.log("RRRRRR: "+JSON.stringify(this.myArray));
}

}