import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSortable} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../walkouts/format-datepicker';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-walkouts',
  templateUrl: './walkouts.component.html',
  styleUrls: ['./walkouts.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class WalkoutsComponent implements AfterViewInit, OnInit {

  panelOpenState = true;

  public fmForm: FormGroup;

  displayedColumns = ['date2', 'name', 'description', 'status', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  wlkkeyid: any;
  wlkdate: any;
  wlkpart: any;
  wlkdescription: any="";
  ///////filter
  namesearch: string='';
  selection: any;
  datesearch: any;
  filterValue = {
    name: '',
    date2s: '',
    date2e: '',
    status:''
  };
  custcollection: any;

  name: any;
  particulartype: string;
  splitty: any=[];

  enableUpdate: boolean = false;

  currentstatus: string='YET TO BE INFORM';
 statuses: string[] = ['YET TO BE INFORM', 'CLEARED'];

  flitereddataary: any[]=[];

  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, public datePipe: DatePipe) { 
    }

  
  ngAfterViewInit() {

      this.afs.collection<any>('Walkouts',ref=> ref.orderBy('date','asc')).valueChanges().subscribe(data => { //filtered by book
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.filterPredicate = this.createFilter();
        console.log("FilteredData:" +JSON.stringify(this.dataSource.filteredData));
        // this.dataSource.sort = this.sort;
  
        // //sorting for date column
        // this.dataSource.sortingDataAccessor = (item, property) => {
        // //console.log(item)
        //   switch (property) {
        //     case 'date2': {
        //       return item.date2;
        //     }
        //     default: return item[property];
        //   }
        // };
  
        this.dataSource.paginator=this.paginator;
        //It searches name and description columns. To search in entire table skip it
        //this.dataSource.filterPredicate = (data, filter) =>
        //(data.name.indexOf(filter) !== -1 || data.description.indexOf(filter) !== -1) || (data.date2.indexOf(filter) !== -1);
        //----------------------------------------------------------------------  
        
  
  
      
      })
    

}

  applyFilter1(filterValue: string) {
    this.filterValue.name = filterValue.trim().toUpperCase(); 
    this.dataSource.filter = JSON.stringify(this.filterValue);  
    console.log(this.dataSource.filter);
  };
  applyFilter2(filterValue: string) {
    this.filterValue.date2s = this.datePipe.transform(new Date(this.datesearch.start),"dd-MM-yyyy"); 
    this.filterValue.date2e = this.datePipe.transform(new Date(this.datesearch.end),"dd-MM-yyyy"); 
    this.dataSource.filter = JSON.stringify(this.filterValue);   
    console.log("S: "+this.filterValue.date2s);
    console.log("E: "+this.filterValue.date2e);
  };

  applyFilter3(filterValue: string) {
    this.filterValue.status = filterValue.trim().toUpperCase(); 
    this.dataSource.filter = JSON.stringify(this.filterValue);  
    console.log(this.dataSource.filter);
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log("CURRDate:" +moment(data.date2, "DD-MM-YYYY").toDate());
      console.log("SDMoment:" +moment(searchTerms.date2s, "DD-MM-YYYY").toDate());
      console.log("EDMoment:" +moment(searchTerms.date2e, "DD-MM-YYYY").toDate());
      if(searchTerms.status!='ALL'){
        return(data.status.indexOf(searchTerms.status) !==-1)
      }
      if(searchTerms.status=='ALL'){
        return(data.status.indexOf(searchTerms.status) !==-1 || data.status.indexOf(searchTerms.status) ==-1)
      }
      if((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
      && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
      {
        console.log(true);
      }
      else{
        console.log(false);
      }
      return (data.name.indexOf(searchTerms.name) !== -1) && (data.status.indexOf(searchTerms.status) === 'CLEARED')
        && ((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
        && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
    }
    return filterFunction;
  }

  trackByUid(index, item) {
    return item.uid;
  }



  ngOnInit() {

    this.Form();  

    this.custcollection = this.afs.collection('Customers',ref=> ref.orderBy('customerName')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
  //   this.sort.sort(<MatSortable>{
  //     id: 'date2',
  //     start: 'asc',
  //   }
  // );
  
  }

   
    // Reactive form
    Form() {
      this.fmForm = this.fb.group({
        walkoutdate: [''],
        walkoutname: [null, [Validators.required,Validators.minLength(5)]],
        walkoutdescription: [''],
        walkoutstatus: [''],
      })
    }

    // Accessing form control using getters
    get walkoutdate() {
      return this.fmForm.get('walkoutdate');
    }

    get walkoutname() {
      return this.fmForm.get('walkoutname').setValidators([Validators.required, Validators.minLength(3)]);
    }
  
    get walkoutdescription() {
      return this.fmForm.get('walkoutdescription');
    }

    get walkoutstatus() {
      return this.fmForm.get('walkoutstatus');
    }

    // Reset form's values
    ResetForm() {
      //this.fmForm.reset();

      this.wlkdate="";
      this.wlkpart="";
      this.wlkdescription="";
      this.name="";
      this.particulartype="";
      this.currentstatus="CLEARED";

      this.enableUpdate=false;
    }
    

    submitwalkoutData() {
      this.add(this.fmForm.value.walkoutid);
      this.ResetForm();  // Reset form when clicked on reset button
      this.refresh();
    };
    
 refresh(){
   this.dataSource.data=this.dataSource.data;
 }

    add(id) {

      this.splitty=[];
      this.splitty=this.wlkpart.split("+");
      this.name = this.splitty[1];
      this.particulartype = this.splitty[2];

      let ids =this.afs.createId();     
      this.afs.collection("Walkouts").doc(ids).set({
        KEYID: ids,
        date: this.wlkdate,
        date2: this.datePipe.transform(this.wlkdate,"dd-MM-yyyy"),
        part: this.wlkpart,
        description: this.wlkdescription,
        name:this.name,
        particulartype:this.particulartype,
        status: this.currentstatus,
      })
        .catch((err) => {
          console.log(err);
        })
      
    }

    read(wlkg) {  
console.log(this.name);
      this.wlkkeyid = wlkg.KEYID;
      this.wlkdate = ((moment(wlkg.date2, "DD-MM-YYYY").toDate()));
      console.log(((moment(wlkg.date2, "DD-MM-YYYY").toDate())));
      this.wlkpart = wlkg.part;

      this.splitty=[];
      this.splitty=this.wlkpart.split("+");
      // int date=Integer.parseInt(spl[0]);
      // int month=Integer.parseInt(spl[1]);
      this.name = this.splitty[1];
      this.particulartype = this.splitty[2];

      this.currentstatus=wlkg.status;

      this.wlkdescription = wlkg.description; 
      this.fmForm.get('walkoutname').setValue(this.splitty[0]+'+'+this.splitty[1]+'+'+this.splitty[2]);
    }

    
  update(lid) {
      this.splitty=[];
      this.splitty=this.wlkpart.split("+");
      this.name = this.splitty[1];
      this.particulartype = this.splitty[2];
    this.afs.collection("Walkouts").doc(lid).update({
        date: this.wlkdate,
        part: this.wlkpart,
        description: this.wlkdescription,
        name:this.name,
        particulartype:this.particulartype,
        status: this.currentstatus,
    }).then(() => {
      console.log('updatedF');
      this.ResetForm();
    })
  }

  
  delete(wlk) {
    this.afs.collection("Walkouts").doc(wlk.KEYID).delete().then(() => {
      console.log('deleted');
    })
  }

  changeParticular(pt){
    this.splitty=[];
    this.splitty=pt.split("+");
    this.name = this.splitty[1];
    this.particulartype = this.splitty[2];
    console.log(this.splitty)
  }

}